import { forwardRef } from 'react';

import { BaseSelect, type IBaseSelectProps } from '../../components/BaseSelect';
import {
  ArrowDown,
  ArrowDownContainer,
  ArrowUp,
  ArrowUpContainer,
  Container,
  IndicatorContainer,
  Item,
  ItemText,
  SelectIcon,
  Square,
  Viewport,
} from './styles';

interface IHTMLDivElementExtension extends HTMLDivElement {
  dataCy?: string;
  name?: string;
  placeholder?: string;
}

interface IBaseSelectPropsExtension extends Omit<IBaseSelectProps, 'children'> {
  dataCy?: string;
  name?: string;
  placeholder?: string;
}

export interface IT {
  key: number | string;
  value: string;
  color?: string;
}

export interface ISelectDataProps<
  T extends { key: number | string; value: string; color?: string }
> extends IBaseSelectPropsExtension {
  data: T[];
}

export const SelectData = forwardRef<
  IHTMLDivElementExtension,
  ISelectDataProps<IT>
>(
  // eslint-disable-next-line no-restricted-syntax
  function SelectData(
    {
      data,
      dataCy = 'select-item',
      name = 'select-name',
      placeholder = '',
      ...props
    },
    ref
  ): JSX.Element {
    return (
      <Container data-cy={`container-${dataCy}`} ref={ref}>
        <BaseSelect
          dataCy={dataCy}
          placeholder={placeholder}
          name={name}
          {...props}
        >
          <ArrowUpContainer data-cy="arrow-up">
            <ArrowUp />
          </ArrowUpContainer>

          <Viewport data-cy="view-port">
            {data.map(({ key, value, color }) => (
              <Item key={key} value={`${key}`} data-cy={`${value}`}>
                <ItemText>{value}</ItemText>
                {color && <Square style={{ backgroundColor: color }} />}
                <IndicatorContainer>
                  <SelectIcon />
                </IndicatorContainer>
              </Item>
            ))}
          </Viewport>
          <ArrowDownContainer data-cy="arrow-down">
            <ArrowDown />
          </ArrowDownContainer>
        </BaseSelect>
      </Container>
    );
  }
);

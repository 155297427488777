import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import { Can, useAuth } from '@gbs-monorepo-packages/auth';
import {
  type ISidebarHeaderProps,
  Roles,
  SidebarHeader,
  SidebarHeaderMenuProfileItem,
  SidebarItem,
  getRouteFrom,
} from '@gbs-monorepo-packages/common';

import { ADMIN_URL } from '../../constants/Env';
import { ALL_MEMBERS, DASHBOARD } from '../../constants/RoutePaths';
import { AdminPortalIcon, DashBoardIcon, MembersIcon } from './styles';

const DashboardRoute = getRouteFrom(DASHBOARD);
const MembersRoute = getRouteFrom(ALL_MEMBERS);

const titleTeammates = 'User Manager';
const titleCompanies = 'Clients';

const HighUserRoles = [Roles.ADMIN, Roles.MANAGER];

interface IDefaultLayoutProps extends Omit<ISidebarHeaderProps, 'children'> {}

export const DefaultLayout = ({
  ...props
}: IDefaultLayoutProps): JSX.Element => {
  const { user, signOut } = useAuth();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  return (
    <SidebarHeader
      data-cy="text-edgePortal"
      logoRedirectTo="/"
      logoText="Edge Portal"
      onSignOut={signOut}
      userName={user ? `${user?.firstName} ${user?.lastName}` : ''}
      sidebarChildren={(isShrunken) => (
        <Can roles={HighUserRoles}>
          <SidebarItem
            dataCy={`navLink-clients`}
            icon={<DashBoardIcon />}
            isShrunken={isShrunken}
            title={titleCompanies}
            type="link"
            to={DashboardRoute}
          />
          <SidebarItem
            dataCy={`navLink-teammates`}
            icon={<MembersIcon />}
            isShrunken={isShrunken}
            title={titleTeammates}
            type="link"
            to={MembersRoute}
          />
        </Can>
      )}
      sidebarFooterChildren={(isShrunken) => (
        <>
          <Can roles={HighUserRoles}>
            <SidebarItem
              dataCy={`navLink-dashboardAdmin`}
              icon={<AdminPortalIcon />}
              isShrunken={isShrunken}
              title={'Admin Portal'}
              type="link"
              to={ADMIN_URL + '/redirect-login'}
            />
          </Can>
        </>
      )}
      menuProfileChildren={
        <SidebarHeaderMenuProfileItem
          data-cy="button-settings"
          onClick={() => {
            if (!pathname.includes('profile')) {
              navigate('/profile', { state: { from: pathname } });
            }
          }}
        >
          Settings
        </SidebarHeaderMenuProfileItem>
      }
      {...props}
    >
      <Outlet />
    </SidebarHeader>
  );
};

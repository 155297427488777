import { type CSSProperties } from 'react';
import { MdOutlineVisibility } from 'react-icons/md';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import {
  BaseLoading,
  // SelectFilter,
  // SelectFilterItem,
} from '@gbs-monorepo-packages/common';
import { colors, radii } from '@gbs-monorepo-packages/styles';

import {
  SelectFilter,
  SelectFilterItem,
} from '../../../../link_common_components/SelectFilter';
import { type ITemplatePageDTO } from '../../../../services/templates';

export const LoadingContainer = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: center;
`;

export const Loading = styled(BaseLoading)`
  height: 3.25rem;
  width: 3.25rem;
`;

const selectFilterContentCustom: CSSProperties = {
  // overflow: 'auto !important',
  // height: '100%',
  maxHeight: '40vh',
  // width: '100%',
  position: 'absolute',
  left: '12px',
};

export const SelectFilterCustom = styled(SelectFilter<ITemplatePageDTO>).attrs(
  ({ customContentStyle = selectFilterContentCustom }) => ({
    customContentStyle,
  })
)``;

export const ItemContainer = styled.div`
  align-items: center;
  display: flex;
  border-radius: ${radii.sm};
  background-color: ${colors.white300};

  &:hover {
    background-color: ${colors.backgroundHover};
  }

  &:focus-within {
    background-color: ${colors.backgroundHover};
  }
`;

export const SelectFilterItemCustom = styled(SelectFilterItem)`
  all: unset;
  align-items: center;
  border-radius: unset;
  color: ${colors.text};
  display: flex;
  flex-grow: 1;
  font-size: 1rem;
  line-height: 1.1;
  padding: 0.375rem 0.375rem 0.375rem 1.5rem;
  position: relative;
  user-select: none;

  /* &[data-disabled] {
    color: ${colors.gray200};
    pointer-events: none;
  } */
  &[data-highlighted],
  &:hover {
    background-color: unset;
  }
`;

export const ActionButtonContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-end;
`;

export const PreviewLink = styled(Link)`
  all: unset;
  align-items: center;
  border-radius: ${radii.full};
  color: ${colors.blue400};
  cursor: pointer;
  display: inline-flex;
  font-family: inherit;
  height: 2.25rem;
  justify-content: center;
  margin: 0.25rem;
  width: 2.25rem;

  &:hover {
    background-color: ${colors.backgroundHover};
  }
`;

export const PreviewIcon = styled(MdOutlineVisibility)`
  height: 1.5rem;
  width: 1.5rem;
`;

import { MdMenu, MdOutlineDescription, MdSearch } from 'react-icons/md';
import styled from 'styled-components';

import { colors, scrollbar } from '@gbs-monorepo-packages/styles';

import { BaseLoading } from '../../components/BaseLoading';

export const CourseViewerContainer = styled.div`
  display: flex;
  height: 100vh;
`;

export const MainContainer = styled.main`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex: 1;
  justify-content: space-between;
`;

export const LoadingPagesContainer = styled.div`
  align-items: center;
  display: flex;
  flex-grow: 1;
  justify-content: center;
`;

export const LoadingPages = styled(BaseLoading)`
  height: 3.25rem;
  width: 3.25rem;
`;

export const NoTemplateContainer = styled.div`
  align-items: center;
  display: flex;
  flex-grow: 1;
  justify-content: center;
`;

export const NoTemplateText = styled.p`
  font-size: 1rem;
  font-weight: 400;
  color: ${colors.textLight};
`;

export const Header = styled.header`
  align-items: center;
  border-bottom: 1px solid ${colors.divider};
  display: flex;
  flex-wrap: wrap;
  height: 3.25rem;
  padding: 0 0.5rem;
`;

// export const PageItem = styled.button`
//   all: unset;
//   display: flex;
//   padding: 0.8rem;
//   cursor: pointer;
//   align-items: center;
//   justify-content: flex-start;

//   &:hover {
//     background-color: ${colors.backgroundHover};
//   }
// `;

export const PageIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const PageIcon = styled(MdOutlineDescription)`
  height: 1.5rem;
  width: 1.5rem;
`;

export const PageTitleContainer = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  max-width: calc(100vw - 2.5rem);
  overflow: hidden;
  padding: 1rem 0.8rem;
`;

export const PageTitle = styled.h1`
  font-size: 1.2rem;
  font-weight: 700;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const NoPagesContainer = styled.div`
  align-items: center;
  display: flex;
  flex-grow: 1;
  justify-content: center;
`;

export const NoPagesText = styled.p`
  font-size: 1rem;
  font-weight: 400;
  color: ${colors.textLight};
`;

export const MainContent = styled.div`
  align-content: flex-start;
  align-items: stretch;
  display: flex;
  flex-basis: 0;
  flex-grow: 1;
  flex-wrap: wrap;
  height: 100%;
  overflow: auto;

  ${scrollbar};
`;

export const IframeCustom = styled.iframe`
  border: none;
  height: 100%;
  width: 100%;
`;

export const SearchIconContainer = styled.div`
  display: flex;
  padding: 0.75rem 0.5rem;
  flex-direction: column;
  cursor: pointer;

  &:hover {
    background-color: ${colors.backgroundHover};
  }
`;

export const PagesMenuIconContainer = styled.div`
  display: flex;
  padding: 0.75rem 0.5rem;
  flex-direction: column;
  cursor: pointer;

  &:hover {
    background-color: ${colors.backgroundHover};
  }
`;

export const PagesMenuIcon = styled(MdMenu)`
  height: 1.5rem;
  width: 1.5rem;
`;

export const SearchIcon = styled(MdSearch)`
  height: 1.5rem;
  width: 1.5rem;
`;

export const ActionItemsContainer = styled.div`
  display: flex;
`;

import { format, parseJSON } from 'date-fns';
import { produce } from 'immer';
import {
  type ChangeEvent,
  type MouseEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import {
  generatePath,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import useWebSocket from 'react-use-websocket';

import { Can, useAuth, useCan } from '@gbs-monorepo-packages/auth';
import { FormBase, SearchBar } from '@gbs-monorepo-packages/common';

import LoadingSpinnerAnimated from '../../assets/spinner.svg';
import { BaseDropdown } from '../../components/BaseDropdown';
import { BaseTooltip } from '../../components/BaseTooltip';
import { Button } from '../../components/Button';
import { CenteredText } from '../../components/CenteredText';
import { ContentPagination } from '../../components/ContentPagination';
import { DialogModal } from '../../components/DialogModal';
import { FormModal } from '../../components/FormModal';
import { SelectData } from '../../components/SelectData';
import { UploadModal } from '../../components/UploadModal';
import { WEBSOCKET_BASE_URL } from '../../constants/Env';
import { languageData } from '../../constants/Languages';
import { Roles } from '../../constants/Roles';
import { COURSES, EDIT_COURSE, REDIRECT } from '../../constants/RoutePaths';
import { type IEditCourseState } from '../../constants/RouteStates';
import { useCompanies } from '../../hooks/useCompanies';
import { useToast } from '../../hooks/useToast';
import {
  type IApiThrowsError,
  type IPaginationMetaProps,
} from '../../services/api';
import {
  type ICourseDTO,
  type ICourseVersion,
  type ICreateCourseFromTemplatesProps,
  type ICreateCourseUsingCourseGroupProps,
  type IPaginationCoursetDTO,
  changeImageCourse,
  createCourse,
  createCourseFromTemplates,
  createCourseUsingCourseGroup,
  deleteCourse,
  downloadCourse,
  duplicateCourse,
  getCoursesByCompanyId,
  getCoursesById,
  restoreCourseHistory,
  updateCourse,
} from '../../services/courses';
import { getRouteFrom } from '../../utils/getRoutes';
import Logger from '../../utils/logger';
import { type ICourseWebsocketMessage } from '../EditCourse';
import { AddCourseWithCoursesTemplateModal } from './components/AddCourseWithCourseTemplateModal';
import { AddCourseWithTemplateModal } from './components/AddCourseWithTemplateModal';
import { DuplicateCourseModal } from './components/DuplicateCourseModal';
import { RestorePageModal } from './components/RestoreCourseModal';
import {
  ActionButton,
  ActionsButtonContainer,
  AddLinkIcon,
  ArrowDown,
  ButtonContent,
  ConcludeIcon,
  ConcludedIcon,
  Container,
  ContentCard,
  CourseActions,
  CourseCard,
  CourseCardGrid,
  CourseName,
  Date,
  DeleteIcon,
  DisabledIcon,
  DownloadIcon,
  DropdownActionButtonContainer,
  DropdownActionItem,
  DropdownActionSeparator,
  DropdownButtonContainer,
  DropdownItem,
  DropdownItemGrid,
  DuplicateIcon,
  EditIcon,
  FieldsetCustom,
  GridContent,
  GridIcon,
  GridItem,
  GridListOptions,
  Header,
  HistoryIcon,
  IconContainer,
  ImageContainer,
  ImageContainerGrid,
  ImageIcon,
  Label,
  LazyImageCustom,
  LazyImageGrid,
  LinkIcon,
  ListIcon,
  Loading,
  LoadingContainer,
  LoadingList,
  MainButtonContainer,
  MainButtonCustom,
  MainContainer,
  ModalText,
  OnDesktopView,
  OnMobileView,
  Option,
  PreviewIcon,
  SearchContainer,
  TextContent,
  TitlePage,
  TriggerDropdownAction,
  ViewURLInput,
  ViewURLInputContainer,
} from './styles';

interface IDuplicateError {
  other?: string;
}

const NotViewer = [Roles.ADMIN, Roles.MANAGER, Roles.STAFF, Roles.CREW];
const RolesNotViewer = { roles: NotViewer };
const COURSES_PATH = getRouteFrom(COURSES);
const COURSE_DELETE_REQUEST_MESSAGE = 'course_delete_request_message';
const COURSE_DELETE_DENIED_MESSAGE = 'course_delete_denied_message';
const HEADER_IMAGE_MAX_SIZE = 10485760; // in bytes = 10 MB
const HEADER_IMAGE_MAX_WIDTH = 600;
const HEADER_IMAGE_MAX_HEIGHT = 400;

export const Courses = (): JSX.Element => {
  const isSelectOpen = useRef(new Set());
  const [loadingCourses, setLoadingCourses] = useState(false);
  const [changeImage, setChangeImage] = useState<File | null>(null);
  const [courses, setCourses] = useState<ICourseDTO[]>([]);
  const { companyId = '' } = useParams(); // need to save this value to COMPANIES route
  const companyIdNumber = useMemo(() => Number(companyId), [companyId]);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [courseToChangeImage, setCourseToChangeImage] =
    useState<ICourseDTO | null>(null);
  const [loadingChangeImage, setLoadingChangeImage] = useState(false);
  const isModalChangeImageOpen = !!courseToChangeImage;

  const [courseToDelete, setCourseToDelete] = useState<ICourseDTO | null>(null);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [loadingCourseRestore, setLoadingCourseRestore] = useState(false);
  const isModalDeleteOpen = !!courseToDelete;
  const [courseToChangeStatus, setCourseToChangeStatus] =
    useState<ICourseDTO | null>(null);
  const [loadingChangeStatus, setLoadingChangeStatus] = useState(false);
  const isModalChangeStatusOpen = !!courseToChangeStatus;
  const [courseToCreateViewURL, setCourseToCreateViewURL] =
    useState<ICourseDTO | null>(null);
  const [currentViewURL, setCurrentViewURL] = useState<string>('');
  const [loadingCreateViewURL, setLoadingCreateViewURL] = useState(false);
  const [isModalCreateViewURLOpen, setIsModalCreateViewURLOpen] =
    useState(false);
  const [
    isModalCreateCourseFromTemplateOpen,
    setIsModalCreateCourseFromTemplateOpen,
  ] = useState(false);
  const [
    isModalCreateCourseFromCourseTemplateOpen,
    setIsModalCreateCourseFromCourseTemplateOpen,
  ] = useState(false);
  const [isDropdownAddCourseOpen, setIsDropdownAddCourseOpen] = useState(false);
  const [isDropdownActionsOpen, setIsDropdownActionsOpen] = useState(false);

  const [courseToDownload, setCourseToDownload] = useState<ICourseDTO | null>(
    null
  );

  const [search, setSearch] = useState<string>('');
  const lastSearch = useRef(search);
  const [isDropdownOpenGrid, setIsDropdownGridOpen] = useState(false);
  const [paginationMeta, setPaginationMeta] =
    useState<IPaginationMetaProps | null>(null);

  const isModalDownloadOpen = !!courseToDownload;
  const { addToast } = useToast();
  const { selectedCompany, selectedListOptionStyle, selectListOptionStyle } =
    useCompanies();

  const { user, getCurrentRole } = useAuth();
  const currentRole = getCurrentRole(user?.roles ?? ['']);
  const [companyDuplicateCourse, setCompanyDuplicateCourse] = useState<
    number | null
  >(Number(companyId));
  const [newCourseStatus, setNewCourseStatus] = useState<string | null>('');
  const [dialogDuplicateOpen, setDialogDuplicateOpen] = useState(false);
  const [loadingDuplicate, setLoadingDuplicate] = useState(false);
  const [errorDuplicate, setErrorDuplicate] = useState<IDuplicateError>({});
  const [optionStyle, setOptionStyle] = useState<string | null>(
    selectedListOptionStyle
  );
  const [courseDuplicate, setCourseDuplicate] = useState<ICourseDTO | null>(
    null
  );

  const [courseRestore, setCourseRestore] = useState<ICourseDTO | null>(null);
  const [orientation, setOrientation] = useState('portrait');
  const [language, setLanguage] = useState('en');
  const [loadingDownload, setLoadingDownload] = useState(false);
  const [errorMessageUpload, setErrorMessageUpload] = useState('');

  const resetFormDuplicate = () => {
    if (!isSelectOpen.current.size) {
      setErrorDuplicate({});
      setDialogDuplicateOpen(false);
      setLoadingDuplicate(false);
    }
  };

  const resetFormDownload = () => {
    if (!isSelectOpen.current.size) {
      setCourseToDownload(null);
      setLanguage('en');
      setOrientation('portrait');
    }
  };

  useEffect(() => {
    let mount = true;
    const syncCourses = async () => {
      try {
        setLoadingCourses(true);
        setCourses([]);
        setPaginationMeta(null);
        const result = await getCoursesByCompanyId({
          companyId,
          page: 1,
          limit: 15,
          filter: search,
        });

        if (mount) {
          setCourses(result.data);
          setPaginationMeta(result.meta);
        }
      } catch (err) {
        Logger.debug('err: ', err);
      } finally {
        if (mount) {
          setLoadingCourses(false);
        }
      }
    };

    if (Number(companyId) <= 0) {
      navigate(getRouteFrom(REDIRECT));
    } else {
      void syncCourses();
    }

    return () => {
      mount = false;
    };
  }, [companyId, navigate]);

  const handleAcceptDelete = useCallback(() => {
    if (courseToDelete && !loadingDelete) {
      setLoadingDelete(true);
      deleteCourse({ id: courseToDelete.id })
        .then(() => {
          addToast({
            title: 'Course deleted',
            description: `Course ${courseToDelete.title} was successfully deleted`,
            styleType: 'success',
            dataCy: 'course-deleted-success-toast',
          });

          searchCourses(true);
        })
        .catch((err) => {
          addToast({
            title: 'Error on deleting course',
            description:
              'An error occurred. Please try again or contact Edge support.',
            styleType: 'error',
            dataCy: 'course-deleted-error-toast',
          });
          Logger.debug('err: ', err);
        })
        .finally(() => {
          setLoadingDelete(false);
          setCourseToDelete(null);
        });
    }
  }, [courseToDelete, loadingDelete, courses, addToast]);

  const handleRestoreCourse = useCallback(
    (historyPage: ICourseVersion) => {
      if (Boolean(historyPage) && !loadingCourseRestore) {
        setLoadingCourseRestore(true);
        restoreCourseHistory({ courseVersion: historyPage.id })
          .then(async () => {
            const courseRestore = await getCoursesById({
              id: historyPage.course.id,
            });

            const state: IEditCourseState = {
              course: courseRestore,
              from: generatePath(COURSES_PATH, { companyId }),
            };

            navigate(
              getRouteFrom(EDIT_COURSE)
                .replace(':companyId', companyId)
                .replace(':courseId', String(historyPage.course.id)),
              { state }
            );
          })
          .catch((err) => {
            setLoadingCourseRestore(false);
            addToast({
              title: 'Error on restore course',
              description:
                'An error occurred. Please try again or contact Edge support.',
              styleType: 'error',
              dataCy: 'course-restore-error-toast',
            });
            Logger.debug('err: ', err);
          });
      }
    },
    [loadingCourseRestore, companyId, navigate, addToast]
  );

  const handleAcceptChangeStatus = useCallback(() => {
    if (courseToChangeStatus && !loadingChangeStatus) {
      setLoadingChangeStatus(true);
      updateCourse({
        ...courseToChangeStatus,
        status: newCourseStatus,
      })
        .then((course: ICourseDTO) => {
          setLoadingChangeStatus(false);
          const modifiedCourseIndex = courses.findIndex(
            ({ id }) => id === course.id
          );
          if (modifiedCourseIndex >= 0) {
            const currentCourses = [...courses];
            currentCourses.splice(modifiedCourseIndex, 1);
            setCourses([course, ...currentCourses]);
          }
          addToast({
            title: 'Status updated',
            description: `Status of course ${course.title} was successfully updated`,
            styleType: 'success',
            dataCy: 'course-status-updated-success-toast',
          });
          setCourseToChangeStatus(null);
        })
        .catch((error: IApiThrowsError) => {
          setLoadingChangeStatus(false);
          const errorMessage =
            error.response && error.response.status < 500
              ? error.response.data.error.message
              : 'An error occurred. Please try again or contact Edge support.';
          addToast({
            title: 'Error on changing status',
            description: errorMessage,
            styleType: 'error',
            dataCy: 'error-toast',
          });
          Logger.debug('error: ', error);
        });
    }
  }, [
    courseToChangeStatus,
    loadingChangeStatus,
    courses,
    newCourseStatus,
    addToast,
  ]);

  const { sendMessage } = useWebSocket(WEBSOCKET_BASE_URL, {
    onMessage: (messageEvent: WebSocketEventMap['message']) => {
      const courseWebsocketMessage = JSON.parse(
        messageEvent.data as string
      ) as ICourseWebsocketMessage;

      switch (courseWebsocketMessage.type) {
        case COURSE_DELETE_DENIED_MESSAGE:
          setCourseToDelete(null);
          addToast({
            title: courseWebsocketMessage.title,
            description: courseWebsocketMessage.message,
            styleType: 'error',
            dataCy: 'course-delete-denied-toast',
          });
          break;
        default:
          handleAcceptDelete();
          break;
      }
    },
    shouldReconnect: () => true,
  });

  const sendDeleteRequest = useCallback(() => {
    if (courseToDelete) {
      const requestEditCourseMessage: ICourseWebsocketMessage = {
        id: courseToDelete.id,
        type: COURSE_DELETE_REQUEST_MESSAGE,
        section: 'edit_course',
      };

      sendMessage(JSON.stringify(requestEditCourseMessage));
    }
  }, [courseToDelete, sendMessage]);

  const handleDeclineDelete = useCallback(() => {
    setCourseToDelete(null);
  }, []);

  const handleDeclineChangeStatus = useCallback(() => {
    if (!isSelectOpen.current.size) {
      setCourseToChangeStatus(null);
    }
  }, []);

  const courseDownload = (
    e: MouseEvent<HTMLButtonElement | HTMLDivElement>,
    course: ICourseDTO
  ) => {
    e.stopPropagation();
    setCourseToDownload(course);
    setLoadingDownload(false);
    setLanguage('en');
    setOrientation('portrait');
  };

  const viewCourse = (
    e: MouseEvent<HTMLButtonElement | HTMLDivElement>,
    course: ICourseDTO
  ) => {
    e.stopPropagation();
    if (!course.viewURL || course.viewURL === '') {
      handleOpenModalCreateViewURL(e, course);
      return;
    }

    window.open(
      `${
        import.meta.env.VITE_COURSE_VIEW_SUBDOMAIN_URL ??
        window.location.origin + '/view'
      }/${decodeURI(course.viewURL)}`,
      '_blank'
    );
  };

  const handleOpenModalCreateViewURL = (
    e: MouseEvent<HTMLButtonElement | HTMLDivElement>,
    course: ICourseDTO
  ) => {
    e.stopPropagation();
    if (!course.viewURL || course.viewURL === '') {
      setIsModalCreateViewURLOpen(true);
      setCourseToCreateViewURL(course);
      setCurrentViewURL(course?.viewURL ?? '');
      return;
    }

    if (!navigator.clipboard) {
      addToast({
        title: "Error on copy course's link",
        description:
          'Clipboard not available. Please try again or contact Edge support.',
        styleType: 'error',
        dataCy: 'document-link-copied-error-toast',
      });
      return;
    }
    navigator.clipboard
      .writeText(
        `${
          import.meta.env.VITE_COURSE_VIEW_SUBDOMAIN_URL ??
          window.location.origin + '/view'
        }/${decodeURI(course.viewURL)}`
      )
      .then(() => {
        addToast({
          title: 'Link copied',
          description: `Link of course ${course.title} was successfully copied`,
          styleType: 'success',
          dataCy: 'course-link-copied-success-toast',
        });
      })
      .catch((err) => {
        Logger.debug('err: ', err);

        addToast({
          title: "Error on copy course's link",
          description:
            'An error occurred. Please try again or contact Edge support.',
          styleType: 'error',
          dataCy: 'course-link-copied-error-toast',
        });
      });
  };

  const handleAcceptCreateViewURL = useCallback(() => {
    setLoadingCreateViewURL(true);

    if (!courseToCreateViewURL) {
      return;
    }

    updateCourse({
      ...courseToCreateViewURL,
      viewURL: encodeURI(currentViewURL.trim()),
    })
      .then((course: ICourseDTO) => {
        setLoadingCreateViewURL(false);
        searchCourses(true);
        addToast({
          title: 'Link updated',
          description: `Link of course ${course.title} was successfully updated`,
          styleType: 'success',
          dataCy: 'course-link-updated-success-toast',
        });
        setIsModalCreateViewURLOpen(false);
      })
      .catch((error: IApiThrowsError) => {
        setLoadingCreateViewURL(false);
        addToast({
          title: 'URL already in use',
          description:
            error.response && error.response.status < 500
              ? error.response.data.error.message
              : 'An error occurred. Please try again or contact Edge support.',
          styleType: 'error',
          dataCy: 'error-toast',
        });
        Logger.debug('error: ', error);
      });
  }, [courseToCreateViewURL, currentViewURL, courses, addToast]);

  const handleDeclineCreateViewURL = useCallback(() => {
    setIsModalCreateViewURLOpen(false);
  }, []);

  const handleOnChangeViewURL = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length <= 100) {
      const sanitizedText = e.target.value.replace(/[^a-zA-Z0-9-_]/g, '');
      setCurrentViewURL(sanitizedText);
    }
  };

  const handleOpenModalDelete = (
    e: MouseEvent<HTMLButtonElement | HTMLDivElement>,
    course: ICourseDTO
  ) => {
    e.stopPropagation();
    setCourseToDelete(course);
  };

  const handleOpenModalChangeStatus = (
    e: MouseEvent<HTMLButtonElement | HTMLDivElement>,
    course: ICourseDTO
  ) => {
    e.stopPropagation();
    setNewCourseStatus(course?.status ?? 'In Progress');
    setCourseToChangeStatus(course);
  };

  const displayInMegaBytes = (value: number): number => {
    return Number((value / 1024 / 1024).toFixed(2));
  };

  const handleChangeImage = useCallback(
    ({ target }: ChangeEvent<HTMLInputElement>) => {
      setChangeImage(null);
      setErrorMessageUpload('');
      const { files } = target;

      if (files?.item(0)) {
        const file = files.item(0);
        if (!file) return;
        const objectURL = URL.createObjectURL(file);
        const img = document.createElement('img');
        img.onload = () => {
          URL.revokeObjectURL(objectURL);

          if (
            img.width > HEADER_IMAGE_MAX_WIDTH ||
            img.height > HEADER_IMAGE_MAX_HEIGHT
          ) {
            setErrorMessageUpload(
              `The image dimensions must be less than ${HEADER_IMAGE_MAX_WIDTH} x ${HEADER_IMAGE_MAX_HEIGHT} px (width x height)`
            );
            return;
          }

          if (file.size > HEADER_IMAGE_MAX_SIZE) {
            setErrorMessageUpload(
              `The image size must be less than ${displayInMegaBytes(
                HEADER_IMAGE_MAX_SIZE
              )} MB`
            );
          }
        };

        img.src = objectURL;

        setChangeImage(file);
      }
    },
    []
  );

  const handleAcceptChangeImage = useCallback(() => {
    if (courseToChangeImage && !loadingChangeImage && changeImage) {
      setLoadingChangeImage(true);
      changeImageCourse({ id: courseToChangeImage.id, newImage: changeImage })
        .then((modifiedCourse) => {
          const modifiedCourseIndex = courses.findIndex(
            ({ id }) => id === modifiedCourse.id
          );
          if (modifiedCourseIndex >= 0) {
            setCourses(
              produce((draft) => {
                draft.splice(modifiedCourseIndex, 1);
                draft.unshift(modifiedCourse);
              })
            );
            addToast({
              title: 'Success on update image',
              description: `The preview course image has been changed`,
              styleType: 'success',
              dataCy: 'update-image-success-toast',
            });
          }
        })
        .catch((error) => {
          Logger.debug('error', error);
          addToast({
            title: 'Error on update image',
            description:
              'An error occurred. Please try again or contact Edge support.',
            styleType: 'error',
            dataCy: 'update-image-error-toast',
          });
        })
        .finally(() => {
          setLoadingChangeImage(false);
          setCourseToChangeImage(null);
          setChangeImage(null);
        });
    }
  }, [courseToChangeImage, loadingChangeImage, changeImage, courses, addToast]);

  const handleDeclineChangeImage = useCallback(() => {
    setCourseToChangeImage(null);
    setChangeImage(null);
    setErrorMessageUpload('');
  }, []);

  const handleOpenModalChangeImage = (
    e: MouseEvent<HTMLButtonElement | HTMLDivElement>,
    course: ICourseDTO
  ) => {
    e.stopPropagation();
    setCourseToChangeImage(course);
  };

  const handleOpenModalCourseRestore = (
    e: MouseEvent<HTMLButtonElement | HTMLDivElement>,
    course: ICourseDTO
  ) => {
    e.stopPropagation();
    setCourseRestore(course);
  };

  const handleDeclineCourseRestore = useCallback(() => {
    setCourseRestore(null);
  }, []);

  const handleCompanyDuplicateSelectChange = (value: string) => {
    setCompanyDuplicateCourse(Number(value));
  };

  const handleAdminSubmitCompanyDuplicate = (
    e: MouseEvent<HTMLButtonElement>
  ) => {
    e.preventDefault();
    if (!courseDuplicate) return;

    setLoadingDuplicate(true);
    void handleDuplicateCourse(e, courseDuplicate);
  };

  const handleDuplicateCourse = async (
    e: MouseEvent<HTMLButtonElement | HTMLDivElement>,
    { id, title }: ICourseDTO
  ) => {
    e.stopPropagation();
    try {
      await duplicateCourse({
        id,
        clientId: companyDuplicateCourse ?? 0,
      }).then(() => {
        if (
          companyDuplicateCourse === selectedCompany?.id ||
          companyDuplicateCourse === Number(companyId)
        ) {
          searchCourses(true);
        }
        addToast({
          title: 'Course duplicated',
          description: `Course ${title} was successfully duplicated`,
          styleType: 'success',
          dataCy: 'duplicate-course-success-toast',
        });
      });
    } catch (err) {
      addToast({
        title: 'Error on duplicate course',
        description:
          'An error occurred. Please try again or contact Edge support.',
        styleType: 'error',
        dataCy: 'duplicate-course-error-toast',
      });
    }

    setCourseDuplicate(null);
    setLoadingDuplicate(false);
    setDialogDuplicateOpen(false);
  };

  const handleToAdminDuplicateCourse = (
    e: MouseEvent<HTMLButtonElement | HTMLDivElement>,
    course: ICourseDTO
  ) => {
    e.stopPropagation();
    setCourseDuplicate(course);
    setCompanyDuplicateCourse(Number(companyId));
    setDialogDuplicateOpen(true);
  };

  const addCourseFromTemplates = useCallback(
    async ({
      pageTemplateIds,
    }: Pick<ICreateCourseFromTemplatesProps, 'pageTemplateIds'>) => {
      try {
        const courseResult = await createCourseFromTemplates({
          companyId,
          title: `New course from ${pageTemplateIds.length} template${
            pageTemplateIds.length && 's'
          }`,
          passcodeProtected: false,
          pageTemplateIds,
        });

        const state: IEditCourseState = {
          course: courseResult,
          from: generatePath(COURSES_PATH, { companyId }),
        };
        navigate(
          generatePath(getRouteFrom(EDIT_COURSE), {
            companyId: String(companyId),
            courseId: String(courseResult.id),
          }),
          { state }
        );
      } catch (error) {
        Logger.debug('error: ', error);
        addToast({
          title: 'Error on create course',
          description:
            'An error occurred. Please try again or contact Edge support.',
          styleType: 'error',
          dataCy: 'create-course-from-template-error-toast',
        });
      }
    },
    [addToast, companyId, navigate]
  );

  const addCourseFromCourseTemplates = useCallback(
    async ({
      templateGroupId,
      title,
    }: Pick<
      ICreateCourseUsingCourseGroupProps,
      'templateGroupId' | 'title'
    >) => {
      try {
        const courseResult = await createCourseUsingCourseGroup({
          companyId,
          title:
            title === ''
              ? `New course from ${templateGroupId} group template`
              : title,
          passcodeProtected: false,
          templateGroupId,
        });

        const state: IEditCourseState = {
          course: courseResult,
          from: generatePath(COURSES_PATH, { companyId }),
        };
        navigate(
          generatePath(getRouteFrom(EDIT_COURSE), {
            companyId: String(companyId),
            courseId: String(courseResult.id),
          }),
          { state }
        );
      } catch (error) {
        Logger.debug('error: ', error);
        addToast({
          title: 'Error on create course',
          description:
            'An error occurred. Please try again or contact Edge support.',
          styleType: 'error',
          dataCy: 'create-course-from-template-error-toast',
        });
      }
    },
    [addToast, companyId, navigate]
  );

  const handleAddCourseFromTemplate = () => {
    setIsModalCreateCourseFromTemplateOpen(true);
  };

  const handleDeclineAddCourseFromTemplate = () => {
    setIsModalCreateCourseFromTemplateOpen(false);
  };

  const handleAddCourseFromCourseTemplate = () => {
    setIsModalCreateCourseFromCourseTemplateOpen(true);
  };
  const handleDeclineAddCourseFromCourseTemplate = () => {
    setIsModalCreateCourseFromCourseTemplateOpen(false);
  };

  const handleAddBlankCourse = useCallback(() => {
    createCourse({
      companyId,
      title: 'Untitled',
      passcodeProtected: false,
    })
      .then((course) => {
        const state: IEditCourseState = {
          course,
          from: generatePath(COURSES_PATH, { companyId }),
        };

        navigate(
          getRouteFrom(EDIT_COURSE)
            .replace(':companyId', companyId)
            .replace(':courseId', String(course.id)),
          { state }
        );
      })
      .catch((error) => {
        Logger.debug('error: ', error);
      });
  }, [companyId, navigate]);

  const hasDropdownOpen =
    isDropdownActionsOpen || isDropdownAddCourseOpen || isDropdownOpenGrid;

  const canEditCourse = useCan(RolesNotViewer);

  const { canEditCourseBoolean, editCourseReference } = useMemo(() => {
    const canEditCourseBoolean = canEditCourse ?? false;
    return {
      canEditCourseBoolean,
      editCourseReference: canEditCourseBoolean
        ? getRouteFrom(EDIT_COURSE).replace(':companyId', companyId.toString())
        : '.',
    };
  }, [canEditCourse, companyId]);

  const handleClickOnCard = useCallback(
    (editCoursePath: string, course: ICourseDTO) => {
      const state: IEditCourseState = {
        course,
        from: pathname,
      };
      navigate(editCoursePath, { state });
    },
    [pathname, navigate]
  );

  const searchCourses = (searchByButton = false) => {
    if (!loadingCourses || searchByButton) {
      setLoadingCourses(true);
      const pageAux = searchByButton
        ? 0
        : Number((paginationMeta?.page ?? 0) > 0 ? paginationMeta?.page : 0);
      getCoursesByCompanyId({
        companyId,
        page: pageAux + 1,
        limit: 15,
        filter: search,
      })
        .then((response: IPaginationCoursetDTO) => {
          setPaginationMeta(response.meta);

          if (!searchByButton) {
            setCourses(
              produce((draft) => {
                draft.push(...response.data);
              })
            );
          } else {
            setCourses(response.data);
          }
        })
        .catch((error: IApiThrowsError) => {
          Logger.debug('error: ', error);
        })
        .finally(() => {
          setLoadingCourses(false);
        });
    }
  };

  useEffect(() => {
    let timer: NodeJS.Timeout | null = null;

    if (search.trim() !== lastSearch.current) {
      const execSearch = () => {
        lastSearch.current = search.trim();
        setCourses([]);
        searchCourses(true);
      };

      if (search.trim() === '') {
        execSearch();
      } else {
        timer = setTimeout(execSearch, 2000);
      }
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [search]);

  const handleOpenChange = useCallback((isOpen: boolean, key: string) => {
    if (isOpen) {
      isSelectOpen.current.add(key);
    } else {
      isSelectOpen.current.delete(key);
    }
  }, []);

  const handleChangeOrientation = useCallback((value: string) => {
    setOrientation(value);
  }, []);

  const handleChangeLanguage = useCallback((value: string) => {
    setLanguage(value);
  }, []);

  const handleSubmitDownloadPdf = useCallback(() => {
    if (!courseToDownload) {
      return;
    }

    setLoadingDownload(true);
    const courseDownloadName = courseToDownload.title;
    const courseDownloadLanguage =
      languageData.find(({ key }) => key === language)?.value ?? 'English';
    const toastTitle = courseDownloadName + ' - ' + courseDownloadLanguage;
    void downloadCourse({
      id: courseToDownload.id,
      language: language !== 'en' ? language : '',
      orientation,
    })
      .then((course) => {
        addToast({
          title: toastTitle,
          description: course.message,
          styleType: 'success',
          dataCy: 'success-toast',
        });
      })
      .catch(() => {
        setTimeout(() => {
          addToast({
            title: 'Something went wrong',
            description: 'There was an error downloading the course',
            styleType: 'error',
            dataCy: 'error-toast',
          });
        }, 2000);
      })
      .finally(() => {
        setCourseToDownload(null);
        setLoadingDownload(false);
      });
  }, [addToast, courseToDownload, language, orientation]);

  return (
    <Container data-cy="page-container">
      <Header>
        <TitlePage data-cy="title-pageName">Courses</TitlePage>
        <Can roles={NotViewer}>
          <DropdownButtonContainer data-cy="add-button-container">
            <BaseDropdown
              onOpenChange={(open) => {
                setIsDropdownAddCourseOpen(open);
              }}
              customTrigger={
                <Button dataCy="button-addCourse" noPaddingRight>
                  <ButtonContent>
                    <p>Add Course</p>
                    <IconContainer>
                      <ArrowDown />
                    </IconContainer>
                  </ButtonContent>
                </Button>
              }
            >
              <DropdownItem
                data-cy="button-useTemplates"
                onClick={handleAddCourseFromTemplate}
                style={{ marginBottom: '0.25rem' }}
              >
                <p>
                  <strong data-cy="text-useTemplates">
                    Create course from template
                  </strong>
                </p>
              </DropdownItem>
              <DropdownItem
                data-cy="start-from-course-templates"
                onClick={handleAddCourseFromCourseTemplate}
                style={{ marginBottom: '0.25rem' }}
              >
                <p>
                  <strong data-cy="option-from-group">
                    Create course from course template
                  </strong>
                </p>
              </DropdownItem>
              <DropdownItem
                data-cy="button-useScratch"
                onClick={handleAddBlankCourse}
              >
                <p>
                  <strong data-cy="text-useScratch">
                    Create course from scratch
                  </strong>
                </p>
              </DropdownItem>
            </BaseDropdown>
          </DropdownButtonContainer>
        </Can>
      </Header>

      <GridListOptions>
        <Option
          color={optionStyle === 'list' ? '#00426b' : '#414042'}
          data-cy="coursetList"
          onClick={() => {
            selectListOptionStyle({ optionStyle: 'list' });
            setOptionStyle('list');
          }}
        >
          <ListIcon />
        </Option>
        <Option
          color={optionStyle === 'grid' ? '#00426b' : '#414042'}
          data-cy="courseGrid"
          onClick={() => {
            selectListOptionStyle({ optionStyle: 'grid' });
            setOptionStyle('grid');
          }}
        >
          <GridIcon />
        </Option>
      </GridListOptions>
      <MainContainer data-cy="courses-card-list">
        <SearchContainer>
          <SearchBar
            search={search}
            placeholder="Search course"
            onChange={(e) => {
              if (e.target.value.length <= 90) {
                setSearch(e.target.value);
              }
            }}
            onClearInput={() => {
              setSearch('');
            }}
            loading={loadingCourses}
          />
        </SearchContainer>

        <ContentPagination dataCy="content-courses">
          {loadingCourses && courses.length === 0 ? (
            <LoadingContainer data-cy="loading-courses-container">
              <Loading
                data-cy="loading-courses"
                src={LoadingSpinnerAnimated}
                isLoading={loadingCourses}
              />
            </LoadingContainer>
          ) : optionStyle === 'list' && courses.length > 0 ? (
            <InfiniteScroll
              height={200}
              style={{
                overflow: 'auto',
                maxHeight: '100%',
                display: 'flex',
                flexDirection: 'column',
                flexGrow: 1,
              }}
              dataLength={courses.length} // This is important field to render the next data
              next={searchCourses}
              hasMore={courses.length < (paginationMeta?.total ?? 0)}
              loader={
                <LoadingContainer data-cy="loading-courses-container">
                  <LoadingList />
                </LoadingContainer>
              }
              endMessage={
                <CenteredText
                  message="You have seen it all"
                  dataCy="pagination-endLimit"
                />
              }
            >
              {courses.map((course) => {
                const { id, title, updatedAt, courseFile, viewURL } = course;
                const editCoursePath = editCourseReference.replace(
                  ':courseId',
                  id.toString()
                );

                return (
                  <CourseCard
                    data-cy={`${title}`}
                    key={id}
                    disabled={!canEditCourseBoolean}
                    onClick={() => {
                      handleClickOnCard(editCoursePath, course);
                    }}
                  >
                    <ImageContainer data-cy={'course-image-container'}>
                      <LazyImageCustom
                        src={courseFile?.path ?? ''}
                        alt="course-image"
                        dataCy={courseFile?.name ?? 'course-image'}
                      />
                    </ImageContainer>
                    <TextContent data-cy="info-container">
                      <CourseName data-cy={title}>
                        {title}
                        {course.status === 'Completed' && <ConcludedIcon />}
                        {course.status === 'Disabled' && <DisabledIcon />}
                      </CourseName>
                      {true && (
                        <Date data-cy="course-last-update">
                          {format(parseJSON(updatedAt), 'LLLL u')}
                        </Date>
                      )}
                    </TextContent>
                    <CourseActions data-cy="actions-container">
                      <OnDesktopView data-cy="desktop-view">
                        <ActionsButtonContainer data-cy="fast-actions-container">
                          <Can roles={NotViewer}>
                            <BaseTooltip message="Edit">
                              <ActionButton
                                data-cy="button-edit"
                                onClick={() => {
                                  handleClickOnCard(editCoursePath, course);
                                }}
                              >
                                <EditIcon />
                              </ActionButton>
                            </BaseTooltip>
                            <BaseTooltip message="Status">
                              <ActionButton
                                data-cy="change-status"
                                onClick={(e) => {
                                  handleOpenModalChangeStatus(e, course);
                                }}
                              >
                                <ConcludeIcon />
                              </ActionButton>
                            </BaseTooltip>
                            <BaseTooltip message="Delete">
                              <ActionButton
                                data-cy="button-delete"
                                onClick={(e) => {
                                  handleOpenModalDelete(e, course);
                                }}
                              >
                                <DeleteIcon />
                              </ActionButton>
                            </BaseTooltip>
                          </Can>
                          <BaseTooltip message="Download">
                            <ActionButton
                              data-cy="button-download"
                              onClick={(e) => {
                                courseDownload(e, course);
                              }}
                            >
                              <DownloadIcon />
                            </ActionButton>
                          </BaseTooltip>
                          <Can roles={NotViewer}>
                            <BaseTooltip message="Duplicate">
                              <ActionButton
                                data-cy="button-duplicate"
                                onClick={(e) => {
                                  if (
                                    currentRole?.level &&
                                    currentRole?.level > 3
                                  ) {
                                    handleToAdminDuplicateCourse(e, course);
                                  } else {
                                    void handleDuplicateCourse(e, course);
                                  }
                                }}
                              >
                                <DuplicateIcon />
                              </ActionButton>
                            </BaseTooltip>
                            <BaseTooltip message="Change image">
                              <ActionButton
                                data-cy="button-changeImage"
                                onClick={(e) => {
                                  handleOpenModalChangeImage(e, course);
                                }}
                              >
                                <ImageIcon />
                              </ActionButton>
                            </BaseTooltip>
                          </Can>
                          {(canEditCourseBoolean || viewURL) && (
                            <BaseTooltip message="Preview">
                              <ActionButton
                                data-cy="button-preview"
                                onClick={(e) => {
                                  viewCourse(e, course);
                                }}
                              >
                                <PreviewIcon />
                              </ActionButton>
                            </BaseTooltip>
                          )}
                          <Can roles={NotViewer}>
                            <BaseTooltip message="Course restore">
                              <ActionButton
                                data-cy="button-courseRestore"
                                onClick={(e) => {
                                  handleOpenModalCourseRestore(e, course);
                                }}
                              >
                                <HistoryIcon />
                              </ActionButton>
                            </BaseTooltip>
                          </Can>
                        </ActionsButtonContainer>
                        <MainButtonContainer data-cy="main-actions-container">
                          {(canEditCourseBoolean || viewURL) && (
                            <BaseTooltip
                              message={viewURL ? 'Copy link' : 'Add link'}
                            >
                              <MainButtonCustom
                                styleType="text"
                                dataCy="button-send"
                                onClick={(e) => {
                                  handleOpenModalCreateViewURL(e, course);
                                }}
                              >
                                {viewURL ? (
                                  <LinkIcon data-cy="button-copyLink" />
                                ) : (
                                  <AddLinkIcon data-cy="button-addLink" />
                                )}
                              </MainButtonCustom>
                            </BaseTooltip>
                          )}
                        </MainButtonContainer>
                      </OnDesktopView>
                      <OnMobileView data-cy="mobile-view">
                        <DropdownActionButtonContainer data-cy="fast-actions-container">
                          <BaseDropdown
                            dataCy={`dropdown-action-menu-button`}
                            customTrigger={<TriggerDropdownAction />}
                            onOpenChange={(open) => {
                              setIsDropdownActionsOpen(open);
                            }}
                          >
                            {(canEditCourseBoolean || viewURL) && (
                              <>
                                <DropdownActionItem
                                  onClick={(e) => {
                                    handleOpenModalCreateViewURL(e, course);
                                  }}
                                >
                                  {viewURL ? 'Copy link' : 'Add link'}
                                </DropdownActionItem>
                                <DropdownActionSeparator />
                              </>
                            )}
                            <Can roles={NotViewer}>
                              <DropdownActionItem
                                data-cy="button-edit"
                                onClick={() => {
                                  handleClickOnCard(editCoursePath, course);
                                }}
                              >
                                Edit
                              </DropdownActionItem>
                              <DropdownActionItem
                                data-cy="change-status"
                                onClick={(e) => {
                                  handleOpenModalChangeStatus(e, course);
                                }}
                              >
                                Status
                              </DropdownActionItem>
                              <DropdownActionItem
                                data-cy="button-delete"
                                onClick={(e) => {
                                  handleOpenModalDelete(e, course);
                                }}
                              >
                                Delete
                              </DropdownActionItem>
                            </Can>
                            <DropdownActionItem
                              data-cy="button-download"
                              onClick={(e) => {
                                courseDownload(e, course);
                              }}
                            >
                              Download
                            </DropdownActionItem>
                            <Can roles={NotViewer}>
                              <DropdownActionItem
                                data-cy="button-duplicate"
                                onClick={(e) => {
                                  if (
                                    currentRole?.level &&
                                    currentRole?.level > 3
                                  ) {
                                    handleToAdminDuplicateCourse(e, course);
                                  } else {
                                    void handleDuplicateCourse(e, course);
                                  }
                                }}
                              >
                                Duplicate
                              </DropdownActionItem>
                              <DropdownActionItem
                                data-cy="button-changeImage"
                                onClick={(e) => {
                                  handleOpenModalChangeImage(e, course);
                                }}
                              >
                                Change image
                              </DropdownActionItem>
                            </Can>
                            {(canEditCourseBoolean || viewURL) && (
                              <DropdownActionItem
                                data-cy="button-preview"
                                onClick={(e) => {
                                  viewCourse(e, course);
                                }}
                              >
                                Preview
                              </DropdownActionItem>
                            )}
                          </BaseDropdown>
                        </DropdownActionButtonContainer>
                      </OnMobileView>
                    </CourseActions>
                  </CourseCard>
                );
              })}
            </InfiniteScroll>
          ) : optionStyle === 'grid' && courses.length > 0 ? (
            <InfiniteScroll
              height={200}
              style={{
                overflow: 'auto',
                maxHeight: '100%',
                display: 'flex',
                flexDirection: 'column',
                flexGrow: 1,
              }}
              dataLength={courses.length} // This is important field to render the next data
              next={searchCourses}
              hasMore={courses.length < (paginationMeta?.total ?? 0)}
              loader={
                <LoadingContainer data-cy="loading-courses-container">
                  <LoadingList />
                </LoadingContainer>
              }
              endMessage={
                <CenteredText
                  message="You have seen it all"
                  dataCy="pagination-endLimit"
                />
              }
            >
              <GridContent>
                {courses.map((course) => {
                  const { id, title, courseFile, viewURL } = course;
                  const editCoursePath = editCourseReference.replace(
                    ':courseId',
                    id.toString()
                  );
                  return (
                    <GridItem key={course.id}>
                      <CourseCardGrid
                        data-cy={`${title}`}
                        disabled={!canEditCourseBoolean}
                        onClick={() => {
                          handleClickOnCard(editCoursePath, course);
                        }}
                      >
                        <ImageContainerGrid data-cy={'course-image-container'}>
                          <LazyImageGrid
                            src={courseFile?.path ?? ''}
                            alt="course-image"
                            dataCy={courseFile?.name ?? 'course-image'}
                          />
                        </ImageContainerGrid>
                        <ContentCard>
                          {course.status === 'Completed' && <ConcludedIcon />}
                          {course.status === 'Disabled' && <DisabledIcon />}
                          <BaseTooltip message={title}>
                            <CourseName data-cy="label-card">
                              {title}
                            </CourseName>
                          </BaseTooltip>

                          <DropdownButtonContainer data-cy="dropdown-container">
                            <BaseDropdown
                              dataCy={`dropdown-options-button`}
                              onOpenChange={(isOpen) => {
                                setIsDropdownGridOpen(isOpen);
                              }}
                            >
                              <Can roles={NotViewer}>
                                <DropdownItemGrid
                                  data-cy={`button-edit`}
                                  onClick={() => {
                                    handleClickOnCard(editCoursePath, course);
                                  }}
                                  asChild
                                >
                                  <p>Edit</p>
                                </DropdownItemGrid>
                                <DropdownItemGrid
                                  data-cy={`change-status`}
                                  onClick={(e) => {
                                    handleOpenModalChangeStatus(e, course);
                                  }}
                                  asChild
                                >
                                  <p>Status</p>
                                </DropdownItemGrid>
                                <DropdownItemGrid
                                  data-cy={`button-delete`}
                                  onClick={(e) => {
                                    handleOpenModalDelete(e, course);
                                  }}
                                  asChild
                                >
                                  <p>Delete</p>
                                </DropdownItemGrid>
                              </Can>
                              <DropdownItemGrid
                                data-cy={`button-download`}
                                onClick={(e) => {
                                  courseDownload(e, course);
                                }}
                                asChild
                              >
                                <p>Download</p>
                              </DropdownItemGrid>
                              <Can roles={NotViewer}>
                                <DropdownItemGrid
                                  data-cy={`button-duplicate`}
                                  onClick={(e) => {
                                    if (
                                      currentRole?.level &&
                                      currentRole?.level > 3
                                    ) {
                                      handleToAdminDuplicateCourse(e, course);
                                    } else {
                                      void handleDuplicateCourse(e, course);
                                    }
                                  }}
                                  asChild
                                >
                                  <p>Duplicate</p>
                                </DropdownItemGrid>
                                <DropdownItemGrid
                                  data-cy={`button-changeImage`}
                                  onClick={(e) => {
                                    handleOpenModalChangeImage(e, course);
                                  }}
                                  asChild
                                >
                                  <p>Change image</p>
                                </DropdownItemGrid>
                              </Can>
                              {(canEditCourseBoolean || viewURL) && (
                                <DropdownItemGrid
                                  data-cy={`button-preview`}
                                  onClick={(e) => {
                                    viewCourse(e, course);
                                  }}
                                  asChild
                                >
                                  <p>Preview</p>
                                </DropdownItemGrid>
                              )}
                              <Can roles={NotViewer}>
                                <DropdownItemGrid
                                  data-cy={`button-courseRestore`}
                                  onClick={(e) => {
                                    handleOpenModalCourseRestore(e, course);
                                  }}
                                  asChild
                                >
                                  <p>Course Restore</p>
                                </DropdownItemGrid>
                              </Can>
                              {(canEditCourseBoolean || viewURL) && (
                                <DropdownItemGrid
                                  data-cy={`button-send`}
                                  onClick={(e) => {
                                    handleOpenModalCreateViewURL(e, course);
                                  }}
                                  asChild
                                >
                                  <p>{viewURL ? 'Copy link' : 'Add link'}</p>
                                </DropdownItemGrid>
                              )}
                            </BaseDropdown>
                          </DropdownButtonContainer>
                        </ContentCard>
                      </CourseCardGrid>
                    </GridItem>
                  );
                })}
              </GridContent>
            </InfiniteScroll>
          ) : (
            <CenteredText
              message="No courses found"
              dataCy="text-noCourses-found"
            />
          )}
        </ContentPagination>

        <AddCourseWithTemplateModal
          companyId={companyIdNumber}
          onAccept={addCourseFromTemplates}
          onDecline={handleDeclineAddCourseFromTemplate}
          open={!hasDropdownOpen && isModalCreateCourseFromTemplateOpen}
        />
        <AddCourseWithCoursesTemplateModal
          companyId={companyIdNumber}
          onAccept={addCourseFromCourseTemplates}
          onDecline={handleDeclineAddCourseFromCourseTemplate}
          open={!hasDropdownOpen && isModalCreateCourseFromCourseTemplateOpen}
        />
        <DialogModal
          acceptText="Confirm"
          dataCy="dialogModal-deleteCourse"
          declineText="Cancel"
          loading={loadingDelete}
          mainText={`Are you sure you want to delete ${
            courseToDelete?.title ?? 'this course'
          }?`}
          onAccept={sendDeleteRequest}
          onDecline={handleDeclineDelete}
          onOpenChange={handleDeclineDelete}
          open={!hasDropdownOpen && isModalDeleteOpen}
          hiddenCloseButton={true}
        />
        <DialogModal
          acceptText="Save"
          biggerModal
          dataCy="dialogModal-sendCourse"
          declineText="Cancel"
          loading={loadingCreateViewURL}
          mainText={`Send Course`}
          onAccept={handleAcceptCreateViewURL}
          onDecline={handleDeclineCreateViewURL}
          onOpenChange={handleDeclineCreateViewURL}
          open={!hasDropdownOpen && isModalCreateViewURLOpen}
          zIndex={10}
        >
          <ModalText>
            <strong data-cy="text-createURL">
              Please create the URL that this Course will be available through
            </strong>
          </ModalText>
          <ViewURLInputContainer>
            <ModalText data-cy="textPlaceholder-URL">
              {import.meta.env.VITE_COURSE_VIEW_SUBDOMAIN_URL ??
                window.location.origin + '/view'}
              /
            </ModalText>
            <ViewURLInput
              data-cy="input-viewURL"
              placeholder="Enter View URL"
              value={currentViewURL}
              onChange={handleOnChangeViewURL}
              autoFocus
            />
          </ViewURLInputContainer>
          <ModalText data-cy="text-description">
            This is the link you will share so people can view your course.
          </ModalText>
        </DialogModal>
        <UploadModal
          accept="image/*"
          acceptText="Update"
          dataCy="dialogModal-changeImage"
          declineText="Cancel"
          loading={loadingChangeImage}
          mainText={`Are you sure you want to change image?`}
          onAccept={handleAcceptChangeImage}
          onChange={handleChangeImage}
          disabled={!changeImage || Boolean(errorMessageUpload)}
          onDecline={handleDeclineChangeImage}
          onOpenChange={handleDeclineChangeImage}
          open={!hasDropdownOpen && isModalChangeImageOpen}
          errorMessage={errorMessageUpload}
          fileSizeLimit={`${displayInMegaBytes(HEADER_IMAGE_MAX_SIZE)} MB`}
          fileDimensionsLimit={`${HEADER_IMAGE_MAX_WIDTH} x ${HEADER_IMAGE_MAX_HEIGHT} px`}
        />

        <RestorePageModal
          open={Boolean(courseRestore)}
          onAccept={handleRestoreCourse}
          onDecline={handleDeclineCourseRestore}
          course={courseRestore}
          zIndex={10}
        />

        <FormModal
          acceptText="Confirm"
          declineText="Cancel"
          mainText="Download Course PDF"
          open={!hasDropdownOpen && isModalDownloadOpen}
          onDecline={resetFormDownload}
          onOpenChange={resetFormDownload}
          onAccept={handleSubmitDownloadPdf}
          loading={loadingDownload}
        >
          <>
            <FormBase.InputContent
              data-cy="fieldSet-orientation"
              filled={!!orientation}
              label="Orientation"
              inputRef="orientation"
            >
              <SelectData
                data={[
                  { key: 'portrait', value: 'Portrait' },
                  { key: 'landscape', value: 'Landscape' },
                ]}
                dataCy="select-orientation"
                name="orientation"
                onValueChange={handleChangeOrientation}
                value={orientation}
                zIndex={10}
                disabled={loadingDownload}
                onOpenChange={(isOpen) => {
                  handleOpenChange(isOpen, 'orientation');
                }}
              />
            </FormBase.InputContent>

            <FormBase.InputContent
              data-cy="fieldSet-language"
              filled={!!language}
              label="Language"
              inputRef="language"
            >
              <SelectData
                data={languageData}
                dataCy="select-language"
                name="language"
                onValueChange={handleChangeLanguage}
                value={language}
                zIndex={10}
                disabled={loadingDownload}
                onOpenChange={(isOpen) => {
                  handleOpenChange(isOpen, 'language');
                }}
              />
            </FormBase.InputContent>
          </>
        </FormModal>

        <DuplicateCourseModal
          loading={loadingDuplicate}
          onAccept={handleAdminSubmitCompanyDuplicate}
          onDecline={resetFormDuplicate}
          onValueChange={handleCompanyDuplicateSelectChange}
          open={!hasDropdownOpen && dialogDuplicateOpen}
          errorMessage={errorDuplicate.other}
        />

        <DialogModal
          acceptText="Update Status"
          dataCy="change-status-dialog-modal"
          declineText="Cancel"
          loading={loadingChangeStatus}
          mainText={`Do you want to change the status of the course ${
            courseToChangeStatus?.title ?? ''
          }?`}
          onAccept={handleAcceptChangeStatus}
          onDecline={handleDeclineChangeStatus}
          onOpenChange={handleDeclineChangeStatus}
          open={!hasDropdownOpen && isModalChangeStatusOpen}
        >
          <FieldsetCustom data-cy="fieldSet-status" filled={!!newCourseStatus}>
            <SelectData
              data={[
                { key: 'Completed', value: 'Completed' },
                { key: 'In Progress', value: 'In Progress' },
                { key: 'Disabled', value: 'Disabled' },
              ]}
              dataCy="course-status-field"
              name="course-status"
              placeholder="Select Status"
              defaultValue={newCourseStatus ?? 'In Progress'}
              onValueChange={(value) => {
                setNewCourseStatus(value);
              }}
              onOpenChange={(isOpen) => {
                handleOpenChange(isOpen, 'status');
              }}
            />
            <Label data-cy="text-course-status">Status</Label>
          </FieldsetCustom>
        </DialogModal>
      </MainContainer>
    </Container>
  );
};

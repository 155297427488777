import { produce } from 'immer';
import {
  type ChangeEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useNavigate, useParams } from 'react-router-dom';

import { SearchBar } from '@gbs-monorepo-packages/common';

import { BaseDropdown } from '../../components/BaseDropdown';
import { Button } from '../../components/Button';
import { CenteredText } from '../../components/CenteredText';
import { ContentPagination } from '../../components/ContentPagination';
import { DialogModal } from '../../components/DialogModal';
import { UploadModal } from '../../components/UploadModal';
import { REDIRECT } from '../../constants/RoutePaths';
import { useToast } from '../../hooks/useToast';
import {
  type IApiThrowsError,
  type IPaginationMetaProps,
} from '../../services/api';
import {
  type IDocumentDTO,
  type IPaginationDocumentDTO,
  deleteDocument,
  getListDocumentsByCompany,
  renameDocument,
  uploadDocument,
} from '../../services/documents';
import { getRouteFrom } from '../../utils/getRoutes';
import Logger from '../../utils/logger';
import {
  ButtonContent,
  DocumentCard,
  DocumentName,
  DocumentNameContainer,
  DocumentTypeIcon,
  DocumentTypeIconContainer,
  DropdownButtonContainer,
  DropdownItem,
  Header,
  Loading,
  LoadingContainer,
  MainContainer,
  MainContent,
  RenameInput,
  RenameInputContainer,
  SearchContainer,
  TitlePage,
  TitleSectionContainer,
  UploadButtonContainer,
} from './styles';

const imageExtensions = '.jpg, .jpeg, .png, .svg, .gif, .bmp, .webp';
const videoExtensions = '.mp4, .mov, .wmv, .mkv, .webm';
const audioExtensions = '.mp3, .wav';
const textExtensions = '.csv, .txt, .htm, .html';
const otherExtensions = '.doc, .docx, .xls, .xlsx, .ppt, .pptx, .pdf';
const acceptedExtensions = `${imageExtensions}, ${videoExtensions}, ${audioExtensions}, ${textExtensions}, ${otherExtensions}`;

export const Documents = (): JSX.Element => {
  const [loadingCourses, setLoadingCourses] = useState(false);
  const { companyId = '' } = useParams();
  const navigate = useNavigate();
  const [changeImage, setChangeImage] = useState<File | null>(null);
  const [documents, setDocuments] = useState<IDocumentDTO[]>([]);
  const [isUploadDocumentModalOpen, setUploadDocumentModalOpen] =
    useState<boolean>(false);
  const [loadingDocumentAction, setLoadingDocumentAction] =
    useState<boolean>(false);
  const [isRenameDocumentModalOpen, setRenameDocumentModalOpen] =
    useState<boolean>(false);
  const [renameValue, setRenameValue] = useState('');
  const [documentToRename, setDocumentToRename] = useState<IDocumentDTO | null>(
    null
  );

  const [search, setSearch] = useState('');
  const lastSearch = useRef(search);
  const [paginationMeta, setPaginationMeta] =
    useState<IPaginationMetaProps | null>(null);

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const { addToast } = useToast();

  const [documentToDelete, setDocumentToDelete] = useState<IDocumentDTO | null>(
    null
  );
  const isModalDeleteOpen = !!documentToDelete;

  useEffect(() => {
    let mount = true;
    const syncDocuments = async () => {
      try {
        setLoadingCourses(true);
        const fetchedDocuments = await getListDocumentsByCompany({
          companyId,
          page: 1,
          limit: 15,
          filter: search,
        });

        if (mount) {
          setDocuments(fetchedDocuments.data);
          setPaginationMeta(fetchedDocuments.meta);
        }
      } catch (err) {
        Logger.debug('err: ', err);
      } finally {
        if (mount) {
          setLoadingCourses(false);
        }
      }
    };

    if (Number(companyId) <= 0) {
      navigate(getRouteFrom(REDIRECT));
    } else {
      void syncDocuments();
    }

    return () => {
      mount = false;
    };
  }, [companyId, navigate]);

  const handleChangeUploadDocument = useCallback(
    ({ target }: ChangeEvent<HTMLInputElement>) => {
      const { files } = target;
      if (files?.item(0)) {
        setChangeImage(files.item(0));
      }
    },
    []
  );

  const handleUploadDocument = useCallback(() => {
    if (!loadingDocumentAction && changeImage) {
      setLoadingDocumentAction(true);
      uploadDocument({ id: Number(companyId), newImage: changeImage })
        .then(() => {
          searchDocuments(true);
          addToast({
            title: 'File uploaded',
            description: 'File was successfully uploaded',
            styleType: 'success',
          });
        })
        .catch((error: IApiThrowsError) => {
          addToast({
            title: 'Error on upload document.',
            description:
              error.response?.data.error &&
              error.response?.data.error.code >= 500
                ? 'An error occurred. Please try again or contact Edge support.'
                : error.response?.data.error.message,
            styleType: 'error',
            dataCy: 'document-upload-error-toast',
          });
        })
        .finally(() => {
          setLoadingDocumentAction(false);
          setUploadDocumentModalOpen(false);
          setChangeImage(null);
        });
    }
  }, [changeImage, companyId, documents, loadingDocumentAction]);

  const handleDeclineUploadImage = useCallback(() => {
    setUploadDocumentModalOpen(false);
    setChangeImage(null);
  }, []);

  const handleOpenModalUploadDocument = () => {
    setUploadDocumentModalOpen(true);
  };

  useEffect(() => {
    if (documentToRename) {
      setRenameValue(documentToRename?.name);
    }
  }, [documentToRename]);

  const renameDocumentHandler = useCallback(
    (documentId: string, newName: string) => {
      if (!loadingDocumentAction) {
        setLoadingDocumentAction(true);
        renameDocument({ documentId, newName })
          .then(() => {
            searchDocuments(true);
            addToast({
              title: 'Document updated',
              description: 'Document name was successfully updated',
              styleType: 'success',
              dataCy: 'document-updated',
            });
          })
          .catch((err) => {
            addToast({
              title: 'Error on edit document name',
              description:
                'An error occurred. Please try again or contact Edge support.',
              styleType: 'error',
              dataCy: 'document-rename-error-toast',
            });
            Logger.debug('err: ', err);
          })
          .finally(() => {
            setLoadingDocumentAction(false);
            setRenameDocumentModalOpen(false);
          });
      }
    },
    [addToast, documents, loadingDocumentAction]
  );

  const deleteDocumentHandler = useCallback(() => {
    if (!documentToDelete) {
      return;
    }

    if (!loadingDocumentAction) {
      setLoadingDocumentAction(true);
      const documentId = String(documentToDelete.id);
      deleteDocument({ documentId })
        .then(() => {
          searchDocuments(true);
        })
        .catch((error) => {
          setLoadingDocumentAction(false);
          setUploadDocumentModalOpen(false);
          setDocumentToDelete(null);
          Logger.error('Error: ', error);
          addToast({
            title: 'Error on deleting document',
            description:
              'An error occurred. Please try again or contact Edge support.',
            styleType: 'error',
            dataCy: 'delete-document-error-toast',
          });
        });
    }
  }, [addToast, documentToDelete, documents, loadingDocumentAction]);

  const handleAcceptRename = useCallback(() => {
    if (!renameValue) {
      addToast({
        title: 'Error on edit document name',
        description: 'Name is required.',
        styleType: 'error',
      });
    }
    if (documentToRename && renameValue && !loadingDocumentAction) {
      renameDocumentHandler(String(documentToRename.id), renameValue);
    }
  }, [
    addToast,
    documentToRename,
    loadingDocumentAction,
    renameDocumentHandler,
    renameValue,
  ]);

  const handleDeclineRename = () => {
    setRenameDocumentModalOpen(false);
    setRenameValue('');
    setDocumentToRename(null);
    setUploadDocumentModalOpen(false);
  };

  const handleOpenRenameDocumentModal = (document: IDocumentDTO) => {
    setRenameDocumentModalOpen(true);
    setDocumentToRename(document);
  };

  const searchDocuments = (searchByButton = false) => {
    if (!loadingCourses || searchByButton) {
      setLoadingCourses(true);
      const pageAux = searchByButton
        ? 0
        : Number((paginationMeta?.page ?? 0) > 0 ? paginationMeta?.page : 0);

      getListDocumentsByCompany({
        companyId,
        page: pageAux + 1,
        limit: 15,
        filter: search,
      })
        .then((response: IPaginationDocumentDTO) => {
          setPaginationMeta(response.meta);

          if (!searchByButton) {
            setDocuments(
              produce((draft) => {
                draft.push(...response.data);
              })
            );
          } else {
            setDocuments(response.data);
          }

          if (isModalDeleteOpen) {
            setLoadingDocumentAction(false);
            setUploadDocumentModalOpen(false);
            setDocumentToDelete(null);

            addToast({
              title: 'Document deleted',
              description: `Document ${documentToDelete.name} was successfully delete`,
              styleType: 'success',
              dataCy: 'delete-document-success-toast',
            });
          }
        })
        .catch((error: IApiThrowsError) => {
          Logger.debug('error: ', error);
        })
        .finally(() => {
          setLoadingCourses(false);
        });
    }
  };

  useEffect(() => {
    let timer: NodeJS.Timeout | null = null;

    if (search.trim() !== lastSearch.current) {
      const execSearch = () => {
        lastSearch.current = search.trim();
        setDocuments([]);
        searchDocuments(true);
      };

      if (search.trim() === '') {
        execSearch();
      } else {
        timer = setTimeout(execSearch, 2000);
      }
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [search]);

  const handleOpenModalDelete = (document: IDocumentDTO) => {
    setDocumentToDelete(document);
  };

  const handleDeclineDelete = useCallback(() => {
    setDocumentToDelete(null);
  }, []);

  return (
    <MainContainer data-cy="page-container">
      <Header>
        <TitleSectionContainer>
          <TitlePage data-cy="title-pageName">Documents</TitlePage>
          <UploadButtonContainer data-cy="add-button-container">
            <Button
              dataCy="dropdown-add-button"
              onClick={handleOpenModalUploadDocument}
            >
              <ButtonContent>
                <p data-cy="button-upload">Upload</p>
              </ButtonContent>
            </Button>
          </UploadButtonContainer>
        </TitleSectionContainer>
      </Header>

      <MainContent data-cy="courses-card-list">
        <SearchContainer>
          <SearchBar
            search={search}
            placeholder="Search document"
            onChange={(e) => {
              if (e.target.value.length <= 50) {
                setSearch(e.target.value);
              }
            }}
            onClearInput={() => {
              setSearch('');
            }}
            loading={loadingCourses}
          />
        </SearchContainer>
        <ContentPagination dataCy="content-documents">
          {loadingCourses && documents.length === 0 ? (
            <LoadingContainer data-cy="loading-documents-container">
              <Loading dataCy="loading-documents" />
            </LoadingContainer>
          ) : documents.length > 0 ? (
            <InfiniteScroll
              height={200}
              style={{
                overflow: 'auto',
                maxHeight: '100%',
                display: 'flex',
                flexDirection: 'column',
                flexGrow: 1,
              }}
              dataLength={documents.length} // This is important field to render the next data
              next={searchDocuments}
              hasMore={documents.length < (paginationMeta?.total ?? 0)}
              loader={
                <LoadingContainer data-cy="loading-documents-container">
                  <Loading />
                </LoadingContainer>
              }
              endMessage={
                <CenteredText
                  message="You have seen it all"
                  dataCy="pagination-endLimit"
                />
              }
            >
              {documents.map((document: IDocumentDTO) => {
                const { id, name, path } = document;
                return (
                  <DocumentCard data-cy={id} key={id}>
                    <div
                      data-cy="document-div"
                      style={{ display: 'flex', width: '100%' }}
                    >
                      <DocumentTypeIconContainer data-cy="document-type-container">
                        <DocumentTypeIcon />
                      </DocumentTypeIconContainer>
                      <DocumentNameContainer data-cy="document-name-container">
                        <DocumentName data-cy={name}>{name}</DocumentName>
                      </DocumentNameContainer>
                    </div>
                    <DropdownButtonContainer data-cy="dropdown-container">
                      <BaseDropdown
                        dataCy={`dropdown-menu-button-${name}`}
                        onOpenChange={(isOpen) => {
                          setIsDropdownOpen(isOpen);
                        }}
                      >
                        <DropdownItem
                          onClick={() => {
                            if (!navigator.clipboard) {
                              addToast({
                                title: 'Error on copy documents link',
                                description:
                                  'Clipboard not available. Please try again or contact Edge support.',
                                styleType: 'error',
                                dataCy: 'document-link-copied-error-toast',
                              });
                              return;
                            }
                            navigator.clipboard
                              .writeText(path)
                              .then(() => {
                                addToast({
                                  title: 'Link copied',
                                  description: `Link of document ${name} was successfully copied`,
                                  styleType: 'success',
                                  dataCy: 'document-link-copied-success-toast',
                                });
                              })
                              .catch((err) => {
                                Logger.debug('err: ', err);

                                addToast({
                                  title: "Error on copy documents's link",
                                  description:
                                    'An error occurred. Please try again or contact Edge support.',
                                  styleType: 'error',
                                  dataCy: 'document-link-copied-error-toast',
                                });
                              });
                          }}
                          data-cy={`documents-copyLink-${id}`}
                        >
                          Copy Link
                        </DropdownItem>
                        <DropdownItem
                          onClick={() => {
                            handleOpenModalDelete(document);
                          }}
                          data-cy={`documents-delete-${id}`}
                        >
                          Delete
                        </DropdownItem>
                        <DropdownItem
                          onClick={() =>
                            window.open(`${path}`, '_blank')?.focus()
                          }
                          data-cy={`documents-download-${id}`}
                        >
                          Download
                        </DropdownItem>
                        <DropdownItem
                          onClick={() => {
                            handleOpenRenameDocumentModal(document);
                          }}
                          data-cy={`documents-rename-${id}`}
                        >
                          Edit
                        </DropdownItem>
                      </BaseDropdown>
                    </DropdownButtonContainer>
                  </DocumentCard>
                );
              })}
            </InfiniteScroll>
          ) : (
            <CenteredText
              message="No documents found"
              dataCy="text-noDocuments-found"
            />
          )}
        </ContentPagination>
      </MainContent>
      <DialogModal
        dataCy="delete-course-dialog-modal"
        acceptText="Update"
        declineText="Cancel"
        open={!isDropdownOpen && isRenameDocumentModalOpen}
        loading={loadingDocumentAction}
        mainText="Rename Document"
        onAccept={handleAcceptRename}
        onDecline={handleDeclineRename}
        onOpenChange={handleDeclineRename}
        zIndex={10}
      >
        <RenameInputContainer>
          <RenameInput
            autoComplete="off"
            autoFocus
            data-cy="input-rename"
            id="rename-document"
            onChange={(e) => {
              if (e.target.value.length <= 50) {
                setRenameValue(e.target.value);
              }
            }}
            onBlur={(e) => {
              setRenameValue(e.target.value.replace(/\s+/g, ' ').trim());
            }}
            required
            type="text"
            value={renameValue}
          />
        </RenameInputContainer>
      </DialogModal>
      <DialogModal
        dataCy="delete-document-dialogModal"
        acceptText="Confirm"
        declineText="Cancel"
        open={!isDropdownOpen && isModalDeleteOpen}
        loading={loadingDocumentAction}
        mainText={`Are you sure you want to delete ${
          documentToDelete?.name ?? 'this document'
        }?`}
        onAccept={deleteDocumentHandler}
        onDecline={handleDeclineDelete}
        onOpenChange={handleDeclineDelete}
        zIndex={10}
      />
      <UploadModal
        data-cy="upload-document-dialog-modal"
        acceptText="Upload"
        declineText="Cancel"
        open={isUploadDocumentModalOpen}
        loading={loadingDocumentAction}
        mainText="Upload Document"
        description="Please select the document you wish to upload:"
        disabled={!changeImage}
        onAccept={handleUploadDocument}
        onChange={handleChangeUploadDocument}
        onDecline={handleDeclineUploadImage}
        onOpenChange={handleDeclineUploadImage}
        accept={acceptedExtensions}
      />
    </MainContainer>
  );
};

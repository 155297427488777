import { MdOutlineCancel } from 'react-icons/md';
import styled, { css, keyframes } from 'styled-components';

import { colors, radii } from '@gbs-monorepo-packages/styles';
import * as ToastPrimitive from '@radix-ui/react-toast';

const VIEWPORT_PADDING = 25;

const hide = keyframes`
  0% { opacity: 1 };
  100% { opacity: 0 };
`;

const slideIn = keyframes`
  from { transform: 'translateX(calc(100% + ${VIEWPORT_PADDING}px))' };
  to { transform: 'translateX(0)' };
`;

const swipeOut = keyframes`
  from { transform: 'translateX(var(--radix-toast-swipe-end-x))' };
  to { transform: 'translateX(calc(100% + ${VIEWPORT_PADDING}px))' };
`;

export const ToastViewport = styled(ToastPrimitive.Viewport)`
  position: fixed;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  padding: ${VIEWPORT_PADDING}px;
  gap: 10px;
  width: 400px;
  max-width: 100vw;
  margin: 0;
  list-style: none;
  z-index: 2147483647;
  outline: none;
`;

export const Toast = styled(ToastPrimitive.Root)`
  background-color: ${colors.white300};
  border-radius: ${radii.sm};
  box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px,
    hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
  padding: 15px;
  display: grid;
  grid-template-areas: 'title close' 'description close';
  grid-template-columns: auto max-content;
  column-gap: 15px;
  align-items: center;

  @media (prefers-reduced-motion: no-preference) {
    &[data-state='open'] {
      animation: ${slideIn} 150ms cubic-bezier(0.16, 1, 0.3, 1);
    }
    &[data-state='closed'] {
      animation: ${hide} 100ms ease-in;
    }
    &[data-swipe='move'] {
      transform: translateX(var(--radix-toast-swipe-move-x));
    }
    &[data-swipe='cancel'] {
      transform: translateX(0);
      transition: transform 200ms ease-out;
    }
    &[data-swipe='end'] {
      animation: ${swipeOut} 100ms ease-out;
    }
  }
`;

interface IToastProps {
  styleType: 'error' | 'info' | 'success' | 'warning';
}

export const CustomToast = styled.li<IToastProps>`
  ${({ styleType }) => {
    switch (styleType) {
      case 'error':
        return css`
          background-color: ${colors.toastError};
          color: ${colors.toastTextError};
        `;

      case 'success':
        return css`
          background-color: ${colors.toastSuccess};
          color: ${colors.toastTextSuccess};
        `;

      case 'warning':
        return css`
          background-color: ${colors.white300};
          color: ${colors.toastTextWarning};

          & button {
            color: ${colors.toastWarning};
          }
        `;

      default:
        return css`
          background-color: ${colors.white300};
          color: ${colors.toastTextInfo};

          & button {
            color: ${colors.toastInfo};
          }
        `;
    }
  }};
`;

export const ToastTitle = styled(ToastPrimitive.Title)`
  grid-area: title;
  margin-bottom: 5px;
  font-weight: 700;
  font-size: 1rem;
`;

export const ToastDescription = styled(ToastPrimitive.Description)`
  grid-area: description;
  margin: 0;
  font-weight: 300;
  font-size: 0.875rem;
  line-height: 1.3;
  word-break: break-word;
`;

export const ToastClose = styled(ToastPrimitive.Close)`
  all: unset;
  align-items: center;
  border-radius: ${radii.full};
  display: inline-flex;
  font-family: inherit;
  height: 1.5rem;
  justify-content: center;
  width: 1.5rem;
  grid-area: close;

  &:hover {
    background-color: ${colors.backgroundHover};
  }
`;

export const CloseIcon = styled(MdOutlineCancel)`
  height: 1.5rem;
  width: 1.5rem;
`;

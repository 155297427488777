import { MdOutlineVisibility } from 'react-icons/md';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { colors, radii } from '@gbs-monorepo-packages/styles';

import {
  CheckBoxCard,
  CheckBoxGroup,
} from '../../../../components/CheckBoxGroup';

export const CheckBoxCardCustom = styled(CheckBoxCard)``;

export const Container = styled(CheckBoxGroup)`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  color: ${colors.blue300};
  & ${CheckBoxCardCustom} + ${CheckBoxCardCustom} {
    margin-top: 0.125rem;
  }
`;
export const CheckBoxCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  max-height: 60vh;
  overflow-y: auto;

  color: ${colors.blue300};
`;

export const Fieldset = styled.fieldset`
  all: unset;
  align-items: center;
  background-color: ${colors.white300};
  border-radius: ${radii.px};
  border: 1px solid ${colors.inputBorderLight};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
  margin-bottom: 0.75rem;

  &:focus-within {
    border: 1px solid ${colors.fieldFocus};
  }
`;

export const Input = styled.input`
  background-color: ${colors.white300};
  border-radius: ${radii.sm};
  border: none;
  color: ${colors.text};
  flex-grow: 1;
  font-size: 1rem;
  height: 2rem;
  line-height: 1.1;
  outline: none;
  padding: 0 1rem;

  &::placeholder {
    color: ${colors.inputPlaceholder};
  }
`;

export const AnalyticsTitle = styled.p`
  font-size: 1rem;
  line-height: 1.1;
  flex-grow: 1;
  color: ${colors.text};
`;

export const ActionButton = styled(Link)`
  all: unset;
  align-items: center;
  border-radius: ${radii.full};
  color: ${colors.blue400};
  cursor: pointer;
  display: inline-flex;
  font-family: inherit;
  height: 2.25rem;
  justify-content: center;
  margin: 0.25rem;
  width: 2.25rem;

  &:disabled {
    opacity: 0.2;
  }

  &:hover {
    background-color: ${colors.backgroundHover};
  }
`;

export const PreviewIcon = styled(MdOutlineVisibility)`
  height: 1.5rem;
  width: 1.5rem;
`;

export const AnalyticsTotalViews = styled.p`
  font-size: 1rem;
  line-height: 1.1;
  color: ${colors.text};
  font-weight: bold;
`;

export const AnalyticsEntryContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding: 0.25rem 0.5rem;
`;

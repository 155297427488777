import { MdCheck } from 'react-icons/md';
import styled from 'styled-components';

import { colors, radii } from '@gbs-monorepo-packages/styles';
import * as CheckboxPrimitive from '@radix-ui/react-checkbox';

export const Checkbox = styled(CheckboxPrimitive.Root)`
  align-items: center;
  background-color: ${colors.white300};
  border-radius: ${radii.px};
  border: 1px solid currentColor;
  display: flex;
  font-size: 1rem;
  height: 1.25rem;
  justify-content: center;
  width: 1.25rem;

  &[data-state='checked'] {
    background-color: currentColor;
  }

  &[data-disabled] {
    background-color: ${colors.backgroundHover};
  }

  &:hover {
    filter: brightness(0.9);
  }
`;

export const Indicator = styled(CheckboxPrimitive.Indicator)`
  align-items: center;
  color: ${colors.white300};
  display: flex;
  justify-content: center;
`;

export const CheckIcon = styled(MdCheck)`
  height: 100%;
  width: 100%;
`;

import styled, { css, keyframes } from 'styled-components';

import { colors, radii } from '@gbs-monorepo-packages/styles';

import { CommandsInfoModal } from '../../pages/EditCourse/components/CommandsInfoModal';

const grapesStylePrefix = 'gjs-' as const;
const assetManagerStylePrefix = `${grapesStylePrefix}am-` as const;
const layerStylePrefix = `${grapesStylePrefix}layer-` as const;

const editorShow = keyframes`
  0% { opacity: 0 }
  100% { opacity: 1 }
`;

const loadingHide = keyframes`
  0% { opacity: 1 }
  100% { opacity: 0 }
`;

export const Container = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
`;

const assetManager = css`
  .${assetManagerStylePrefix}file-uploader {
    width: 40%;
  }

  .${assetManagerStylePrefix}add-asset {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .${assetManagerStylePrefix}add-field {
      align-items: center;
      background-color: ${colors.white300};
      border-radius: ${radii.px};
      border: 1px solid ${colors.inputBorderLight};
      display: flex;
      flex-grow: 1;
      flex-direction: row;
      justify-content: space-between;
      position: relative;
      margin-right: 1rem;
      width: unset;
      float: unset;

      &:focus-within {
        border: 1px solid ${colors.fieldFocus};
      }

      input {
        background-color: ${colors.white300};
        border-radius: ${radii.sm};
        border: none;
        color: ${colors.text};
        flex-grow: 1;
        font-size: 1rem;
        height: 2rem;
        line-height: 1.1;
        outline: none;
        padding: 0 1rem;

        &::placeholder {
          color: ${colors.inputPlaceholder};
        }
      }
    }

    button {
      all: unset;
      align-items: center;
      border-radius: ${radii.md};
      cursor: pointer;
      /* flex-grow: 1; */
      display: inline-flex;
      font-family: inherit;
      font-size: 0.875rem;
      font-weight: 700;
      justify-content: center;
      line-height: 1.5;
      padding: 0.5rem 1rem;
      text-transform: uppercase;
      width: unset;
      float: unset;

      &:hover {
        filter: brightness(0.9);
      }

      &[disabled] {
        cursor: not-allowed;
        opacity: unset;
        background-color: ${colors.blue50};
      }

      background-color: ${colors.button};
      color: ${colors.white300};
    }

    div:last-child {
      display: none;
    }
  }

  .${assetManagerStylePrefix}assets-cont {
    width: 60%;
  }
`;

const layerManager = css`
  .${layerStylePrefix}caret {
    width: 20px;
    height: 20px;
  }

  .${layerStylePrefix}move,
    .${layerStylePrefix}vis,
    .${layerStylePrefix}caret,
    .${layerStylePrefix}name {
    box-sizing: unset;
  }

  .${grapesStylePrefix}layer {
    &.${grapesStylePrefix}selected
      > .${layerStylePrefix}title-c
      .${layerStylePrefix}title {
      border-bottom: 1px solid currentColor;
    }
  }
`;

interface IEditorProps {
  isLoading: boolean;
}

export const EditorDiv = styled.div<IEditorProps>`
  @media (prefers-reduced-motion: no-preference) {
    opacity: 1;
    animation-name: ${editorShow};
    animation-iteration-count: 1;
    animation-timing-function: ease-in;
    animation-duration: 600ms;
  }

  ${(props) =>
    props.isLoading &&
    css`
      display: none;
    `}

  .gjs-one-bg {
    /* background-color: #463a3c; */
    background-color: ${colors.background};
  }
  .gjs-one-color {
    /* color: #463a3c; */
    color: ${colors.background};
  }
  .gjs-one-color-h:hover {
    /* color: #463a3c; */
    color: ${colors.background};
  }
  .gjs-two-bg {
    /* background-color: #b9a5a6; */
    background-color: ${colors.blue400};
  }
  .gjs-two-color {
    /* color: #b9a5a6; */
    color: ${colors.blue400};
  }
  .gjs-two-color-h:hover {
    /* color: #b9a5a6; */
    color: ${colors.blue400};
  }
  .gjs-three-bg {
    /* background-color: #804f7b; */
    background-color: ${colors.blue100};
  }
  .gjs-three-color {
    /* color: #804f7b; */
    color: ${colors.blue100};
  }
  .gjs-three-color-h:hover {
    /* color: #804f7b; */
    color: ${colors.blue100};
  }
  .gjs-four-bg {
    /* background-color: #d97aa6; */
    background-color: ${colors.blue200};
  }
  .gjs-four-color {
    /* color: #d97aa6; */
    color: ${colors.blue200};
  }
  .gjs-four-color-h:hover {
    /* color: #d97aa6; */
    color: ${colors.blue200};
  }

  ${assetManager}
  ${layerManager}
`;

interface ILoadingProps {
  isLoading: boolean;
}

export const Loading = styled.img<ILoadingProps>`
  height: 3rem;
  left: calc(50% - 3rem);
  position: absolute;
  top: calc(50% - 3rem);
  width: 3rem;
  opacity: 0;

  ${(props) =>
    props.isLoading
      ? css`
          opacity: 1;
        `
      : css`
          @media (prefers-reduced-motion: no-preference) {
            animation-name: ${loadingHide};
            animation-iteration-count: 1;
            animation-timing-function: ease-out;
            animation-duration: 600ms;
          }
        `}
`;

export const Overlay = styled.div`
  height: 100%;
  position: fixed;
  width: 100%;
  z-index: 11;
`;

export const CustomCommandsInfoModal = styled(CommandsInfoModal).attrs({
  zIndex: 10,
})``;

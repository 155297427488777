import { MdClose } from 'react-icons/md';
import styled, { css, keyframes } from 'styled-components';

import { colors, radii, shadows } from '@gbs-monorepo-packages/styles';
import * as DialogPrimitive from '@radix-ui/react-dialog';

const overlayShow = keyframes`
  0% { opacity: 0 }
  100% { opacity: 1 }
`;

const contentShow = keyframes`
  0% { opacity: 0; transform: 'translate(-50%, -48%) scale(.96)' };
  100% { opacity: 1; transform: 'translate(-50%, -50%) scale(1)' };
`;

interface IDialogOverlayProps {
  $zIndex?: number;
}

// prettier-ignore
export const DialogOverlay = styled(DialogPrimitive.Overlay)<IDialogOverlayProps>`
  background-color: ${colors.blackTranslucent400};

  inset: 0;
  position: fixed;
  ${({ $zIndex }) =>
    $zIndex !== undefined &&
    css`
      z-index: ${$zIndex};
    `}

  @media (prefers-reduced-motion: no-preference) {
    animation: ${overlayShow} 150ms cubic-bezier(0.55, 0.29, 0.34, 1.02);
  }
`;

export const DialogOverlayLogin = styled(DialogOverlay)`
  background-color: ${colors.authPagesBackground};
`;

interface IDialogContentProps {
  $biggerModal?: boolean;
  $height?: string;
  $zIndex?: number;
  $setManualWidth?: number;
  $fullWidth?: boolean;
}

// prettier-ignore
export const DialogContent = styled(DialogPrimitive.Content)<IDialogContentProps>`
  background-color: ${colors.white300};
  border-radius: ${radii.sm};
  box-shadow: ${shadows.modal};
  left: 50%;
  max-width: ${({ $biggerModal = false, $fullWidth = false }) =>
    $fullWidth ? '90vw' : $biggerModal ? '600px' : '450px'};
  padding: 25px;
  position: fixed;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 90vw;
  display: flex;
  flex-direction: column;
  ${({ $zIndex }) =>
    $zIndex !== undefined &&
    css`
      z-index: ${$zIndex};
    `}
  ${({ $height }) =>
    $height !== undefined &&
    css`
      min-height: ${$height};
    `}

  ${({$setManualWidth }) =>
    $setManualWidth !== undefined &&
    css`
      max-width: ${$setManualWidth}px;
  `}


  @media (prefers-reduced-motion: no-preference) {
    animation: ${contentShow} 150ms cubic-bezier(0.55, 0.29, 0.34, 1.02);
  }

  &:focus {
    outline: none;
  }
`;

export const DialogContentLogin = styled(DialogContent)`
  border-radius: ${radii.lg};
`;

export const CloseButton = styled.button`
  all: unset;
  align-items: center;
  border-radius: ${radii.full};
  color: ${colors.black300};
  display: inline-flex;
  font-family: inherit;
  height: 25px;
  justify-content: center;
  position: absolute;
  right: 10px;
  top: 10px;
  width: 25px;

  &:hover {
    background-color: ${colors.backgroundHover};
  }
  &:focus {
    box-shadow: ${shadows.modal};
  }
`;

export const CloseIcon = styled(MdClose)``;

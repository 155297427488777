import { type MouseEventHandler } from 'react';

import * as DialogPrimitive from '@radix-ui/react-dialog';

import { BaseModal, type IBaseModalProps } from '../../components/BaseModal';
import { Button } from '../Button';
import { ButtonsContainer, TextContent, Title } from './styles';

export interface IDialogModalProps extends Partial<IBaseModalProps> {
  acceptText?: string;
  dataCy?: string;
  declineText?: string;
  disabled?: boolean;
  loading?: boolean;
  mainText: string;
  onAccept?: MouseEventHandler<HTMLButtonElement>;
  onDecline?: MouseEventHandler<HTMLButtonElement>;
}

export const DialogModal = ({
  acceptText,
  children,
  dataCy = 'dialog-modal',
  declineText,
  disabled = false,
  loading = false,
  mainText,
  onAccept,
  onDecline,
  ...props
}: IDialogModalProps): JSX.Element => {
  return (
    <BaseModal dataCy={dataCy} {...props}>
      <TextContent data-cy="text-container">
        <DialogPrimitive.Title asChild>
          <Title data-cy={mainText}>{mainText}</Title>
        </DialogPrimitive.Title>
      </TextContent>

      {children}

      <ButtonsContainer>
        {declineText && (
          <Button
            type="button"
            dataCy="button-cancel"
            styleType="outline"
            onClick={onDecline}
          >
            {declineText}
          </Button>
        )}
        {acceptText && (
          <Button
            type="button"
            dataCy="button-add"
            onClick={onAccept}
            loading={loading}
            disabled={disabled}
          >
            {acceptText}
          </Button>
        )}
      </ButtonsContainer>
    </BaseModal>
  );
};

import { type ReactNode, forwardRef } from 'react';

import { type ICheckBoxCardProps } from '../../../../components/CheckBoxGroup';
import { GOOGLE_FONTS_API_URL } from '../../../../constants/Env';
import { type IFont } from '../../../../pages/EditCourse/components/FontsModal';
import { type IFontDTO } from '../../../../services/courses';
import { CheckBoxCardCustom, FontTitle } from './styles';

export interface IData extends IFontDTO {
  sourceIndex?: number;
}

export interface IFontCardProps
  extends Omit<ICheckBoxCardProps, 'children' | 'value'> {
  data: IFont;
  dataCy?: string;
  value?: string;
  children?: ReactNode;
}

export const FontCard = forwardRef<HTMLButtonElement, IFontCardProps>(
  function FontCard(
    {
      children,
      data: { family, category },
      dataCy = family,
      value = `${family}`,
      ...props
    },
    ref
  ): JSX.Element {
    return (
      <CheckBoxCardCustom
        {...props}
        ref={ref}
        dataCy={dataCy}
        isNotButton
        value={value}
        defaultValue={[`${family}`]}
      >
        <link
          rel="stylesheet"
          href={`${GOOGLE_FONTS_API_URL}?family=${family}&text=${family}`}
        ></link>
        <FontTitle
          style={{ fontFamily: `${family}, ${category}` }}
          data-cy="item-text"
        >
          {family}
        </FontTitle>
        {children}
      </CheckBoxCardCustom>
    );
  }
);

import {
  sortByDateAttribute,
  sortByNumberAttribute,
  sortByStringAttribute,
} from '@gbs-monorepo-packages/common';

export const ByName = <T extends { name: string }>(a: T, b: T): number => {
  return sortByStringAttribute(a, b, 'name');
};

export const ByTitle = <T extends { title: string }>(a: T, b: T): number => {
  return sortByStringAttribute(a, b, 'title');
};

export const ByFirstName = <T extends { firstName: string }>(
  a: T,
  b: T
): number => {
  return a.firstName.toUpperCase().localeCompare(b.firstName.toUpperCase());
};

export const ByPageNumber = <T extends { pageNumber: number }>(
  a: T,
  b: T
): number => {
  return sortByNumberAttribute(a, b, 'pageNumber');
};

export const ByUpdatedAt = <T extends { updatedAt: string }>(
  a: T,
  b: T
): number => {
  return sortByDateAttribute(a, b, 'updatedAt');
};

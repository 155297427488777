import styled from 'styled-components';

import { colors, radii } from '@gbs-monorepo-packages/styles';
import * as RadioGroupPrimitive from '@radix-ui/react-radio-group';

export const RadioGroupRoot = styled(RadioGroupPrimitive.Root)`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const RadioGroupItem = styled(RadioGroupPrimitive.Item)`
  all: unset;
  background-color: ${colors.white300};
  border-radius: ${radii.full};
  border: 1px solid currentColor;
  box-shadow: 0 2px 10px rgb(0 0 0 / 14%);

  height: 1.5rem;
  width: 1.5rem;

  &:hover {
    filter: brightness(0.9);
  }
`;

export const RadioGroupIndicator = styled(RadioGroupPrimitive.Indicator)`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  position: relative;
  width: 100%;

  &::after {
    background-color: currentColor;
    border-radius: ${radii.full};
    content: '';
    display: block;
    height: 50%;
    width: 50%;
  }
`;

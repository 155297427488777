import type * as CSS from 'csstype';
import type { Editor } from 'grapesjs';

import {
  wrapperClass,
  wrapperStyle,
} from '../../../components/GrapesJS/Customs/ToggleWrapper';
import { mdScreens, smScreens } from '../../../constants/grapes';
import { type IPropertySelectProps } from '../interfaces';

export const cmdSetDefaultRules = 'set-default-styles';

export const addVerticalAlign = (editor: Editor): void => {
  const verticalAlign: IPropertySelectProps = {
    id: 'vertical-align',
    default: '',
    label: 'Vertical align',
    options: [
      { id: 'unset', label: ' ' },
      { id: 'baseline', label: 'Baseline' },
      { id: 'bottom', label: 'Bottom' },
      { id: 'middle', label: 'Middle' },
      { id: 'sub', label: 'Sub' },
      { id: 'super', label: 'Super' },
      { id: 'text-bottom', label: 'Text bottom' },
      { id: 'text-top', label: 'Text top' },
      { id: 'top', label: 'Top' },
    ],
    property: 'vertical-align',
    type: 'select',
  };

  editor.StyleManager.addProperty('typography', verticalAlign, { at: 6 });
};

export const addMaxMinSizes = (editor: Editor): void => {
  // remove the default properties to set a better order
  editor.StyleManager.removeProperty('dimension', 'max-width');
  editor.StyleManager.removeProperty('dimension', 'min-height');

  // ordering to properties related to width and height stay together
  editor.StyleManager.addProperty(
    'dimension',
    {
      property: 'min-width',
      type: 'number',
      min: 0,
      units: ['px', '%', 'em', 'rem', 'vh', 'vw'],
      default: 'auto',
    },
    { at: 2 }
  );
  editor.StyleManager.addProperty(
    'dimension',
    {
      property: 'min-height',
      type: 'number',
      min: 0,
      units: ['px', '%', 'em', 'rem', 'vh', 'vw'],
      default: 'auto',
    },
    { at: 3 }
  );
  editor.StyleManager.addProperty(
    'dimension',
    {
      property: 'max-width',
      type: 'number',
      min: 0,
      units: ['px', '%', 'em', 'rem', 'vh', 'vw'],
      default: 'auto',
    },
    { at: 4 }
  );
  editor.StyleManager.addProperty(
    'dimension',
    {
      property: 'max-height',
      type: 'number',
      min: 0,
      units: ['px', '%', 'em', 'rem', 'vh', 'vw'],
      default: 'auto',
    },
    { at: 5 }
  );
};

export const addOverflow = (editor: Editor): void => {
  editor.StyleManager.addProperty(
    'dimension',
    {
      property: 'overflow-x',
      type: 'select',
      default: 'visible',
      options: [
        { id: 'visible', label: 'visible' },
        { id: 'hidden', label: 'hidden' },
        { id: 'scroll', label: 'scroll' },
        { id: 'auto', label: 'auto' },
      ],
    },
    { at: 20 }
  );
  editor.StyleManager.addProperty(
    'dimension',
    {
      property: 'overflow-y',
      type: 'select',
      default: 'visible',
      options: [
        { id: 'visible', label: 'visible' },
        { id: 'hidden', label: 'hidden' },
        { id: 'scroll', label: 'scroll' },
        { id: 'auto', label: 'auto' },
      ],
    },
    { at: 21 }
  );
};

export const handlerSetDefaultRules = (
  { Commands, Css }: Editor,
  addCssContent?: string
): void => {
  const isRunning = Commands.isActive(cmdSetDefaultRules);

  if (!isRunning) {
    Commands.run(cmdSetDefaultRules);
    addCssContent && Css.addRules(addCssContent);
  }
};

export const CustomCommandsPlugin = ({ Commands }: Editor): void => {
  Commands.add(cmdSetDefaultRules, {
    // eslint-disable-next-line no-restricted-syntax
    run({ Css }) {
      Css.setRule(`.${wrapperClass}`, wrapperStyle);

      Css.setRule('.gjs-cell', {
        width: '8%',
        display: 'table-cell',
        height: '75px',
        'vertical-align': 'top',
      } as CSS.PropertiesHyphen);

      Css.setRule(
        '.gjs-cell',
        {
          width: '8%',
          display: 'table-cell',
        } as CSS.StandardPropertiesHyphen,
        {
          atRuleParams: `(max-width: ${mdScreens.mediaWidth})`,
          atRuleType: 'media',
        }
      );

      Css.setRule('img', {
        'max-width': '100%!important',
      } as CSS.StandardPropertiesHyphen);

      Css.setRule('div[data-gjs-type="video"]', {
        'max-width': '100%!important',
        width: 'unset!important',
      } as CSS.StandardPropertiesHyphen);

      Css.setRule('.mailto-link', {
        'background-color': '#00426b',
        border: '1px solid #00426b',
        color: '#ffffff',
        'border-radius': '15px',
        display: 'inline-block',
        padding: '10px 25px',
        'text-decoration': 'none',
      } as CSS.StandardPropertiesHyphen);

      Css.setRule('.link-button', {
        'background-color': '#00426b',
        border: '1px solid #00426b',
        color: '#ffffff',
        'border-radius': '15px',
        display: 'inline-block',
        padding: '10px 25px',
        'text-decoration': 'none',
      } as CSS.StandardPropertiesHyphen);

      Css.setRule('.link-document', {
        'background-color': '#00426b',
        border: '1px solid #00426b',
        color: '#ffffff',
        'border-radius': '15px',
        display: 'inline-block',
        padding: '10px 25px',
        'text-decoration': 'none',
      } as CSS.StandardPropertiesHyphen);

      Css.setRule('.mailto-link:hover', {
        'background-color': '#07629b',
        opacity: '0.9',
      } as CSS.StandardPropertiesHyphen);

      Css.setRule('.link-button:hover', {
        'background-color': '#07629b',
        opacity: '0.9',
      } as CSS.StandardPropertiesHyphen);

      Css.setRule('.link-document:hover', {
        'background-color': '#07629b',
        opacity: '0.9',
      } as CSS.StandardPropertiesHyphen);

      Css.setRule('.two-columns-text', {
        'column-count': '2',
        orphans: '2',
      } as CSS.StandardPropertiesHyphen);

      Css.setRule(
        '.sm-two-columns-text',
        {
          'column-count': '2',
          orphans: '2',
        } as CSS.StandardPropertiesHyphen,
        {
          atRuleParams: `(max-width: ${mdScreens.mediaWidth})`,
          atRuleType: 'media',
        }
      );

      Css.setRule(
        '.lg-two-columns-text',
        {
          'column-count': '2',
          orphans: '2',
        } as CSS.StandardPropertiesHyphen,
        {
          atRuleParams: `(min-width: ${mdScreens.mediaWidth})`,
          atRuleType: 'media',
        }
      );

      Css.setRule('.allow-scroll', {
        'overflow-y': 'auto',
      } as CSS.StandardPropertiesHyphen);

      Css.setRule(
        '.sm-allow-scroll',
        {
          'overflow-y': 'auto',
        } as CSS.StandardPropertiesHyphen,
        {
          atRuleParams: `(max-height: ${smScreens.mediaHeight}) or (max-width: ${mdScreens.mediaWidth})`,
          atRuleType: 'media',
        }
      );

      Css.setRule(
        '.lg-allow-scroll',
        {
          'overflow-y': 'auto',
        } as CSS.StandardPropertiesHyphen,
        {
          atRuleParams: `(min-height: ${smScreens.mediaHeight} or (min-width: ${mdScreens.mediaWidth}))`,
          atRuleType: 'media',
        }
      );

      Css.setRule('.grid-3-7-columns', {
        display: 'grid',
        'grid-template-columns': '35fr 65fr',
      } as CSS.StandardPropertiesHyphen);

      Css.setRule('.grid-7-3-columns', {
        display: 'grid',
        'grid-template-columns': '65fr 35fr',
      } as CSS.StandardPropertiesHyphen);
    },
  });
};

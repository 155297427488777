import { produce } from 'immer';
import { useCallback, useEffect, useRef, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import { SearchBar } from '@gbs-monorepo-packages/common';

import { BaseDropdown } from '../../components/BaseDropdown';
import { BaseTooltip } from '../../components/BaseTooltip';
import { Button } from '../../components/Button';
import { ButtonAddCompany } from '../../components/ButtonAddCompany';
import { CenteredText } from '../../components/CenteredText';
import { ContentPagination } from '../../components/ContentPagination';
import { DialogModal } from '../../components/DialogModal';
import { LazyImage } from '../../components/LazyImage';
import { useCompanies } from '../../hooks/useCompanies';
import { useToast } from '../../hooks/useToast';
import {
  type IApiThrowsError,
  type IPaginationMetaProps,
} from '../../services/api';
import {
  type IAddCompanyDTO,
  type ICompanyDTO,
  type IPaginationCompaniesDTO,
  getCompanies,
} from '../../services/companies';
import Logger from '../../utils/logger';
import {
  CompanyCard,
  CompanyCardGrid,
  CompanyName,
  Container,
  ContentCard,
  DropdownButtonContainer,
  DropdownItem,
  DropdownItemAsLink,
  GridContent,
  GridIcon,
  GridItem,
  GridListOptions,
  Header,
  HeaderContent,
  ImageContainer,
  LabelContent,
  ListIcon,
  Loading,
  LoadingContainer,
  MainContainer,
  Option,
  SearchContainer,
  TitlePage,
} from './styles';

export const Dashboard = (): JSX.Element => {
  const [modalAddCompanyOpen, setModalAddCompanyOpen] = useState(false);
  const [loadingCompanies, setLoadingCompanies] = useState(false);
  const [loadingAddCompanies, setLoadingAddCompanies] = useState(false);
  const [companiesAux, setCompaniesAux] = useState<ICompanyDTO[]>([]);
  const [search, setSearch] = useState('');
  const lastSearch = useRef(search);
  const [paginationMeta, setPaginationMeta] =
    useState<IPaginationMetaProps | null>(null);

  const { addToast } = useToast();
  const [companyToDelete, setCompanyToDelete] = useState<ICompanyDTO | null>(
    null
  );
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const isModalDeleteOpen = !!companyToDelete;

  const {
    addCompany,
    deleteCompany,
    selectedListOptionStyle,
    selectListOptionStyle,
  } = useCompanies();

  const getCompaniesAux = useCallback(
    (page: number, limit: number) => {
      setLoadingCompanies(true);
      getCompanies({ page, limit, filter: search })
        .then((response: IPaginationCompaniesDTO) => {
          setCompaniesAux(response.data);
          setPaginationMeta(response.meta);

          if (isModalDeleteOpen) {
            setLoadingDelete(false);
            setCompanyToDelete(null);

            addToast({
              title: 'Client deleted',
              description: `Client ${companyToDelete.name} was successfully deleted`,
              styleType: 'success',
              dataCy: 'delete-client-success-toast',
            });
          }

          if (modalAddCompanyOpen) {
            setModalAddCompanyOpen(false);
            setLoadingAddCompanies(false);

            addToast({
              title: 'Success',
              description: `Client was successfully created`,
              styleType: 'success',
              dataCy: 'success-toast',
            });
          }
        })
        .catch((error: IApiThrowsError) => {
          Logger.debug('error: ', error);
        })
        .finally(() => {
          setLoadingCompanies(false);
        });
    },
    [search, addToast, companyToDelete, isModalDeleteOpen, modalAddCompanyOpen]
  );

  const searchCompanies = (searchByButton = false) => {
    if (!loadingCompanies || searchByButton) {
      setLoadingCompanies(true);
      const pageAux = searchByButton
        ? 0
        : Number((paginationMeta?.page ?? 0) > 0 ? paginationMeta?.page : 0);
      getCompanies({
        page: pageAux + 1,
        limit: 12,
        filter: search,
      })
        .then((response: IPaginationCompaniesDTO) => {
          setCompaniesAux(
            produce((draft) => {
              draft.push(...response.data);
            })
          );
          setPaginationMeta(response.meta);
        })
        .catch((error: IApiThrowsError) => {
          Logger.debug('error: ', error);
        })
        .finally(() => {
          setLoadingCompanies(false);
        });
    }
  };

  useEffect(() => {
    let timer: NodeJS.Timeout | null = null;

    if (search.trim() !== lastSearch.current) {
      const execSearch = () => {
        lastSearch.current = search.trim();
        setCompaniesAux([]);
        searchCompanies(true);
      };

      if (search.trim() === '') {
        execSearch();
      } else {
        timer = setTimeout(execSearch, 2000);
      }
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [search]);

  const handleDeclineDelete = useCallback(() => {
    setCompanyToDelete(null);
  }, []);

  const handleOpenModalDelete = (
    e: React.MouseEvent<HTMLDivElement>,
    company: ICompanyDTO
  ) => {
    e.stopPropagation();
    setCompanyToDelete(company);
  };

  const handleAcceptDelete = useCallback(() => {
    if (companyToDelete && isModalDeleteOpen) {
      setLoadingDelete(true);
      void deleteCompany(companyToDelete.id)
        .then(() => {
          getCompaniesAux(1, 12);
        })
        .catch(() => {
          setLoadingDelete(false);
          setCompanyToDelete(null);
          addToast({
            title: 'Error on deleting client',
            description:
              'An error occurred. Please try again or contact Edge support.',
            styleType: 'error',
            dataCy: 'delete-client-error-toast',
          });
        });
    }
  }, [
    companyToDelete,
    isModalDeleteOpen,
    deleteCompany,
    getCompaniesAux,
    addToast,
  ]);

  useEffect(() => {
    if (!companiesAux.length) {
      setLoadingCompanies(true);
      getCompaniesAux(1, 12);
    }
  }, []);

  const handleAddCompany = useCallback(
    (data: IAddCompanyDTO) => {
      setLoadingAddCompanies(true);
      void addCompany(data)
        .then(() => {
          getCompaniesAux(1, 12);
        })
        .catch(() => {
          setLoadingAddCompanies(false);
          addToast({
            title: 'Error on creating client',
            description:
              'An error occurred. Please try again or contact Edge support.',
            styleType: 'error',
            dataCy: 'add-client-error-toast',
          });
        });
    },
    [addToast, getCompaniesAux]
  );

  return (
    <Container data-cy="page-container">
      <Header>
        <TitlePage data-cy="title-pageName">Clients</TitlePage>
        <Button
          dataCy="button-addClient"
          onClick={() => {
            setModalAddCompanyOpen(true);
          }}
          disabled={loadingCompanies}
          type="button"
        >
          Add Client
        </Button>
      </Header>

      <GridListOptions>
        <Option
          data-cy="clientList"
          color={selectedListOptionStyle === 'list' ? '#00426b' : '#414042'}
          onClick={() => {
            selectListOptionStyle({ optionStyle: 'list' });
          }}
        >
          <ListIcon />
        </Option>
        <Option
          data-cy="clientGrid"
          color={selectedListOptionStyle === 'grid' ? '#00426b' : '#414042'}
          onClick={() => {
            selectListOptionStyle({ optionStyle: 'grid' });
          }}
        >
          <GridIcon />
        </Option>
      </GridListOptions>
      <MainContainer data-cy="list">
        <SearchContainer>
          <SearchBar
            search={search}
            placeholder="Search client"
            onChange={(e) => {
              if (e.target.value.length <= 50) {
                setSearch(e.target.value);
              }
            }}
            onClearInput={() => {
              setSearch('');
            }}
            loading={loadingCompanies}
          />
        </SearchContainer>

        <ContentPagination dataCy="content-dashboard">
          {selectedListOptionStyle === 'list' ? (
            <HeaderContent data-cy="header-list">
              <LabelContent data-cy="label-clientName">
                Client Name
              </LabelContent>
            </HeaderContent>
          ) : (
            ''
          )}
          {loadingCompanies && companiesAux.length === 0 ? (
            <LoadingContainer data-cy="loading-clients-container">
              <Loading dataCy="loading-clients" />
            </LoadingContainer>
          ) : selectedListOptionStyle === 'list' && companiesAux.length > 0 ? (
            <InfiniteScroll
              data-cy="dashboard-infiniteScroll"
              height={200}
              style={{
                overflow: 'auto',
                maxHeight: '100%',
                display: 'flex',
                flexDirection: 'column',
                flexGrow: 1,
              }}
              dataLength={companiesAux.length} // This is important field to render the next data
              next={searchCompanies}
              hasMore={companiesAux.length < (paginationMeta?.total ?? 0)}
              loader={
                <LoadingContainer data-cy="loading-members-container">
                  <Loading />
                </LoadingContainer>
              }
              endMessage={
                <CenteredText
                  message="You have seen it all"
                  dataCy="pagination-endLimit"
                />
              }
            >
              {companiesAux.map((company) => (
                <CompanyCard
                  key={company.id}
                  to={`/companies/${company.id}/courses`}
                  data-cy={`${company.name}`}
                >
                  <CompanyName data-cy="label-card">{company.name}</CompanyName>
                  <div style={{ display: 'flex' }}>
                    <DropdownButtonContainer data-cy="dropdown-container">
                      <BaseDropdown
                        dataCy={`dropdown-menu-button-${company.name}`}
                        onOpenChange={(isOpen) => {
                          setIsDropdownOpen(isOpen);
                        }}
                      >
                        <DropdownItem
                          data-cy={`courses-${company.name}`}
                          asChild
                        >
                          <DropdownItemAsLink
                            to={`/companies/${company.id}/courses`}
                          >
                            Courses
                          </DropdownItemAsLink>
                        </DropdownItem>
                        <DropdownItem
                          data-cy={`analytics-${company.name}`}
                          asChild
                        >
                          <DropdownItemAsLink
                            to={`/companies/${company.id}/analytics`}
                          >
                            Analytics
                          </DropdownItemAsLink>
                        </DropdownItem>
                        <DropdownItem
                          data-cy={`documents-${company.name}`}
                          asChild
                        >
                          <DropdownItemAsLink
                            to={`/companies/${company.id}/documents`}
                          >
                            Documents
                          </DropdownItemAsLink>
                        </DropdownItem>
                        <DropdownItem
                          data-cy={`teammates-${company.name}`}
                          asChild
                        >
                          <DropdownItemAsLink
                            to={`/companies/${company.id}/members`}
                          >
                            Teammates
                          </DropdownItemAsLink>
                        </DropdownItem>
                        <DropdownItem
                          data-cy={`settings-${company.name}`}
                          asChild
                        >
                          <DropdownItemAsLink
                            to={`/companies/${company.id}/settings`}
                          >
                            Settings
                          </DropdownItemAsLink>
                        </DropdownItem>
                        <DropdownItem
                          data-cy={`delete-${company.name}`}
                          onClick={(e) => {
                            handleOpenModalDelete(e, company);
                          }}
                        >
                          Delete
                        </DropdownItem>
                      </BaseDropdown>
                    </DropdownButtonContainer>
                  </div>
                </CompanyCard>
              ))}
            </InfiniteScroll>
          ) : selectedListOptionStyle === 'grid' && companiesAux.length > 0 ? (
            <InfiniteScroll
              height={200}
              style={{
                overflow: 'auto',
                maxHeight: '100%',
                display: 'flex',
                flexDirection: 'column',
                flexGrow: 1,
              }}
              dataLength={companiesAux.length}
              next={searchCompanies}
              hasMore={companiesAux.length < (paginationMeta?.total ?? 0)}
              loader={
                <LoadingContainer data-cy="loading-teammates-container">
                  <Loading />
                </LoadingContainer>
              }
              endMessage={
                <CenteredText
                  message="You have seen it all"
                  dataCy="pagination-endLimit"
                />
              }
            >
              <GridContent>
                {companiesAux.map((company) => (
                  <GridItem key={company.id}>
                    <CompanyCardGrid
                      to={`/companies/${company.id}/courses`}
                      data-cy={`${company.name}`}
                    >
                      <ImageContainer data-cy={'container-imageClient'}>
                        <LazyImage
                          src={
                            company.clientFile === null
                              ? ''
                              : company.clientFile?.path
                          }
                          alt="company-image"
                          dataCy="image-client"
                        />
                      </ImageContainer>
                      <ContentCard>
                        <BaseTooltip message={company.name}>
                          <CompanyName data-cy="label-card">
                            {company.name}
                          </CompanyName>
                        </BaseTooltip>
                        <DropdownButtonContainer data-cy="dropdown-container">
                          <BaseDropdown
                            dataCy={`dropdown-options-button-${company.name}`}
                            onOpenChange={(isOpen) => {
                              setIsDropdownOpen(isOpen);
                            }}
                          >
                            <DropdownItem
                              data-cy={`courses-${company.name}`}
                              asChild
                            >
                              <DropdownItemAsLink
                                to={`/companies/${company.id}/courses`}
                              >
                                Courses
                              </DropdownItemAsLink>
                            </DropdownItem>
                            <DropdownItem
                              data-cy={`analytics-${company.name}`}
                              asChild
                            >
                              <DropdownItemAsLink
                                to={`/companies/${company.id}/analytics`}
                              >
                                Analytics
                              </DropdownItemAsLink>
                            </DropdownItem>
                            <DropdownItem
                              data-cy={`documents-${company.name}`}
                              asChild
                            >
                              <DropdownItemAsLink
                                to={`/companies/${company.id}/documents`}
                              >
                                Documents
                              </DropdownItemAsLink>
                            </DropdownItem>
                            <DropdownItem
                              data-cy={`teammates-${company.name}`}
                              asChild
                            >
                              <DropdownItemAsLink
                                to={`/companies/${company.id}/members`}
                              >
                                Teammates
                              </DropdownItemAsLink>
                            </DropdownItem>
                            <DropdownItem
                              data-cy={`settings-${company.name}`}
                              asChild
                            >
                              <DropdownItemAsLink
                                to={`/companies/${company.id}/settings`}
                              >
                                Settings
                              </DropdownItemAsLink>
                            </DropdownItem>
                            <DropdownItem
                              data-cy={`delete-${company.name}`}
                              onClick={(e) => {
                                handleOpenModalDelete(e, company);
                              }}
                            >
                              Delete
                            </DropdownItem>
                          </BaseDropdown>
                        </DropdownButtonContainer>
                      </ContentCard>
                    </CompanyCardGrid>
                  </GridItem>
                ))}
              </GridContent>
            </InfiniteScroll>
          ) : (
            <CenteredText
              message="No clients found"
              dataCy="text-noClients-found"
            />
          )}
        </ContentPagination>

        <DialogModal
          dataCy="dialog-DeleteClient"
          acceptText="Confirm"
          declineText="Cancel"
          open={!isDropdownOpen && isModalDeleteOpen}
          loading={loadingDelete}
          mainText={`Are you sure you want to delete ${
            companyToDelete?.name ?? 'this company'
          }?`}
          onAccept={handleAcceptDelete}
          onDecline={handleDeclineDelete}
          onOpenChange={handleDeclineDelete}
        />

        <ButtonAddCompany
          data-cy="button-addClient"
          onAccept={handleAddCompany}
          onDecline={() => {
            setModalAddCompanyOpen(false);
          }}
          open={modalAddCompanyOpen}
          loading={loadingAddCompanies}
        />
      </MainContainer>
    </Container>
  );
};

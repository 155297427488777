export interface IRoutePath {
  prefixPath: string;
  path: string;
}

const rootPath = {
  prefixPath: '/',
  path: '',
} as const;

export const COMPANIES = { ...rootPath, path: 'companies' } as const;
export const COMPANY = { ...rootPath, path: 'companies/:companyId' } as const;
export const COURSE_VIEWER = {
  ...rootPath,
  path: 'view/:viewURL',
} as const;
export const SUBDOMAIN_COURSE_VIEWER = {
  ...rootPath,
  path: ':viewURL',
} as const;
export const DASHBOARD = { ...rootPath, path: 'dashboard' } as const;
export const EDIT_COURSE = {
  ...rootPath,
  path: 'company/:companyId/courses/:courseId/edit',
} as const;
export const CREATE_TEMPLATE = {
  ...rootPath,
  path: 'company/:companyId/template/:templateId/edit',
} as const;
export const FORGOT_PASSWORD = {
  ...rootPath,
  path: 'forgot-password',
} as const;
export const HOME = { ...rootPath, path: 'home' } as const;
export const LOGIN = { ...rootPath, path: 'login' } as const;
export const REDIRECT_LOGIN = { ...rootPath, path: 'redirect-login' } as const;
export const ALL_MEMBERS = { ...rootPath, path: 'members' } as const;
export const PROFILE = { ...rootPath, path: 'profile' } as const;
export const NOT_FOUND = { ...rootPath, path: '404' } as const;
export const REDIRECT = { ...rootPath } as const;
export const RESET_PASSWORD = { ...rootPath, path: 'reset-password' } as const;
export const TEMPLATE_VIEW = {
  ...rootPath,
  path: 'view-template/:templateId',
} as const;
export const WEB_BUILDER = { ...rootPath, path: 'web-builder' } as const;

const rootCompanyPath = {
  prefixPath: `/${COMPANY.path}`,
  path: '',
} as const;

export const COURSES = { ...rootCompanyPath, path: 'courses' } as const;
export const ANALYTICS = { ...rootCompanyPath, path: 'analytics' } as const;
export const COURSES_TEMPLATE = {
  ...rootCompanyPath,
  path: 'templates',
} as const;
export const DOCUMENTS = { ...rootCompanyPath, path: 'documents' } as const;
export const MEMBERS = {
  ...rootCompanyPath,
  path: 'members',
} as const;
export const SETTINGS = { ...rootCompanyPath, path: 'settings' } as const;
export const PERMISSION_REDIRECT = {
  ...rootPath,
  path: 'permission-redirect',
} as const;

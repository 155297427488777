import { type ChangeEventHandler, type MouseEventHandler } from 'react';

import * as DialogPrimitive from '@radix-ui/react-dialog';

import { BaseModal, type IBaseModalProps } from '../../components/BaseModal';
import { Button } from '../Button';
import { UploadField } from '../UploadField';
import {
  ButtonsContainer,
  Description,
  ErrorMessage,
  TextContent,
  TextLimit,
  TextLimitContainer,
  Title,
} from './styles';

interface IUploadModalProps extends Partial<IBaseModalProps> {
  accept?: string;
  acceptText?: string;
  dataCy?: string;
  declineText?: string;
  disabled?: boolean;
  loading?: boolean;
  mainText: string;
  description?: string;
  errorMessage?: string;
  fileSizeLimit?: string;
  fileDimensionsLimit?: string;
  onAccept?: MouseEventHandler<HTMLButtonElement>;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  onDecline?: MouseEventHandler<HTMLButtonElement>;
}

export const UploadModal = ({
  accept,
  acceptText,
  dataCy = 'upload-modal',
  declineText,
  disabled = false,
  loading = false,
  mainText,
  description,
  errorMessage,
  fileSizeLimit,
  fileDimensionsLimit,
  onAccept,
  onChange,
  onDecline,
  ...props
}: IUploadModalProps): JSX.Element => {
  return (
    <BaseModal {...props} dataCy={dataCy}>
      <TextContent data-cy="text-container">
        <DialogPrimitive.Title asChild>
          <Title data-cy={mainText}>{mainText}</Title>
        </DialogPrimitive.Title>
        {description ? (
          <Description data-cy={description}>{description}</Description>
        ) : (
          <></>
        )}
      </TextContent>

      <UploadField dataCy="input-file" onChange={onChange} accept={accept} />

      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}

      <TextLimitContainer>
        {fileSizeLimit && <TextLimit>Limit: {fileSizeLimit}</TextLimit>}
        {fileDimensionsLimit && (
          <TextLimit>Dimensions: {fileDimensionsLimit}</TextLimit>
        )}
      </TextLimitContainer>

      <ButtonsContainer>
        {declineText && (
          <Button
            type="button"
            dataCy="button-cancel"
            styleType="outline"
            onClick={onDecline}
          >
            {declineText}
          </Button>
        )}
        {acceptText && (
          <Button
            type="button"
            dataCy="button-add"
            onClick={onAccept}
            loading={loading}
            disabled={disabled}
          >
            {acceptText}
          </Button>
        )}
      </ButtonsContainer>
    </BaseModal>
  );
};

import { useCallback, useEffect, useState } from 'react';

import { ColorPickerSelectData } from '../../../../components/ColorPickerSelectData';
import {
  FormModal,
  type IFormModalProps,
} from '../../../../components/FormModal';
import { type ICoursePage } from '../../../../contexts/coursePage';
import { useCourse } from '../../../../hooks/useCourse';
import { type ITemplateColors } from '../../../../services/templates';

interface IMatchSvgTemplateColorsModalProps
  extends Partial<
    Omit<IFormModalProps, 'children' | 'onAccept' | 'onOpenChange'>
  > {
  loading: boolean;
  colorsDefault: ITemplateColors;
  onAccept: (pages: ICoursePage[]) => Promise<void>;
  onDecline: () => void;
  open: boolean;
  waitToOpen?: boolean;
  pageId: number;
}

export const MatchSvgTemplateColorsModal = ({
  loading,
  colorsDefault,
  onAccept,
  onDecline,
  open,
  waitToOpen = false,
  pageId,
  ...props
}: IMatchSvgTemplateColorsModalProps): JSX.Element | null => {
  const { selectedCourse } = useCourse();
  const [templateColors, setTemplateColors] =
    useState<ITemplateColors>(colorsDefault);

  useEffect(() => {
    const transformedObject: ITemplateColors = {};
    Object.keys(colorsDefault).forEach((key) => {
      transformedObject[key] = colorsDefault[key];
    });

    setTemplateColors(transformedObject);
  }, [colorsDefault]);

  const handleDecline = useCallback(() => {
    onDecline?.();
  }, [onDecline]);

  const handleAccept = useCallback(async () => {
    const selectedCourseAux = selectedCourse;
    const coursePagesAux = selectedCourseAux?.pages;
    const updatedCoursePagesAux = coursePagesAux?.map((page) => {
      if (page.id === pageId) {
        return {
          ...page,
          templateColorsFound: templateColors,
        };
      } else {
        return page;
      }
    });

    if (updatedCoursePagesAux) {
      await onAccept(updatedCoursePagesAux);
    }
  }, [templateColors, onAccept, pageId, selectedCourse]);

  return !open ? null : (
    <FormModal
      hiddenCloseButton
      acceptText="Save"
      dataCy="edit-template-colors-dialog-modal"
      mainText="Match Template Styles"
      disabled={false}
      {...props}
      onAccept={() => {
        void handleAccept();
      }}
      onDecline={handleDecline}
      loading={loading}
      open={!waitToOpen}
    >
      {Object.entries(templateColors).map(([colorKey, colorValue], index) => (
        <div key={index}>
          <ColorPickerSelectData
            color={String(colorValue)}
            dataCy={`color-picker-${colorKey}`}
            errorMessage=""
            label={`Import color`}
            onChange={(color, colorKey) => {
              const newTemplateColors: ITemplateColors = { ...templateColors };
              if (colorKey) {
                newTemplateColors[colorKey] = color;
                setTemplateColors(newTemplateColors);
              }
            }}
            colorKey={colorKey}
          />
        </div>
      ))}
    </FormModal>
  );
};

import { MdMoreHoriz } from 'react-icons/md';
import styled, { keyframes } from 'styled-components';

import { colors, radii, shadows } from '@gbs-monorepo-packages/styles';
import * as DropdownMenuPrimitive from '@radix-ui/react-dropdown-menu';

const slideUpAndFade = keyframes`
  0% { opacity: 0; transform: "translateY(2px)" };
  100% { opacity: 1; transform: "translateY(0)" };
`;

const slideRightAndFade = keyframes`
  0% { opacity: 0; transform: "translateX(-2px)" };
  100% { opacity: 1; transform: "translateX(0)" };
`;

const slideDownAndFade = keyframes`
  0% { opacity: 0; transform: "translateY(-2px)" };
  100% { opacity: 1; transform: "translateY(0)" };
`;

const slideLeftAndFade = keyframes`
  0% { opacity: 0; transform: "translateX(2px)" };
  100% { opacity: 1; transform: "translateX(0)" };
`;

interface IDropdownMenuContentProps {
  $zIndex?: number;
}

// prettier-ignore
export const DropdownMenuContent = styled(DropdownMenuPrimitive.Content)<IDropdownMenuContentProps>`
  background-color: ${colors.white300};
  border-radius: ${radii.sm};
  min-width: 150px;
  max-width: 99vw;
  padding: 5px;
  box-shadow: 0px 10px 38px -10px rgba(22, 23, 24, 0.35),
    0px 10px 20px -15px rgba(22, 23, 24, 0.2);
  z-index: ${({ $zIndex }) => ($zIndex !== undefined ? $zIndex : 'auto')};

  @media (prefers-reduced-motion: no-preference) {
    animation-duration: 400ms;
    animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
    will-change: transform, opacity;
    &[data-state='open'] {
      &[data-side='top'] {
        animation-name: ${slideDownAndFade};
      }

      &[data-side='right'] {
        animation-name: ${slideLeftAndFade};
      }

      &[data-side='bottom'] {
        animation-name: ${slideUpAndFade};
      }

      &[data-side='left'] {
        animation-name: ${slideRightAndFade};
      }
    }
  }
`;

export const StyledArrow = styled(DropdownMenuPrimitive.Arrow)`
  fill: ${colors.white300};
`;

export const OptionsButton = styled.button`
  all: unset;
  align-items: center;
  border-radius: ${radii.full};
  display: inline-flex;
  font-family: inherit;
  height: 2.5rem;
  justify-content: center;
  width: 2.5rem;

  &:hover {
    background-color: ${colors.backgroundHover};
  }
  &:focus {
    box-shadow: ${shadows.modal};
  }
`;

export const OptionsIcon = styled(MdMoreHoriz)`
  height: 2rem;
  width: 2rem;
`;

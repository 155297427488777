import { produce } from 'immer';
import {
  type MouseEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import {
  generatePath,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import useWebSocket from 'react-use-websocket';

import { useAuth } from '@gbs-monorepo-packages/auth';
import { FormBase, SearchBar } from '@gbs-monorepo-packages/common';

import { BaseTooltip } from '../../components/BaseTooltip';
import { Button } from '../../components/Button';
import { CenteredText } from '../../components/CenteredText';
import { ContentPagination } from '../../components/ContentPagination';
import { DialogModal } from '../../components/DialogModal';
import { SelectData } from '../../components/SelectData';
import { WEBSOCKET_BASE_URL } from '../../constants/Env';
import { Roles } from '../../constants/Roles';
import {
  CREATE_TEMPLATE,
  REDIRECT,
  TEMPLATE_VIEW,
} from '../../constants/RoutePaths';
import { type IEditTemplateState } from '../../constants/RouteStates';
import {
  TemplatesType,
  groupsTypes,
  templatesTypes,
} from '../../constants/TemplatesTypes';
import { useToast } from '../../hooks/useToast';
import {
  type IApiThrowsError,
  type IPaginationMetaProps,
} from '../../services/api';
import {
  type ICreateTemplateGroupProps,
  type IPaginationTemplateGroupsDTO,
  type IPaginationTemplatesDTO,
  type ITemplateDTO,
  type ITemplateGroupDTO,
  type ITemplatePageDTO,
  type IUpdateTemplateGroupProps,
  createTemplateGroup,
  createTemplatePage,
  deletePageTemplateGroup,
  deleteTemplateGroup,
  deleteTemplatePage,
  getTemplateGroupPages,
  getTemplateGroupsByCompanyId,
  getTemplatePagesByCompanyId,
  templateBaseCss,
  templateBaseHtml,
  updateTemplateGroup,
} from '../../services/templates';
import { getRouteFrom } from '../../utils/getRoutes';
import Logger from '../../utils/logger';
import { SavePageAsTemplateModal } from '../EditCourse/components/SavePageAsTemplateModal';
import { CreateNewGroupModal } from './components/CreateNewGroupModal';
import {
  AccordionContent,
  AccordionGroupActions,
  AccordionGroupLabel,
  AccordionItem,
  AccordionRoot,
  AccordionTrigger,
  ActionButton,
  ActionButtonContainer,
  ButtonContent,
  ContentInputSearch,
  DeleteIcon,
  EditIcon,
  GroupListItemCard,
  Header,
  Loading,
  LoadingContainer,
  MainContainer,
  MainContent,
  NoTemplatesContainer,
  NoTemplatesText,
  PreviewIcon,
  PreviewLink,
  SearchContent,
  TabsContent,
  TabsList,
  TabsRoot,
  TabsTrigger,
  TemplateCard,
  TemplateName,
  TemplateNameContainer,
  TemplateTypeIcon,
  TemplateTypeIconContainer,
  TitlePage,
  UploadButtonContainer,
} from './styles';

export interface ITemplatePage extends ITemplatePageDTO {
  selected?: boolean;
  index?: number;
}

export interface ICourseWebsocketMessage {
  id: number;
  type: string;
  message?: string;
  title?: string;
  section?: string;
}

const fromGlobalTemplates = TemplatesType.GLOBAL_TEMPLATES;

const TEMPLATE_DELETE_REQUEST_MESSAGE = 'template_delete_request_message';
const TEMPLATE_DELETE_DENIED_MESSAGE = 'template_delete_denied_message';
const titleSearchKey = 'titleTemplate';
const typeSearchKey = 'typeTemplate';
const titleGroupSearchKey = 'titleCourseTemplate';
const typeGroupSearchKey = 'typeCourseTemplate';

const delaySearchTemplates = 1000;

const templatesOptionsFiltered = templatesTypes.filter(
  (i) => i.key !== TemplatesType.BUILD_SCRATCH
);

export const TemplateCourses = (): JSX.Element => {
  const [tabOpened, setTabOpened] = useState('T'); // T = Templates, G = Groups
  const [loadingTemplates, setLoadingTemplates] = useState(true);
  const [isUserAdmin, setUserIsAdmin] = useState(false);
  const [isUserManager, setUserIsManager] = useState(false);
  const [canEdit, setCanEdit] = useState(true);
  const [canDelete, setCanDelete] = useState(true);
  const [templates, setTemplates] = useState<ITemplatePage[]>([]);
  const [loadingSavePageAsTemplate, setLoadingSavePageAsTemplate] =
    useState(false);
  const [isModalSavePageAsTemplateOpen, setIsModalSavePageAsTemplateOpen] =
    useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [paginationMeta, setPaginationMeta] =
    useState<IPaginationMetaProps | null>(null);
  const [templateToDelete, setTemplateToDelete] =
    useState<ITemplatePage | null>(null);
  const isModalDeleteOpen = !!templateToDelete;

  // Course Templates
  const [searchTemplateGroup, setSearchTemplateGroup] = useState<string>('');
  const lastGroupSearch = useRef(searchTemplateGroup);
  const [
    isModalCreateCourseFromTemplateOpen,
    setIsModalCreateCourseFromTemplateOpen,
  ] = useState(false);
  const [templatesGroup, setTemplatesGroups] = useState<ITemplateGroupDTO[]>(
    []
  );
  const [loadingGroupTemplatesItems, setLoadingGroupTemplatesItems] =
    useState(false);
  const [idTemplateGroupOpen, setIdTemplateGroupOpen] = useState<string>('');
  const [templateGroupItemsOpen, setTemplateGroupItemsOpen] = useState<
    ITemplateDTO[]
  >([]);
  const [templateGroupToDelete, setTemplateGroupToDelete] =
    useState<ITemplateGroupDTO | null>(null);
  const [paginationMetaTemplateGroup, setPaginationMetaTemplateGroup] =
    useState<IPaginationMetaProps | null>(null);
  const [loadingDeleteTemplateGroup, setLoadingDeleteTemplateGroup] =
    useState(false);
  const [templateGroupUpdate, setTemplateGroupUpdate] =
    useState<ITemplateGroupDTO | null>(null);
  const [isModalUpdateTemplateGroupOpen, setIsModalUpdateTemplateGroupOpen] =
    useState(false);

  const [refreshPage, setRefreshPage] = useState(false);
  const isModalDeleteOpenTemplateGroup = !!templateGroupToDelete;

  const { companyId = '' } = useParams(); // need to save this value to COMPANIES route
  const loadedTemplatesGroupOnce = useRef(false);
  const navigate = useNavigate();
  const { addToast } = useToast();
  const { user, getCurrentRole } = useAuth();
  const { pathname } = useLocation();

  const searchTimeOutHandler = useRef<NodeJS.Timeout>();
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchTemplate, setSearchTemplate] = useState<string>(
    searchParams.get(titleSearchKey) ?? ''
  );
  const lastTemplateSearch = useRef(searchTemplate);
  const [templateOptionsId, setTemplateOptionsId] = useState(
    searchParams.get(typeSearchKey) ?? fromGlobalTemplates
  );

  const [groupTemplateOptionsId, setGroupTemplateOptionsId] = useState(
    searchParams.get(typeGroupSearchKey) ?? fromGlobalTemplates
  );

  useEffect(() => {
    const id = Number(companyId);
    if (id === 0 || isNaN(id)) {
      navigate(getRouteFrom(REDIRECT));
    }
  }, [companyId, navigate]);

  useEffect(() => {
    const currentRole = getCurrentRole(user?.roles ?? []);
    if (currentRole?.key === Roles.ADMIN) {
      setUserIsAdmin(true);
    }
    if (currentRole?.key === Roles.MANAGER) {
      setUserIsManager(true);
    }
  }, [getCurrentRole, user]);

  useEffect(() => {
    let mount = true;

    const initialLoadTemplates = async () => {
      try {
        setLoadingTemplates(true);

        const result = await getTemplatePagesByCompanyId({
          clientId: Number(companyId),
          templateOptionsId,
          page: 1,
          limit: 15,
          filter: searchTemplate,
        });

        const resultWithIndex: ITemplatePage[] = result.data.map(
          (data, index) => ({
            ...data,
            index,
          })
        );

        if (mount) {
          setTemplates(resultWithIndex);
          setPaginationMeta(result.meta);
          lastTemplateSearch.current = searchTemplate;
        }
      } catch (err) {
        Logger.debug('err: ', err);
      } finally {
        setLoadingTemplates(false);
      }
    };

    if (companyId && tabOpened === 'T') {
      void initialLoadTemplates();
    }

    return () => {
      mount = false;
    };
  }, [companyId, tabOpened]);

  useEffect(() => {
    setTemplatesGroups([]);
    const loadTemplatesOnce = async () => {
      setSearchTemplate('');
      setLoadingTemplates(true);
      try {
        const result = await getTemplateGroupsByCompanyId({
          templateOptionsId: Number(groupTemplateOptionsId),
          page: 1,
          limit: 15,
          filter: searchTemplate,
        });

        const resultIfIndex: ITemplateGroupDTO[] = result.data.map(
          (data: ITemplateGroupDTO, index: number) => ({
            ...data,
            index,
          })
        );

        setTemplatesGroups(resultIfIndex);
        setPaginationMetaTemplateGroup(result.meta);
      } catch (err) {
        Logger.debug('err: ', err);
      } finally {
        setLoadingTemplates(false);
      }
    };

    if (companyId && tabOpened === 'G') {
      void loadTemplatesOnce();
      Logger.notice('loadGroupTemplatesOnce');
    }
  }, [companyId, tabOpened, refreshPage]);

  useEffect(() => {
    loadedTemplatesGroupOnce.current = false;
  }, [templateOptionsId, tabOpened]);

  useEffect(() => {
    if (refreshPage) {
      setRefreshPage(false);
    }
  }, [refreshPage]);

  useEffect(() => {
    const loadTemplatesOnce = async () => {
      setLoadingGroupTemplatesItems(true);
      try {
        const result = await getTemplateGroupPages({
          templateGroupId: +idTemplateGroupOpen,
        });

        const resultIfIndex: ITemplateDTO[] = result.data.map(
          (data: ITemplateDTO, index: number) => ({
            ...data,
            index,
          })
        );

        setTemplateGroupItemsOpen(resultIfIndex);
      } catch (err) {
        Logger.debug('err: ', err);
      } finally {
        setLoadingGroupTemplatesItems(false);
      }
    };
    if (idTemplateGroupOpen) {
      void loadTemplatesOnce();
      Logger.notice('loadTemplatesOnce');
    }
  }, [idTemplateGroupOpen]);

  useEffect(() => {
    const currentRole = getCurrentRole(user?.roles ?? []);
    let permissionEdit = true;
    let permissionDelete = true;
    if (
      templateOptionsId === TemplatesType.GLOBAL_TEMPLATES &&
      currentRole?.key !== Roles.ADMIN
    ) {
      permissionEdit = false;
      permissionDelete = false;
    }
    if (
      (currentRole?.key === Roles.STAFF ||
        currentRole?.key === Roles.CREW ||
        currentRole?.key === Roles.VIEWER) &&
      templateOptionsId === TemplatesType.SHARE_TEMPLATES
    ) {
      permissionEdit = false;
      permissionDelete = false;
    }

    if (templateOptionsId === TemplatesType.MY_TEMPLATES) {
      permissionEdit = true;
      permissionDelete = true;
    }

    setCanEdit(permissionEdit);
    setCanDelete(permissionDelete);
  }, [getCurrentRole, isUserAdmin, templateOptionsId, user?.roles]);

  const handleDeleteTemplate = useCallback(() => {
    setLoadingDelete(true);
    try {
      if (templateToDelete?.id === undefined) {
        return;
      }
      deleteTemplatePage({ id: templateToDelete?.id })
        .then(() => {
          const index = templates.findIndex(
            ({ id }) => id === templateToDelete?.id
          );

          if (index >= 0) {
            setTemplates(
              produce((draft) => {
                draft.splice(index, 1);
              })
            );
          }
          addToast({
            title: 'Template deleted',
            styleType: 'success',
            dataCy: 'delete-template-success-toast',
          });
        })
        .catch((error: IApiThrowsError) => {
          addToast({
            title: 'Error on delete template',
            description:
              error.response?.status === 400
                ? error.response.data.error.message
                : 'An error occurred. Please try again or contact Edge support.',
            styleType: 'error',
            dataCy: 'delete-template-error-toast',
          });
        });
    } catch (error: any) {
      Logger.debug('error: ', error);
    } finally {
      setLoadingDelete(false);
      setTemplateToDelete(null);
    }
  }, [addToast, templateToDelete?.id, templates]);

  const { sendMessage } = useWebSocket(WEBSOCKET_BASE_URL, {
    onMessage: (messageEvent: WebSocketEventMap['message']) => {
      const courseWebsocketMessage = JSON.parse(
        messageEvent.data as string
      ) as ICourseWebsocketMessage;

      switch (courseWebsocketMessage.type) {
        case TEMPLATE_DELETE_DENIED_MESSAGE:
          setTemplateToDelete(null);
          addToast({
            title: courseWebsocketMessage.title,
            description: courseWebsocketMessage.message,
            styleType: 'error',
            dataCy: 'template-delete-denied-toast',
          });
          break;
        default:
          handleDeleteTemplate();
          break;
      }
    },
    shouldReconnect: () => true,
  });

  const sendDeleteRequest = useCallback(() => {
    if (templateToDelete) {
      const requestEditCourseMessage: ICourseWebsocketMessage = {
        id: templateToDelete.id,
        type: TEMPLATE_DELETE_REQUEST_MESSAGE,
        section: 'page_template',
      };

      sendMessage(JSON.stringify(requestEditCourseMessage));
    }
  }, [templateToDelete, sendMessage]);

  const handleOpenModalDelete = (
    e: MouseEvent<HTMLButtonElement | HTMLDivElement>,
    course: ITemplatePage
  ) => {
    e.stopPropagation();
    setTemplateToDelete(course);
  };

  const handleSavePageTemplate = useCallback(
    async (title: string, templateOptions: number) => {
      setLoadingSavePageAsTemplate(true);
      if (title === '') {
        addToast({
          title: 'Error on save template',
          description: 'Need a description.',
          styleType: 'error',
          dataCy: 'save-template-description-error-toast',
        });
        setLoadingSavePageAsTemplate(false);
        return;
      }
      const companyIdNum = parseInt(companyId);
      try {
        const result = await createTemplatePage({
          companyId: companyIdNum,
          cssContent: templateBaseCss,
          hasWrapper: true,
          htmlContent: templateBaseHtml,
          templateOptions,
          title,
          fonts: [],
          components: '{}',
        });

        const state: IEditTemplateState = {
          from: pathname,
        };
        setLoadingSavePageAsTemplate(false);
        navigate(
          getRouteFrom(CREATE_TEMPLATE)
            .replace(':templateId', String(result.id))
            .replace(':companyId', companyId),
          { state }
        );

        addToast({
          title: 'Template saved',
          styleType: 'success',
          dataCy: 'save-template-success-toast',
        });
      } catch (error) {
        setLoadingSavePageAsTemplate(false);
        addToast({
          title: 'Error on save template',
          description:
            'An error occurred. Please try again or contact Edge support.',
          styleType: 'error',
          dataCy: 'save-template-error-toast',
        });
      }
    },
    [addToast, companyId, navigate, pathname]
  );

  const handleOpenSavePageAsTemplate = () => {
    setIsModalSavePageAsTemplateOpen(true);
  };

  const handleDeclineSavePageTemplate = useCallback(() => {
    setIsModalSavePageAsTemplateOpen(false);
  }, []);

  const handleOpenEditTemplate = useCallback(
    (e: MouseEvent<HTMLButtonElement | HTMLDivElement>, id: string) => {
      e.stopPropagation();
      const state: IEditTemplateState = {
        from: pathname,
      };

      navigate(
        getRouteFrom(CREATE_TEMPLATE)
          .replace(':templateId', id)
          .replace(':companyId', companyId),
        {
          state,
        }
      );
    },
    [companyId, navigate, pathname]
  );

  const searchTemplates = useCallback(
    ({
      forceFirstPage = false,
      newSearch = searchTemplate,
      newTemplateOptionsId = templateOptionsId,
    } = {}) => {
      let updateSearch = false;
      if (!searchParams.has(typeSearchKey)) {
        searchParams.set(typeSearchKey, String(newTemplateOptionsId));
        updateSearch = true;
      }
      if (
        !(newSearch === null || searchParams.get(titleSearchKey) === newSearch)
      ) {
        searchParams.set(titleSearchKey, newSearch);
        updateSearch = true;
      }
      updateSearch && setSearchParams(searchParams);

      setLoadingTemplates(true);
      const pageAux = forceFirstPage
        ? 0
        : Number((paginationMeta?.page ?? 0) > 0 ? paginationMeta?.page : 0);

      getTemplatePagesByCompanyId({
        clientId: Number(companyId),
        templateOptionsId: newTemplateOptionsId,
        page: pageAux + 1,
        limit: 15,
        filter: newSearch ?? '',
      })
        .then((response: IPaginationTemplatesDTO) => {
          setTemplates(
            pageAux === 0
              ? response.data
              : produce((draft) => {
                  draft.push(...response.data);
                })
          );
          setPaginationMeta(response.meta);
          lastTemplateSearch.current = newSearch ?? '';
        })
        .catch((error: IApiThrowsError) => {
          Logger.debug('error: ', error);
        })
        .finally(() => {
          setLoadingTemplates(false);
        });
    },
    [
      companyId,
      paginationMeta?.page,
      searchTemplate,
      searchParams,
      setSearchParams,
      templateOptionsId,
    ]
  );

  const handleDeclineDelete = useCallback(() => {
    setTemplateToDelete(null);
  }, []);

  useEffect(() => {
    let timer: NodeJS.Timeout | null = null;

    if (searchTemplate.trim() !== lastTemplateSearch.current) {
      if (searchTemplate.trim() === '') {
        searchTemplates({
          newSearch: searchTemplate.trim(),
          forceFirstPage: true,
        });
      } else {
        timer = setTimeout(() => {
          searchTemplates({
            newSearch: searchTemplate.trim(),
            forceFirstPage: true,
          });
        }, 1000);
      }
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [searchTemplate]);

  const isLoadingTemplates = loadingTemplates || loadingDelete;

  // ----- Course

  const handleOpenModalCreateGroupFromTemplate = useCallback(() => {
    setIsModalCreateCourseFromTemplateOpen(true);
  }, []);

  const handleCloseModalCreateGroupFromTemplate = useCallback(() => {
    setIsModalCreateCourseFromTemplateOpen(false);
  }, []);

  const handleOpenUpdateTemplateGroup = (
    e: MouseEvent<HTMLButtonElement | HTMLDivElement>,
    templateGroup: ITemplateGroupDTO
  ) => {
    e.preventDefault();
    setTemplateGroupUpdate(templateGroup);
    setIsModalUpdateTemplateGroupOpen(true);
  };

  const handleDeclineUpdateTemplateGroup = useCallback(() => {
    setIsModalUpdateTemplateGroupOpen(false);
    setTemplateGroupUpdate(null);
  }, []);

  const handleTabClick = useCallback(
    (value: string) => {
      setTabOpened(value);
    },
    [setTabOpened]
  );

  const handleOpenTemplateGroup = useCallback((id: string) => {
    setIdTemplateGroupOpen(id);
  }, []);

  const handleDeleteTemplateGroup = useCallback(() => {
    setLoadingTemplates(true);
    setLoadingDeleteTemplateGroup(true);
    try {
      if (templateGroupToDelete?.id === undefined) {
        return;
      }
      deleteTemplateGroup({ id: templateGroupToDelete?.id })
        .then(() => {
          const index = templatesGroup.findIndex(
            ({ id }) => id === templateGroupToDelete?.id
          );

          if (index >= 0) {
            setTemplatesGroups(
              produce((draft) => {
                draft.splice(index, 1);
              })
            );
          }
          addToast({
            title: 'Course Template deleted',
            styleType: 'success',
            dataCy: 'delete-template-success-toast',
          });
        })
        .catch((error: IApiThrowsError) => {
          addToast({
            title: 'Error on delete the course template',
            description:
              error?.response?.status === 400
                ? error.response.data.error.message
                : 'An error occurred. Please try again or contact Edge support.',
            styleType: 'error',
            dataCy: 'delete-template-error-toast',
          });
        });
    } catch (error: any) {
      Logger.debug('error: ', error);
    } finally {
      setLoadingTemplates(false);
      setLoadingDelete(false);
      setTemplateGroupToDelete(null);
      setLoadingDeleteTemplateGroup(false);
    }
  }, [addToast, templateGroupToDelete?.id, templatesGroup]);

  const handleRemoveItemTemplateGroup = useCallback(
    (
      e: MouseEvent<HTMLButtonElement | HTMLDivElement>,
      id: number,
      pageTemplateId: number
    ) => {
      e.preventDefault();
      setLoadingTemplates(true);
      try {
        if (id === undefined) {
          return;
        }

        deletePageTemplateGroup({ id, pageTemplateId })
          .then(() => {
            // remove template group item
            const index = templateGroupItemsOpen.findIndex(
              ({ id }) => id === pageTemplateId
            );

            if (index >= 0) {
              setTemplateGroupItemsOpen(
                produce((draft) => {
                  draft.splice(index, 1);
                })
              );
            }
            addToast({
              title: 'Template deleted',
              styleType: 'success',
              dataCy: 'delete-template-success-toast',
            });
          })
          .catch((error: IApiThrowsError) => {
            addToast({
              title: 'Error on delete template',
              description:
                error?.response?.status === 400
                  ? error.response.data.error.message
                  : 'An error occurred. Please try again or contact Edge support.',
              styleType: 'error',
              dataCy: 'delete-template-error-toast',
            });
          });
      } catch (error: any) {
        Logger.debug('error: ', error);
      } finally {
        setLoadingTemplates(false);
        setLoadingDelete(false);
        setTemplateGroupToDelete(null);
      }
    },
    [addToast, templateGroupItemsOpen]
  );

  const handleOpenModalDeleteTemplateGroup = (
    e: MouseEvent<HTMLButtonElement | HTMLDivElement>,
    templateGroup: ITemplateGroupDTO
  ) => {
    e.stopPropagation();
    setTemplateGroupToDelete(templateGroup);
  };

  const handleDeclineDeleteTemplateGroup = useCallback(() => {
    setTemplateGroupToDelete(null);
  }, []);

  const searchTemplatesGroup = useCallback(
    ({
      forceFirstPage = false,
      newSearch = searchTemplateGroup ?? '',
      newTemplateOptionsId = groupTemplateOptionsId,
    } = {}) => {
      let updateSearch = false;
      if (!searchParams.has(typeGroupSearchKey)) {
        searchParams.set(typeGroupSearchKey, String(newTemplateOptionsId));
        updateSearch = true;
      }
      if (
        !(
          newSearch === null ||
          searchParams.get(titleGroupSearchKey) === newSearch
        )
      ) {
        searchParams.set(titleGroupSearchKey, newSearch);
        updateSearch = true;
      }
      updateSearch && setSearchParams(searchParams);

      setLoadingTemplates(true);
      const pageAux = forceFirstPage
        ? 0
        : Number((paginationMeta?.page ?? 0) > 0 ? paginationMeta?.page : 0);

      getTemplateGroupsByCompanyId({
        templateOptionsId: Number(newTemplateOptionsId),
        page: pageAux + 1,
        limit: 15,
        filter: newSearch ?? '',
      })
        .then((response: IPaginationTemplateGroupsDTO) => {
          setTemplatesGroups(
            pageAux === 0
              ? response.data
              : produce((draft) => {
                  draft.push(...response.data);
                })
          );
          setPaginationMetaTemplateGroup(response.meta);
          lastGroupSearch.current = newSearch ?? '';
        })
        .catch((error: IApiThrowsError) => {
          Logger.debug('error: ', error);
        })
        .finally(() => {
          setLoadingTemplates(false);
        });
    },
    [
      paginationMeta?.page,
      searchTemplateGroup,
      searchParams,
      setSearchParams,
      groupTemplateOptionsId,
    ]
  );

  const addTemplateGroup = useCallback(
    async ({
      client,
      templateOptions,
      title,
      pageTemplates,
    }: ICreateTemplateGroupProps) => {
      try {
        const result = await createTemplateGroup({
          client,
          templateOptions,
          title,
          pageTemplates,
        });
        if (result.id) {
          addToast({
            title: 'Template Course created',
            styleType: 'success',
            dataCy: 'save-template-success-toast',
          });
          setIsModalCreateCourseFromTemplateOpen(false);
          setRefreshPage(true);
          loadedTemplatesGroupOnce.current = false;
        }
      } catch (error) {
        Logger.debug('error: ', error);
        addToast({
          title: 'Error on create course',
          description:
            'An error occurred. Please try again or contact Edge support.',
          styleType: 'error',
          dataCy: 'create-course-from-template-error-toast',
        });
      }
    },
    [addToast]
  );

  const handleUpdateTemplateGroup = useCallback(
    async ({
      id,
      templateOptions,
      title,
      pageTemplates,
    }: IUpdateTemplateGroupProps) => {
      try {
        const result = await updateTemplateGroup({
          id,
          templateOptions,
          title,
          pageTemplates,
        });
        if (result.id) {
          addToast({
            title: 'Template Course updated',
            styleType: 'success',
            dataCy: 'save-template-success-toast',
          });
          setIsModalCreateCourseFromTemplateOpen(false);
          setIdTemplateGroupOpen('');
          searchTemplatesGroup({
            newSearch: '',
            newTemplateOptionsId: templateOptions,
            forceFirstPage: true,
          });
        }
      } catch (error) {
        Logger.debug('error: ', error);
        addToast({
          title: 'Error on update course',
          description:
            'An error occurred. Please try again or contact Edge support.',
          styleType: 'error',
          dataCy: 'create-course-from-template-error-toast',
        });
      }
    },
    [addToast, searchTemplatesGroup]
  );

  useEffect(() => {
    let timer: NodeJS.Timeout | null = null;

    if (searchTemplateGroup.trim() !== lastGroupSearch.current) {
      if (searchTemplateGroup.trim() === '') {
        lastGroupSearch.current = searchTemplateGroup.trim();
        searchTemplatesGroup({
          newSearch: searchTemplateGroup.trim(),
          forceFirstPage: true,
        });
      } else {
        timer = setTimeout(() => {
          lastGroupSearch.current = searchTemplateGroup.trim();
          searchTemplatesGroup({
            newSearch: searchTemplateGroup.trim(),
            forceFirstPage: true,
          });
        }, 1000);
      }
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [searchTemplateGroup]);

  const handleChangeTemplateType = useCallback(
    (id: number) => {
      lastTemplateSearch.current = '';
      clearTimeout(searchTimeOutHandler.current);
      if (tabOpened === 'T') {
        setTemplateOptionsId(id);
        searchParams.set(typeSearchKey, String(id));
        searchParams.delete(titleSearchKey);
        setSearchParams(searchParams);
        setSearchTemplate('');
        setTemplates([]);
        searchTimeOutHandler.current = setTimeout(() => {
          searchTemplates({
            newSearch: '',
            newTemplateOptionsId: id,
            forceFirstPage: true,
          });
        }, delaySearchTemplates);
      } else {
        setGroupTemplateOptionsId(id);
        searchParams.set(typeGroupSearchKey, String(id));
        searchParams.delete(titleGroupSearchKey);
        setSearchParams(searchParams);
        setSearchTemplateGroup('');
        setTemplatesGroups([]);
        searchTimeOutHandler.current = setTimeout(() => {
          searchTemplatesGroup({
            newSearch: '',
            newTemplateOptionsId: id,
            forceFirstPage: true,
          });
        }, delaySearchTemplates);
      }
    },
    [
      searchParams,
      searchTemplates,
      searchTemplatesGroup,
      setSearchParams,
      tabOpened,
    ]
  );

  return (
    <MainContainer data-cy="page-container">
      <TabsRoot
        defaultValue="G"
        value={tabOpened}
        onValueChange={handleTabClick}
      >
        <TabsList aria-label="Choose a image">
          <TabsTrigger value="T" data-cy="tab-template">
            Templates
          </TabsTrigger>
          <TabsTrigger value="G" data-cy="tab-group">
            Course Templates
          </TabsTrigger>
        </TabsList>

        <TabsContent value="T" data-cy="tab-upload">
          <Header>
            <TitlePage data-cy="title-pageName">Templates</TitlePage>
            <UploadButtonContainer data-cy="add-button-container">
              <Button
                dataCy="dropdown-add-button"
                onClick={handleOpenSavePageAsTemplate}
              >
                <ButtonContent>
                  <p data-cy="button-add">Add Template</p>
                </ButtonContent>
              </Button>
            </UploadButtonContainer>
          </Header>
          <MainContent data-cy="courses-card-list">
            <SearchContent data-cy="search-content">
              <FormBase.InputContent
                filled={Boolean(templateOptionsId)}
                inputRef="select-templateType"
                label="Template Type"
              >
                <SelectData
                  data-cy="select-templateType"
                  data={templatesOptionsFiltered}
                  placeholder="Choose a template type"
                  onValueChange={(id) => {
                    handleChangeTemplateType(Number(id));
                  }}
                  required
                  zIndex={10}
                  defaultValue={String(templateOptionsId)} // Global Template
                />
              </FormBase.InputContent>
              <ContentInputSearch>
                <SearchBar
                  search={searchTemplate}
                  placeholder="Search template"
                  onChange={(e) => {
                    setSearchTemplate(e.target.value);
                  }}
                  onClearInput={() => {
                    setSearchTemplate('');
                  }}
                  loading={isLoadingTemplates}
                />
              </ContentInputSearch>
            </SearchContent>
            <ContentPagination data-cy="content-pagination">
              {isLoadingTemplates && templates.length === 0 ? (
                <LoadingContainer data-cy="loading-templates-container">
                  <Loading dataCy="loading-templates" />
                </LoadingContainer>
              ) : !templates.length ? (
                <NoTemplatesContainer>
                  <NoTemplatesText data-cy="text-noTemplates-found">
                    No templates found
                  </NoTemplatesText>
                </NoTemplatesContainer>
              ) : (
                <InfiniteScroll
                  height={200}
                  style={{
                    overflow: 'auto',
                    maxHeight: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    flexGrow: 1,
                  }}
                  dataLength={templates.length} // This is important field to render the next data
                  next={searchTemplates}
                  hasMore={templates.length < (paginationMeta?.total ?? 0)}
                  loader={
                    <LoadingContainer data-cy="loading-templates-container">
                      <Loading dataCy="loading-templates" />
                    </LoadingContainer>
                  }
                  endMessage={
                    <CenteredText
                      message="You have seen it all"
                      dataCy="pagination-endLimit"
                    />
                  }
                >
                  {templates.map((template: ITemplatePageDTO) => {
                    const { id, title } = template;
                    return (
                      <TemplateCard data-cy={id} key={id}>
                        <div
                          data-cy="template-div"
                          style={{ display: 'flex', width: '100%' }}
                        >
                          <TemplateTypeIconContainer data-cy="template-type-container">
                            <TemplateTypeIcon />
                          </TemplateTypeIconContainer>
                          <TemplateNameContainer data-cy="template-name-container">
                            <TemplateName data-cy={title}>{title}</TemplateName>
                          </TemplateNameContainer>
                        </div>
                        <ActionButtonContainer data-cy="container-actionButton">
                          <BaseTooltip message="Preview">
                            <PreviewLink
                              data-cy="preview-link"
                              target="_blank"
                              to={generatePath(getRouteFrom(TEMPLATE_VIEW), {
                                templateId: id,
                              })}
                            >
                              <PreviewIcon />
                            </PreviewLink>
                          </BaseTooltip>
                          <BaseTooltip message="Edit">
                            <ActionButton
                              onClick={(e) => {
                                handleOpenEditTemplate(e, String(id));
                              }}
                              type="button"
                              data-cy="edit-button"
                              isVisible={
                                canEdit || template.userCreatedId === user?.id
                              }
                            >
                              <EditIcon />
                            </ActionButton>
                          </BaseTooltip>
                          <BaseTooltip message="Delete">
                            <ActionButton
                              onClick={(e) => {
                                handleOpenModalDelete(e, template);
                              }}
                              type="button"
                              data-cy="delete-button"
                              isVisible={
                                canDelete || template.userCreatedId === user?.id
                              }
                            >
                              <DeleteIcon />
                            </ActionButton>
                          </BaseTooltip>
                        </ActionButtonContainer>
                      </TemplateCard>
                    );
                  })}
                </InfiniteScroll>
              )}
            </ContentPagination>
          </MainContent>
        </TabsContent>
        <TabsContent value="G" data-cy="tab-upload">
          <Header>
            <TitlePage data-cy="page-name">Course Templates</TitlePage>
            <UploadButtonContainer data-cy="add-button-container">
              <Button
                dataCy="dropdown-add-group-button"
                onClick={handleOpenModalCreateGroupFromTemplate}
              >
                <ButtonContent>
                  <p data-cy="button-newGroup">New Course Template</p>
                </ButtonContent>
              </Button>
            </UploadButtonContainer>
          </Header>
          <MainContent data-cy="courses-card-list">
            <SearchContent data-cy="courses-abc">
              <FormBase.InputContent
                filled={Boolean(groupTemplateOptionsId)}
                inputRef="select-templateGroupType"
                label="Select Course Template Type"
              >
                <SelectData
                  data-cy="select-templateGroupType"
                  data={groupsTypes}
                  placeholder="Choose a course template type"
                  onValueChange={(id) => {
                    handleChangeTemplateType(Number(id));
                  }}
                  required
                  zIndex={10}
                  defaultValue={String(groupTemplateOptionsId)} // Global Template
                  value={String(groupTemplateOptionsId)}
                />
              </FormBase.InputContent>
              <ContentInputSearch>
                <SearchBar
                  search={searchTemplateGroup}
                  placeholder="Search course template"
                  onChange={(e) => {
                    setSearchTemplateGroup(e.target.value);
                  }}
                  onClearInput={() => {
                    setSearchTemplateGroup('');
                  }}
                  loading={isLoadingTemplates}
                />
              </ContentInputSearch>
            </SearchContent>
            <ContentPagination data-cy="content-pagination">
              {loadingTemplates && templatesGroup.length === 0 ? (
                <LoadingContainer data-cy="loading-templates-container">
                  <Loading dataCy="loading-templates" />
                </LoadingContainer>
              ) : !templatesGroup.length ? (
                <NoTemplatesContainer data-cy="no-templates-founded">
                  <NoTemplatesText data-cy="no-templates-founded-text">
                    No course templates found
                  </NoTemplatesText>
                </NoTemplatesContainer>
              ) : (
                <AccordionRoot
                  type="single"
                  collapsible
                  onValueChange={handleOpenTemplateGroup}
                >
                  <InfiniteScroll
                    height={200}
                    style={{
                      overflow: 'auto',
                      maxHeight: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      flexGrow: 1,
                    }}
                    dataLength={templatesGroup.length} // This is important field to render the next data
                    next={searchTemplatesGroup}
                    hasMore={
                      templatesGroup.length <
                      (paginationMetaTemplateGroup?.total ?? 0)
                    }
                    loader={
                      <LoadingContainer data-cy="loading-templates-container">
                        <Loading dataCy="loading-templates" />
                      </LoadingContainer>
                    }
                    endMessage={
                      <CenteredText
                        message="You have seen it all"
                        dataCy="end-limit-pagination"
                      />
                    }
                  >
                    {templatesGroup.map((group: ITemplateGroupDTO) => {
                      const { id, title } = group;
                      return (
                        <AccordionItem
                          data-cy="accordion-item"
                          key={id}
                          value={String(id)}
                        >
                          <AccordionTrigger data-cy={title}>
                            <AccordionGroupLabel data-cy="accordion-groupLabel">
                              {title}
                            </AccordionGroupLabel>
                            <AccordionGroupActions data-cy="accordion-groupActions">
                              <ActionButton
                                onClick={(e) => {
                                  handleOpenUpdateTemplateGroup(e, group);
                                }}
                                type="button"
                                data-cy="update-button"
                                title="Update"
                                isVisible={
                                  canEdit || group.userCreateId === user?.id
                                }
                              >
                                <EditIcon />
                              </ActionButton>
                              <ActionButton
                                onClick={(e) => {
                                  handleOpenModalDeleteTemplateGroup(e, group);
                                }}
                                type="button"
                                data-cy="delete-button"
                                title="Delete"
                                isVisible={
                                  canDelete || group.userCreateId === user?.id
                                }
                              >
                                <DeleteIcon />
                              </ActionButton>
                            </AccordionGroupActions>
                          </AccordionTrigger>
                          <AccordionContent
                            data-cy={`container-groupTemplate-${title}`}
                          >
                            {loadingGroupTemplatesItems ? (
                              <LoadingContainer data-cy="loading-templates-container">
                                <Loading dataCy="loading-templates" />
                              </LoadingContainer>
                            ) : templateGroupItemsOpen.length === 0 ? (
                              <NoTemplatesContainer data-cy="no-templates-founded">
                                <NoTemplatesText data-cy="no-templates-founded-text">
                                  No templates found
                                </NoTemplatesText>
                              </NoTemplatesContainer>
                            ) : (
                              <>
                                {templateGroupItemsOpen.map(
                                  (template: ITemplateDTO) => {
                                    const { id, title } = template;
                                    return (
                                      <GroupListItemCard
                                        data-cy="group-itemList"
                                        key={id}
                                      >
                                        <TemplateNameContainer data-cy="template-name-container">
                                          <TemplateName
                                            data-cy={`Template-${title}`}
                                          >
                                            {title}
                                          </TemplateName>
                                        </TemplateNameContainer>
                                        <AccordionGroupActions
                                          data-cy={`accordion-groupActions-${title}`}
                                        >
                                          <ActionButton
                                            onClick={(e) => {
                                              handleRemoveItemTemplateGroup(
                                                e,
                                                Number(idTemplateGroupOpen),
                                                id
                                              );
                                            }}
                                            type="button"
                                            data-cy="delete-button"
                                            title="Delete"
                                            isVisible={
                                              canDelete ||
                                              group.userCreateId === user?.id
                                            }
                                          >
                                            <DeleteIcon />
                                          </ActionButton>
                                        </AccordionGroupActions>
                                      </GroupListItemCard>
                                    );
                                  }
                                )}
                              </>
                            )}
                          </AccordionContent>
                        </AccordionItem>
                      );
                    })}
                  </InfiniteScroll>
                </AccordionRoot>
              )}
            </ContentPagination>
          </MainContent>
        </TabsContent>
      </TabsRoot>

      <SavePageAsTemplateModal
        loading={loadingSavePageAsTemplate}
        onAccept={handleSavePageTemplate}
        onDecline={handleDeclineSavePageTemplate}
        open={isModalSavePageAsTemplateOpen}
        zIndex={10}
        isUserAdmin={isUserAdmin}
        isUserManager={isUserManager}
      />

      <DialogModal
        acceptText="Confirm"
        dataCy="delete-template-dialog-modal"
        declineText="Cancel"
        loading={loadingDelete}
        mainText={`Are you sure you want to delete ${
          templateToDelete?.title ?? 'this template'
        }?`}
        onAccept={sendDeleteRequest}
        onDecline={handleDeclineDelete}
        onOpenChange={handleDeclineDelete}
        open={isModalDeleteOpen}
      />

      <DialogModal
        acceptText="Confirm"
        dataCy="delete-course-dialog-modal"
        declineText="Cancel"
        loading={loadingDeleteTemplateGroup}
        mainText={`Are you sure you want to delete ${
          templateGroupToDelete?.title ?? 'this course template'
        }?`}
        onAccept={() => {
          handleDeleteTemplateGroup();
        }}
        onDecline={handleDeclineDeleteTemplateGroup}
        onOpenChange={handleDeclineDeleteTemplateGroup}
        open={isModalDeleteOpenTemplateGroup}
      />
      <CreateNewGroupModal
        companyId={+companyId}
        onInsert={addTemplateGroup}
        onUpdate={handleUpdateTemplateGroup}
        onDecline={
          templateGroupUpdate
            ? handleDeclineUpdateTemplateGroup
            : handleCloseModalCreateGroupFromTemplate
        }
        open={
          templateGroupUpdate
            ? isModalUpdateTemplateGroupOpen
            : isModalCreateCourseFromTemplateOpen
        }
        isUserAdmin={isUserAdmin}
        isUserManager={isUserManager}
        templateGroupUpdate={templateGroupUpdate}
        zIndex={10}
      />
    </MainContainer>
  );
};

import { z } from 'zod';

export const courseSettingSchema = z.object({
  viewUrl: z.string().trim().nullable(),
  passcode: z.string().trim().nullable(),
  typeTemplate: z.string().nonempty({ message: 'Please select a template' }),
  status: z.string().nullable(),
});

export type CourseSettingSchema = z.infer<typeof courseSettingSchema>;

import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';
import styled from 'styled-components';

import { colors } from '@gbs-monorepo-packages/styles';

export const BaseItemContainer = styled.div`
  display: flex;
  padding: 1rem 0 1rem;
`;

export const BaseItemTextContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

export const BaseItemText = styled.p`
  font-weight: bold;
`;

export const BaseItemTriggerContainer = styled.div``;

export const ExpandButton = styled.button`
  all: unset;
`;

export const ExpandMenuIcon = styled(MdKeyboardArrowDown)`
  color: ${colors.button};
  height: 2rem;
  width: 2rem;
  cursor: pointer;
`;

export const CollapseMenuIcon = styled(MdKeyboardArrowUp)`
  color: ${colors.button};
  height: 2rem;
  width: 2rem;
  cursor: pointer;
`;

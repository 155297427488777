import type { CommandObject, Component, Editor } from 'grapesjs';

import { type IPositionCanvas, type IPositionEl } from '../../interfaces';
import { addBaseButtonToolbar } from '../Default';
// import { addDeleteOnlyComponentButtonToolbar } from '../DeleteOnlyComponent';
import { addSelectChildrenButtonToolbar } from '../SelectChildren';

export const AddToolbarButtons = ({ Commands }: Editor): void => {
  Commands.extend('core:component-select', {
    getOrderedToolbarButtons(component: Component) {
      return [
        ...addSelectChildrenButtonToolbar(component),
        ...addBaseButtonToolbar(component),
        // ...addDeleteOnlyComponentButtonToolbar(component),
      ];
    },
    beforeUpdateToolbar(
      component: Component,
      opts: { isNewEl: boolean; force?: boolean }
    ) {
      const { isNewEl } = opts;
      const toolbar = component.get('toolbar');
      if (toolbar && isNewEl) {
        component.set('toolbar', this.getOrderedToolbarButtons(component));
      }
    },
    updateToolsGlobal(opts: any = {}) {
      const { el, pos, component }: IPositionEl = this.getElSelected();

      if (!el) {
        // Hides toolbar
        this.toggleToolsEl();
        this.lastSelected = 0;
        return;
      }

      const { canvas } = this;
      const isNewEl = this.lastSelected !== el;

      if (isNewEl || Boolean(opts.force)) {
        this.beforeUpdateToolbar(component, { isNewEl, ...opts });

        this.lastSelected = el;
        this.updateToolbar(component);
      }

      const toolsEl = this.toggleToolsEl(1);
      const { style } = toolsEl;
      const targetToElem: IPositionCanvas = canvas.getTargetToElementFixed(
        el,
        // @ts-expect-error: Argument of type 'HTMLElement | undefined' is not assignable to parameter of type 'HTMLElement'.
        canvas.getToolbarEl(),
        { pos }
      );
      const topOff = targetToElem.canvasOffsetTop;
      const leftOff = targetToElem.canvasOffsetLeft;
      style.top = `${topOff}px`;
      style.left = `${leftOff}px`;
      style.width = `${pos.width}px`;
      style.height = `${pos.height}px`;

      this.updateToolbarPos({ top: targetToElem.top, left: targetToElem.left });
      this._trgToolUp('global', {
        component,
        el: toolsEl,
        top: topOff,
        left: leftOff,
        width: pos.width,
        height: pos.height,
      });
    },
  } as CommandObject<any, Record<string, any>>);
};

import { HiOutlineMenu, HiOutlineViewGrid } from 'react-icons/hi';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { colors, radii, scrollbar } from '@gbs-monorepo-packages/styles';
import { Item } from '@radix-ui/react-dropdown-menu';

import { BaseLoading } from '../../components/BaseLoading';

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background-color: ${colors.lightGray50};
`;

export const Header = styled.header`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 1rem;
`;

export const GridListOptions = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: end;
  margin-top: 2rem;
  margin-bottom: 1rem;
`;

export const TitlePage = styled.h1`
  font-size: 2rem;
  font-weight: 700;
  @media (max-width: 337px) {
    font-size: 1.5rem;
  }
  @media (max-width: 279px) {
    font-size: 1.2rem;
  }
`;

export const MainContainer = styled.main`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background-color: ${colors.white300};
  padding: 1rem;
  border-radius: 1.5rem;
`;

export const HeaderContent = styled.header`
  align-items: center;
  border: none;
  border-bottom: 1px solid ${colors.divider};
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 1rem;
  min-height: 4rem;
`;

export const LabelContent = styled.h2`
  color: ${colors.textLight};
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 21px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
`;

export const MainContent = styled.div`
  display: flex;
  flex-basis: 0;
  flex-direction: column;
  flex-grow: 1;
  overflow: auto;
  padding: 0.5rem 0;
  scroll-snap-type: y mandatory;

  ${scrollbar};
`;

export const LoadingContainer = styled.div`
  display: flex;
  flex-grow: 1;
  margin-top: 1rem;
  justify-content: center;
`;

export const Loading = styled(BaseLoading)`
  height: 3.25rem;
  width: 3.25rem;
`;

export const NoCompanyContainer = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: center;
  padding: 1rem;
`;

export const NoCompanyText = styled.p`
  font-size: 1rem;
  font-weight: 400;
  color: ${colors.textLight};
`;

export const CompanyCard = styled(Link)`
  align-items: center;
  border-bottom: 1px solid ${colors.gray100};
  border-radius: ${radii.xs};
  color: ${colors.textLight};
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  min-height: 4rem;
  padding: 0.5rem 1rem;
  scroll-snap-align: start;
  text-decoration: none;

  &:hover {
    background-color: ${colors.backgroundHover};
  }
`;

export const CompanyCardGrid = styled(Link)`
  cursor: pointer;
  text-decoration: none;
  color: ${colors.textLight};
  display: block;
  height: 100%;

  &:hover {
    background-color: ${colors.backgroundHover};
  }
`;

export const CompanyName = styled.h3`
  color: ${colors.textLight};
  font-size: 1.125rem;
  font-weight: 400;
  width: 80%;
  max-width: 80%;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  display: -webkit-box;
`;

export const DropdownButtonContainer = styled.div`
  align-items: center;
  display: inline-flex;
  height: 100%;
  justify-content: flex-end;
  width: 2.75rem;
  max-width: 20%;
`;

export const DropdownItem = styled(Item)`
  font-size: 1rem;

  border-radius: ${radii.sm};
  color: ${colors.text};
  cursor: pointer;
  padding: 0.5rem;
  position: relative;
  user-select: none;

  &[data-highlighted] {
    background-color: ${colors.backgroundHover};
    outline: none;
  }
`;

export const DropdownItemAsLink = styled(Link)`
  display: flex;
  flex-grow: 1;
  text-decoration: none;

  &:visited {
    color: ${colors.text};
  }
`;

export const GridContent = styled.div`
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(3, minmax(0, 1fr));

  @media only screen and (max-width: 480px) {
    grid-template-columns: 1fr;
  }

  @media only screen and (min-width: 480px) and (max-width: 900px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
`;

export const GridItem = styled.div`
  text-align: center;
  width: 100%;
  margin: 0.5rem auto;
  position: relative;
  border-radius: 0.5rem;

  &:hover {
    background-color: ${colors.backgroundHover};
  }
`;

interface IOptionProps {
  readonly color: string;
}

export const Option = styled.button<IOptionProps>`
  padding: 3px;
  margin: auto 0.5rem;
  background: transparent;
  border: none;
  color: ${({ color }) => color};
  border-radius: ${radii.sm};

  &:hover {
    color: ${colors.button};
  }
`;

export const ListIcon = styled(HiOutlineMenu)`
  height: 1.3125rem;
  width: 1.3125rem;
`;

export const GridIcon = styled(HiOutlineViewGrid)`
  height: 1.3125rem;
  width: 1.3125rem;
`;

export const ImageContainer = styled.div`
  align-items: center;
  color: ${colors.textLight};
  display: flex;
  height: 208px;
  width: 100%;
  justify-content: center;
  padding: 0.5rem;
  clear: both;
  border-bottom: 1px solid ${colors.lightGray50};
  margin-bottom: 1rem;
`;

export const ContentCard = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  padding: 0rem 1rem 1rem 1rem;

  position: relative;
  min-height: 4rem;
`;

export const SearchContainer = styled.div`
  padding-bottom: 1rem;
`;

import { type ReactNode } from 'react';

import { Content } from './styles';

export interface IContentPaginationProps {
  children: ReactNode;
  dataCy?: string;
}

export function ContentPagination({
  children,
  dataCy = 'content-modal',
}: IContentPaginationProps): JSX.Element {
  return <Content data-cy={dataCy}>{children}</Content>;
}

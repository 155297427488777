import { type Component, type Editor } from 'grapesjs';
import { useCallback, useEffect, useRef, useState } from 'react';
import { type SubmitHandler, useForm } from 'react-hook-form';

import {
  FormBase,
  FormModal,
  Logger,
  SelectData,
} from '@gbs-monorepo-packages/common';
import { zodResolver } from '@hookform/resolvers/zod';

import { useToast } from '../../../../../hooks/useToast';
import { getAutobuildMapping } from '../../../../../services/courses';
import { type IFormPopoverProps } from '../../../../FormPopover';
import { type AutobuildSchema, autobuildSchema } from './autobuildSchema';
import { ClearButton } from './style';

interface IDataItens {
  key: string;
  value: string;
}

interface IModalUploadImage
  extends Partial<Omit<IFormPopoverProps, 'children'>> {
  editor: Editor | null;
  open: boolean;
  onClose: () => void;
}

export const ModalAutobuildSettings = ({
  editor,
  open,
  onClose,
}: IModalUploadImage): JSX.Element => {
  const [types, setTypes] = useState<IDataItens[]>();
  const [properties, setProperties] = useState<IDataItens[] | null>(null);
  const [autobuildMapping, setAutobuildMapping] =
    useState<Record<string, string>>();

  const [property, setProperty] = useState<string | null>(null);

  const { addToast } = useToast();

  const isSelectOpen = useRef(new Set());
  const handleOpenChange = useCallback((isOpen: boolean, key: string) => {
    if (isOpen) {
      isSelectOpen.current.add(key);
    } else {
      isSelectOpen.current.delete(key);
    }
  }, []);

  const autobuild = useForm<AutobuildSchema>({
    resolver: zodResolver(autobuildSchema),
    defaultValues: {
      type: '',
      property: '',
      order: 0,
    },
  });

  const {
    reset,
    formState: { errors },
    setValue,
    setError,
    watch,
    handleSubmit,
  } = autobuild;

  const selectDataType = useRef(null);
  const selectDataProperty = useRef(null);

  const values = watch();

  const handleTypeSelectChange = (value: string) => {
    setValue('type', value);

    if (autobuildMapping !== undefined && value in autobuildMapping) {
      const subData = autobuildMapping[value];
      const propertiesAux: IDataItens[] = [];

      Object.keys(subData).forEach((key: string) => {
        propertiesAux.push({ key, value: String(key) });
      });

      if (propertiesAux.length === 0) {
        setError('property', {
          type: 'manual',
          message: 'No properties found for this type',
        });
        setProperties(null);
      } else {
        setProperties(propertiesAux);
        setError('property', {
          type: 'manual',
          message: '',
        });
      }
    } else {
      setProperties([]);
    }

    setValue('property', '');
  };

  const handlePropertySelectChange = (value: string) => {
    setValue('property', value);
  };

  const resetForm = useCallback(() => {
    onClose?.();
    setProperty(null);
    reset();
  }, [onClose, reset]);

  const handleDecline = useCallback(() => {
    if (!isSelectOpen.current.size) {
      resetForm();
    }
  }, [resetForm]);

  const onSubmit: SubmitHandler<AutobuildSchema> = (data: AutobuildSchema) => {
    const component = editor?.getSelected();

    if (component !== undefined) {
      component?.setAttributes({
        'data-gjs-autobuild-type': data.type,
        'data-gjs-autobuild-marker': data.property,
        'data-gjs-autobuild-order': data.order,
      });
    } else {
      addToast({
        title: 'Failed to change autobuild settings',
        styleType: 'error',
        dataCy: 'autobuild-settings-error-toast',
        duration: 5000,
      });
    }

    onClose?.();
  };

  const clearAutobuildSettings = useCallback(() => {
    const component = editor?.getSelected();

    if (component !== undefined) {
      setValue('property', '');
      setValue('type', '');
      setValue('order', 0);
      component?.setAttributes({
        'data-gjs-autobuild-type': '',
        'data-gjs-autobuild-marker': '',
        'data-gjs-autobuild-order': '',
      });
      addToast({
        title: 'Success',
        description: 'Auto Build settings cleared for the selected component',
        styleType: 'success',
        dataCy: 'autobuild-settings-success-toast',
        duration: 5000,
      });
    } else {
      addToast({
        title: 'Failed to clear autobuild settings',
        styleType: 'error',
        dataCy: 'autobuild-settings-error-toast',
        duration: 5000,
      });
    }

    onClose?.();
  }, [addToast, editor, onClose]);

  useEffect(() => {
    const component = editor?.getSelected();

    getAutobuildMapping()
      .then((mapping) => {
        const typeAux = [];
        for (const key in mapping.data) {
          if (Object.hasOwnProperty.call(mapping.data, key)) {
            typeAux.push({ key, value: key });
          }
        }

        if (typeAux.length > 0) {
          setTypes(typeAux);
        }
        setAutobuildMapping(mapping.data);

        if (component !== undefined) {
          updatedForm(component);
        }
      })
      .catch((error) => {
        Logger.debug('error: ', error);
      });
  }, [editor?.getSelected(), setValue, open]);

  const updatedForm = useCallback(
    (component: Component) => {
      const type =
        (component.getAttributes()['data-gjs-autobuild-type'] as string) ||
        null;
      const order =
        (component.getAttributes()['data-gjs-autobuild-order'] as string) ||
        null;
      const propertyAux =
        (component.getAttributes()['data-gjs-autobuild-marker'] as string) ||
        null;

      setValue('type', type !== null ? type : '');
      setValue('order', order !== null ? Number(order) : 0);

      if (type !== null && autobuildMapping !== undefined) {
        const subData = autobuildMapping[type];
        if (subData === undefined) return;
        const propertiesAux: IDataItens[] = [];

        Object.keys(subData).forEach((key: string) => {
          propertiesAux.push({ key, value: String(key) });
        });

        setProperties(propertiesAux);
        setProperty(propertyAux);
      } else {
        setProperty('');
      }
    },
    [autobuildMapping, setValue]
  );

  useEffect(() => {
    setValue('property', property !== null ? property : '');
  }, [property]);

  return (
    <FormBase.Provider {...autobuild}>
      <FormModal
        acceptText="Confirm"
        declineText="Cancel"
        open={open}
        dataCy="autobuild-settings"
        mainText=" Auto Build Settings"
        onOpenChange={handleDecline}
        onDecline={handleDecline}
        onAccept={handleSubmit(onSubmit)}
        formId="autobuild-settings"
        zIndex={10}
        biggerModal
      >
        <FormBase.Content>
          <FormBase.InputContent
            filled={!!values.type}
            inputRef="select-type"
            label="Type"
            errorMessage={errors.type?.message}
            dataCy="autobuild-type"
          >
            {types !== undefined && (
              <SelectData
                data={types}
                value={values.type}
                dataCy={values.type}
                onValueChange={handleTypeSelectChange}
                zIndex={10}
                ref={selectDataType}
                onOpenChange={(isOpen) => {
                  handleOpenChange(isOpen, 'type');
                }}
              />
            )}
          </FormBase.InputContent>

          <FormBase.InputContent
            filled={!!values.property}
            inputRef="select-property"
            label="Property"
            errorMessage={errors.property?.message}
            dataCy="autobuild-property"
          >
            <SelectData
              data={properties === null ? [] : properties}
              value={values.property}
              dataCy={values.property}
              onValueChange={handlePropertySelectChange}
              zIndex={10}
              disabled={!values.type || properties === null}
              ref={selectDataProperty}
              onOpenChange={(isOpen) => {
                handleOpenChange(isOpen, 'property');
              }}
            />
          </FormBase.InputContent>

          <FormBase.InputContent
            filled={!!values?.order}
            inputRef="order"
            label="Order"
            errorMessage={errors.order?.message}
            dataCy="autobuild-order"
          >
            <FormBase.InputText
              autoComplete="off"
              dataCy="input-order"
              id="order"
              type="number"
              name="order"
              min={1}
            />
          </FormBase.InputContent>

          {values.property && values.type && values.order > 0 && (
            <ClearButton onClick={clearAutobuildSettings}>
              Clear settings
            </ClearButton>
          )}
        </FormBase.Content>
      </FormModal>
    </FormBase.Provider>
  );
};

import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';
import styled from 'styled-components';

import { colors, radii, shadows } from '@gbs-monorepo-packages/styles';
import * as SelectPrimitive from '@radix-ui/react-select';

import { CheckIcon } from '../BaseCheckbox/styles';

export const Container = styled.div`
  background: ${colors.white300};
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  & > button {
    height: 3.25rem;
  }

  div & {
    border: 1px solid ${colors.inputBorderLight};
  }

  fieldset & {
    border: none;
  }
`;

export const ArrowUpContainer = styled(SelectPrimitive.ScrollUpButton)`
  align-items: center;
  background-color: ${colors.white300};
  color: ${colors.blue300};
  cursor: default;
  display: flex;
  height: 1.625rem;
  justify-content: center;

  &:hover {
    background-color: ${colors.backgroundHover};
  }
`;

export const ArrowUp = styled(MdKeyboardArrowUp)`
  height: 1rem;
  width: 1rem;
`;

export const Viewport = styled(SelectPrimitive.Viewport)`
  padding: 0.315rem;
`;

export const Item = styled(SelectPrimitive.Item)`
  all: unset;
  align-items: center;
  border-radius: ${radii.px};
  color: ${colors.text};
  display: flex;
  font-size: 1rem;
  line-height: 1.1;
  padding: 0.375rem 35px 0.375rem 25px;
  position: relative;
  user-select: none;

  &[data-disabled] {
    color: ${colors.gray200};
    pointer-events: none;
  }

  &[data-highlighted],
  &:hover {
    background-color: rgba(0, 0, 0, 0.08);
  }
`;

export const ItemText = styled(SelectPrimitive.ItemText)``;

export const IndicatorContainer = styled(SelectPrimitive.SelectItemIndicator)`
  align-items: center;
  color: ${colors.blue300};
  display: inline-flex;
  justify-content: center;
  left: 0px;
  position: absolute;
  width: 25px;

  &:active {
    background-color: rgba(0, 0, 0, 0.08);
  }
`;

export const SelectIcon = styled(CheckIcon)`
  height: 1.125rem;
  width: 1.125rem;
`;

export const ArrowDownContainer = styled(SelectPrimitive.ScrollDownButton)`
  align-items: center;
  background-color: ${colors.white300};
  color: ${colors.blue300};
  cursor: default;
  display: flex;
  height: 25px;
  justify-content: center;

  &:hover {
    background-color: ${colors.backgroundHover};
  }
`;

export const ArrowDown = styled(MdKeyboardArrowDown)`
  height: 1rem;
  width: 1rem;
`;

export const Square = styled.div`
  box-shadow: ${shadows.modal};
  width: 15px;
  height: 15px;
  overflow: hidden;
  z-index: 1;
  margin-left: 5px;
  border: 1px solid ${colors.inputBorderLight};
`;

import { MdCheckCircle, MdHistory } from 'react-icons/md';
import styled, { css } from 'styled-components';

import { colors, radii } from '@gbs-monorepo-packages/styles';
import { Root as LabelPrimitiveRoot } from '@radix-ui/react-label';

import { BaseLoading } from '../../../../components/BaseLoading';

export const Label = styled(LabelPrimitiveRoot)`
  background-color: ${colors.white300};
  color: ${colors.labelText};
  font-size: 0.875rem;
  left: 16px;
  max-width: 90%;
  overflow: hidden;
  padding: 1px 6px;
  position: absolute;
  text-overflow: ellipsis;
  top: 50%;
  transform-origin: 0;
  transform: translateY(-50%);
  transition: transform 0.15s ease-in-out, top 0.15s ease-in-out,
    padding 0.15s ease-in-out;
  user-select: none;
  white-space: nowrap;
  z-index: 1;
`;

interface IFieldsetProps {
  readonly filled: boolean;
  readonly isInvalid?: boolean;
}

export const Fieldset = styled.fieldset<IFieldsetProps>`
  all: unset;
  align-items: center;
  background-color: ${colors.white300};
  border-radius: ${radii.px};
  border: 1px solid ${colors.inputBorder};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 0.75rem;
  position: relative;
  transition: box-shadow 0.2s ease-in-out, border-color 0.2s ease-in-out;

  &:focus-within {
    border: 1px solid ${colors.fieldFocus};

    & ${Label} {
      color: ${colors.fieldFocus};
      top: -2px;
      transform: scale(0.88) translateX(-0.5rem) translateY(-50%);
      white-space: normal;
    }
  }

  ${({ filled = false, isInvalid = false }) =>
    filled &&
    (!isInvalid
      ? css`
          border: 1px solid ${colors.fieldFocus};

          & ${Label} {
            color: ${colors.fieldFocus};
            top: -2px;
            transform: scale(0.88) translateX(-0.5rem) translateY(-50%);
            white-space: normal;
          }
        `
      : css`
          border: 1px solid ${colors.red300};

          & ${Label} {
            color: ${colors.red300};
            top: -2px;
            transform: scale(0.88) translateX(-0.5rem) translateY(-50%);
            white-space: normal;
          }
        `)}
`;

export const Input = styled.input`
  -webkit-appearance: none;
  background-color: none;
  border-radius: ${radii.px};
  border: none;
  color: ${colors.text};
  flex-grow: 1;
  font-size: 1rem;
  height: 3.25rem;
  line-height: 1.1;
  outline: none;
  padding: 0 1rem;
  transition: box-shadow 0.2s ease-in-out, border-color 0.2s ease-in-out;
`;

export const Loading = styled(BaseLoading)`
  height: 3.25rem;
  width: 3.25rem;
  margin-top: 1.5rem;
`;

export const LoadingContainer = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: center;
  margin-bottom: 2rem;
`;

export const HistoryVersionItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 2rem;
  max-height: 75vh;
  overflow: auto;
  margin-bottom: 1.5rem;
  padding-right: 0.25rem;

  &::-webkit-scrollbar {
    height: 12px;
    width: 12px;
  }
  &::-webkit-scrollbar-corner {
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background-clip: padding-box;
    background-color: ${colors.scrollbar};
    border: 2px solid transparent;
    // FIXME: replace radius by constants

    border-radius: 10px;
    &:hover {
      background-color: ${colors.scrollbarHover};
    }
  }
`;

export const HistoryVersionItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid ${colors.gray300};
  border-radius: ${radii.sm};
  cursor: pointer;
  margin-bottom: 0.35rem;
`;

export const HistoryIconContainer = styled.div`
  display: flex;
  padding: 0.5rem;
`;

export const HistoryIcon = styled(MdHistory)`
  height: 1.5rem;
  width: 1.5rem;
`;

export const CheckIconContainer = styled.div`
  display: flex;
  padding: 0.5rem;
`;

export const CheckIcon = styled(MdCheckCircle)`
  height: 1.5rem;
  width: 1.5rem;
`;

export const HistoryVersionDateContainer = styled.div`
  padding: 0.5rem;
  width: 100%;
`;

export const HistoryVersionDate = styled.p``;

export const NoHistoryText = styled.p`
  text-align: center;
`;

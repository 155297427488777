import {
  MdAdd,
  MdDeleteOutline,
  MdInsertDriveFile,
  MdOutlineModeEditOutline,
  MdOutlineVisibility,
} from 'react-icons/md';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { colors, radii, scrollbar } from '@gbs-monorepo-packages/styles';
import * as Accordion from '@radix-ui/react-accordion';
import { Item } from '@radix-ui/react-dropdown-menu';
import * as TabsPrimitive from '@radix-ui/react-tabs';

import { BaseLoading } from '../../components/BaseLoading';
export const ContentInputSearch = styled.div`
  flex-grow: 1;
  margin-bottom: 0.75rem;
`;
export const SearchContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-items: center;
  align-items: center;
  gap: 1rem;
`;
export const MainContainer = styled.main`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;
  background-color: ${colors.lightGray50};
`;
export const ButtonContent = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
`;
export const UploadButtonContainer = styled.div`
  align-items: center;
  display: inline-flex;
  height: 100%;
  justify-content: flex-end;
`;
export const Header = styled.header`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 1rem;
`;
export const IconContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin-left: 0.25rem;
`;
export const MainContent = styled.div`
  background-color: ${colors.white300};
  border-radius: 1.5rem;
  display: flex;
  flex-direction: column;
  height: calc(100% - 2rem);
  padding: 1rem;
`;
export const TitlePage = styled.h1`
  font-size: 2rem;
  font-weight: 700;
  @media (max-width: 390px) {
    font-size: 1.8rem;
  }
  @media (max-width: 337px) {
    font-size: 1.5rem;
  }
  @media (max-width: 279px) {
    font-size: 1.2rem;
  }
`;
export const ListContainer = styled.ul`
  width: 100%;
`;
export const TemplateTypeIconContainer = styled.div`
  display: flex;
  align-items: center;
`;
export const TemplateTypeIcon = styled(MdInsertDriveFile)`
  height: 2rem;
  width: 2rem;
`;
export const TemplateNameContainer = styled.span`
  display: flex;
  align-items: center;
  padding: 0 0.3rem 0 0.3rem;
`;
export const TemplateName = styled.p``;
export const LoadingContainer = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: center;
`;
export const Loading = styled(BaseLoading)`
  height: 3.25rem;
  width: 3.25rem;
`;
export const NoTemplatesContainer = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: center;
  width: 100%;
  height: 100%;
`;
export const NoTemplatesText = styled.p`
  font-size: 1rem;
  font-weight: 400;
  color: ${colors.textLight};
`;
export const DropdownButtonContainer = styled.div`
  align-items: center;
  display: inline-flex;
  height: 100%;
  justify-content: flex-end;
  width: 2.75rem;
`;
export const DropdownItem = styled(Item)`
  font-size: 1rem;
  font-weight: 400;
  border-radius: ${radii.sm};
  color: ${colors.labelText};
  cursor: pointer;
  height: 1.5rem;
  line-height: 1.125rem;
  padding-left: 25;
  padding: 5px;
  position: relative;
  user-select: none;
  &[data-highlighted] {
    background-color: ${colors.backgroundHover};
    outline: none;
  }
`;
export const TemplateCard = styled.div`
  align-items: center;
  scroll-snap-align: start;
  border-bottom: 1px solid ${colors.gray100};
  border-radius: ${radii.xs};
  color: ${colors.textLight};
  display: flex;
  justify-content: space-between;
  min-height: 4rem;
  padding: 0.5rem 1rem;
  scroll-snap-align: start;
  text-decoration: none;
  &:hover {
    background-color: ${colors.backgroundHover};
  }
`;
export const Content = styled.div`
  display: flex;
  flex-basis: 0;
  flex-direction: column;
  flex-grow: 1;
  overflow: auto;
  padding: 0.5rem 0;

  ${scrollbar};
`;

export const ActionButtonContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-end;
`;

export const PreviewLink = styled(Link)`
  all: unset;
  align-items: center;
  border-radius: ${radii.full};
  color: ${colors.blue400};
  cursor: pointer;
  display: inline-flex;
  font-family: inherit;
  height: 2.25rem;
  justify-content: center;
  margin: 0.25rem;
  width: 2.25rem;

  &:hover {
    background-color: ${colors.backgroundHover};
  }
`;

interface IActionButtonProps {
  readonly isVisible: boolean;
}

export const ActionButton = styled.button<IActionButtonProps>`
  all: unset;
  align-items: center;
  border-radius: ${radii.full};
  color: ${colors.blue400};
  cursor: pointer;
  display: inline-flex;
  font-family: inherit;
  height: 2.25rem;
  justify-content: center;
  margin: 0.25rem;
  width: 2.25rem;

  &:disabled {
    opacity: 0.2;
  }

  &:hover {
    background-color: ${colors.backgroundHover};
  }

  ${({ isVisible = false }) =>
    !isVisible &&
    css`
      display: none;
    `}
`;

export const PreviewIcon = styled(MdOutlineVisibility)`
  height: 1.5rem;
  width: 1.5rem;
`;

export const DeleteIcon = styled(MdDeleteOutline)`
  height: 1.5rem;
  width: 1.5rem;
`;

export const EditIcon = styled(MdOutlineModeEditOutline)`
  height: 1.75rem;
  width: 1.75rem;
`;

export const AddIcon = styled(MdAdd)`
  height: 1.75rem;
  width: 1.75rem;
`;

export const TabsRoot = styled(TabsPrimitive.Root)`
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-grow: 1;
`;

export const TabsList = styled(TabsPrimitive.List)`
  flex-shrink: 0;
  display: flex;
  border-bottom: 1px solid ${colors.lightGray200};
  margin-bottom: 1rem;
`;

export const TabsTrigger = styled(TabsPrimitive.Trigger)`
  font-family: inherit;
  background-color: ${colors.white300};
  padding: 0 20px;
  height: 45px;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  line-height: 1;
  color: ${colors.labelText};
  user-select: none;
  border: none;

  &:hover {
    color: var(--violet11);
  }

  &:first-child {
    border-top-left-radius: ${radii.sm};
  }
  &:last-child {
    border-top-right-radius: ${radii.sm};
  }

  &[data-state='active'] {
    color: ${colors.labelText};
    font-weight: bold;
    box-shadow: inset 0 -1px 0 0 currentColor, 0 1px 0 0 currentColor;
  }
`;

export const TabsContent = styled(TabsPrimitive.Content)`
  flex-grow: 1;
  padding: 20px;
  background-color: ${colors.white300};
  border-bottom-left-radius: ${radii.sm};
  border-bottom-right-radius: ${radii.sm};
  outline: none;
`;

export const AccordionRoot = styled(Accordion.Root)`
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-grow: 1;
`;

export const AccordionItem = styled(Accordion.Item)`
  border-bottom: 1px solid ${colors.lightGray200};
  margin-bottom: 1rem;
  font-size: 15px;
  line-height: 1;
`;

export const AccordionTrigger = styled(Accordion.Trigger)`
  font-family: inherit;
  background-color: ${colors.white300};
  padding: 0 20px;
  width: 100%;
  height: 45px;

  text-align: left;
  display: flex;

  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  align-content: flex-start;

  font-size: 20px;
  line-height: 1;
  color: ${colors.labelText};
  user-select: none;
  border: none;
`;

export const AccordionGroupLabel = styled.span`
  flex-grow: 1;
`;

export const AccordionGroupActions = styled.span`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
  justify-content: flex-end;
  gap: 0.5rem;
  flex-grow: 1;
`;

export const AccordionContent = styled(Accordion.Content)`
  flex-grow: 1;
  padding: 20px;
  background-color: ${colors.white300};
  border-bottom-left-radius: ${radii.sm};
  border-bottom-right-radius: ${radii.sm};
  outline: none;
  font-size: 15px;
  line-height: 1;
`;

export const GroupListItemCard = styled.div`
  align-items: center;
  scroll-snap-align: start;
  color: ${colors.textLight};
  display: flex;
  justify-content: space-between;
  min-height: 2rem;
  width: 100%;
  scroll-snap-align: start;
  text-decoration: none;
  &:hover {
    background-color: ${colors.backgroundHover};
  }
`;

import { type Editor } from 'grapesjs';

import { type IBlockContent } from '../interfaces';
import ThreeColumnsResponsiveStyle from './styles.css?inline';

export const threeColumnsResponsiveId = 'three-columns-responsive';

const ThreeColumnsResponsivePlugin = (editor: Editor): void => {
  const ThreeColumnsResponsiveContent: IBlockContent = {
    attributes: { class: 'column-gbs-3' },
    content: '',
    editable: true,
    styles: ThreeColumnsResponsiveStyle,
    tagName: 'div',
  };

  const CenterContainer: IBlockContent = {
    components: [
      ThreeColumnsResponsiveContent,
      ThreeColumnsResponsiveContent,
      ThreeColumnsResponsiveContent,
    ],
    editable: true,
    attributes: { class: 'responsive-three-columns' },
    tagName: 'div',
  };

  editor.BlockManager.add(threeColumnsResponsiveId, {
    category: 'Responsive',
    content: CenterContainer,
    label: '3 Columns Responsive',
    media:
      '<svg viewBox="0 0 23 24"> <path fill="currentColor" d="M2 20h4V4H2v16Zm-1 0V4a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1ZM17 20h4V4h-4v16Zm-1 0V4a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1h-4a1 1 0 0 1-1-1ZM9.5 20h4V4h-4v16Zm-1 0V4a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1h-4a1 1 0 0 1-1-1Z"></path> </svg>',
    select: true,
  });
};

export default ThreeColumnsResponsivePlugin;

import {
  type ToastProps as ToastPrimitiveProps,
  type ToastViewportProps as ToastViewportPrimitiveProps,
} from '@radix-ui/react-toast';

import {
  CloseIcon,
  CustomToast,
  Toast,
  ToastClose,
  ToastDescription,
  ToastTitle,
  ToastViewport,
} from './styles';

/*
 * 'defaultValue' and 'value' are omitted, because type 'readonly array' cannot
 * be assigned to the mutable type 'array'. It happens when trying use Immer.
 */
export interface IBaseToastProps
  extends Omit<ToastPrimitiveProps, 'defaultValue' | 'value'> {
  dataCy?: string;
  description?: string;
  styleType?: 'error' | 'info' | 'success' | 'warning';
  title?: string;
}

export function BaseToast({
  dataCy = 'toast',
  description,
  styleType = 'info',
  title,
  ...props
}: IBaseToastProps): JSX.Element {
  return (
    <Toast {...props} data-cy={dataCy} asChild>
      <CustomToast data-cy="toast-custom" styleType={styleType}>
        <ToastTitle data-cy={title}>{title}</ToastTitle>
        <ToastDescription data-cy={description}>{description}</ToastDescription>
        <ToastClose data-cy="toast-close">
          <CloseIcon />
        </ToastClose>
      </CustomToast>
    </Toast>
  );
}
export interface IBaseToastViewportProps extends ToastViewportPrimitiveProps {
  dataCy?: string;
}

export function BaseToastViewport({
  dataCy = 'toast-viewport',
  ...props
}: IBaseToastViewportProps): JSX.Element {
  return <ToastViewport {...props} data-cy={dataCy} />;
}

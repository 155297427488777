import { type FormEvent, type MouseEventHandler } from 'react';

import { FormBase } from '@gbs-monorepo-packages/common';
import * as DialogPrimitive from '@radix-ui/react-dialog';

import { BaseModal, type IBaseModalProps } from '../../components/BaseModal';
import { BaseTooltip } from '../BaseTooltip';
import { Button } from '../Button';
import { ButtonsContainer, Description, TextContent, Title } from './styles';

export interface IFormModalProps extends Partial<IBaseModalProps> {
  acceptText?: string;
  dataCy?: string;
  declineText?: string;
  disabled?: boolean;
  loading?: boolean;
  mainText: string;
  description?: string;
  vhHeight?: string;
  formId?: string;
  acceptButtonTooltipText?: string | null;
  onAccept?: (event: FormEvent<HTMLFormElement>) => Promise<void> | void;
  onDecline?: MouseEventHandler<HTMLButtonElement>;
}

export const FormModal = ({
  acceptText,
  children,
  dataCy = 'form-modal',
  declineText,
  disabled = false,
  loading = false,
  mainText,
  description,
  vhHeight,
  onAccept,
  onDecline,
  acceptButtonTooltipText = null,
  formId,
  ...props
}: IFormModalProps): JSX.Element => {
  return (
    <BaseModal dataCy={dataCy} {...props} vhHeight={vhHeight}>
      <TextContent data-cy="text-container">
        <DialogPrimitive.Title asChild>
          <Title data-cy={mainText}>{mainText}</Title>
        </DialogPrimitive.Title>
        {description && (
          <DialogPrimitive.Title asChild>
            <Description>{description}</Description>
          </DialogPrimitive.Title>
        )}
      </TextContent>
      <FormBase.Root id={formId} onSubmit={onAccept}>
        {children}

        <FormBase.Content>
          <ButtonsContainer>
            {declineText && (
              <Button
                type="button"
                dataCy="button-cancel"
                styleType="outline"
                onClick={onDecline}
              >
                {declineText}
              </Button>
            )}
            {acceptText &&
              (acceptButtonTooltipText ? (
                <BaseTooltip message={acceptButtonTooltipText}>
                  <Button
                    type="submit"
                    dataCy="button-add"
                    loading={loading}
                    disabled={disabled}
                  >
                    {acceptText}
                  </Button>
                </BaseTooltip>
              ) : (
                <Button
                  type="submit"
                  dataCy="button-add"
                  loading={loading}
                  disabled={disabled}
                >
                  {acceptText}
                </Button>
              ))}
          </ButtonsContainer>
        </FormBase.Content>
      </FormBase.Root>
    </BaseModal>
  );
};

import { useCallback, useEffect, useRef, useState } from 'react';

import {
  DialogModal,
  type IDialogModalProps,
} from '../../../../components/DialogModal';
import { useToast } from '../../../../hooks/useToast';
import {
  type ICourseDTO,
  type ICourseHistory,
  type ICourseVersion,
  getCourseHistory,
} from '../../../../services/courses';
import {
  CheckIcon,
  CheckIconContainer,
  HistoryIcon,
  HistoryIconContainer,
  HistoryVersionDate,
  HistoryVersionDateContainer,
  HistoryVersionItem,
  HistoryVersionItemsContainer,
  Loading,
  LoadingContainer,
  NoHistoryText,
} from './styles';

interface ICoursePageModalProps
  extends Partial<
    Omit<IDialogModalProps, 'children' | 'onAccept' | 'onOpenChange'>
  > {
  onAccept: (courseVersion: ICourseVersion) => void;
  onDecline: () => void;
  open: boolean;
  course?: ICourseDTO | null;
  waitToOpen?: boolean;
}

export const RestorePageModal = ({
  onAccept,
  onDecline,
  open,
  course,
  waitToOpen = false,
  ...props
}: ICoursePageModalProps): JSX.Element | null => {
  const { addToast } = useToast();
  const [loadingCourseHistory, setLoadingCourseHistory] = useState(true);
  const loadedHistoryOnce = useRef(false);
  const [selectedHistoryVersion, setSelectedHistoryVersion] =
    useState<ICourseVersion | null>();

  const [courseHistory, setCourseHistory] = useState<ICourseHistory | null>(
    null
  );

  useEffect(() => {
    loadedHistoryOnce.current = false;
  }, [courseHistory]);

  const handleRestoreCourse = useCallback(() => {
    if (!selectedHistoryVersion) {
      return;
    }

    onAccept?.(selectedHistoryVersion);
    setSelectedHistoryVersion(null);
  }, [selectedHistoryVersion, onAccept]);

  const handleDeclineRestoreCourse = useCallback(() => {
    onDecline?.();
    setSelectedHistoryVersion(null);
    loadedHistoryOnce.current = false;
    setCourseHistory(null);
  }, [onDecline]);

  useEffect(() => {
    let mount = true;

    const loadCourseVersionsOnce = async () => {
      loadedHistoryOnce.current = true;
      setLoadingCourseHistory(true);
      try {
        if (!course) {
          addToast({
            title: 'Error loading course restore',
            description:
              'An error occurred. Please try again or contact Edge support.',
            styleType: 'error',
          });
          handleDeclineRestoreCourse();
          return;
        }

        if (course.id > 0) {
          const data: ICourseHistory = await getCourseHistory({
            courseId: course.id,
          });

          // order by createdAt desc
          data.data.sort((a, b) => {
            if (a.createdAt > b.createdAt) {
              return -1;
            }
            if (a.createdAt < b.createdAt) {
              return 1;
            }
            return 0;
          });

          if (mount) {
            setCourseHistory(data);
          }
        }
      } catch (err) {
        loadedHistoryOnce.current = false;
      } finally {
        setLoadingCourseHistory(false);
      }
    };

    if (open && !loadedHistoryOnce.current) {
      void loadCourseVersionsOnce();
    }

    return () => {
      mount = false;
    };
  }, [open, loadedHistoryOnce]);

  return !open ? null : (
    <DialogModal
      acceptText="Restore"
      dataCy="restore-course-dialogModal"
      declineText="Cancel"
      mainText={`${course?.title ?? 'Untitled'} Restore`}
      {...props}
      onAccept={handleRestoreCourse}
      onDecline={handleDeclineRestoreCourse}
      onOpenChange={handleDeclineRestoreCourse}
      open={!waitToOpen}
      disabled={!selectedHistoryVersion}
    >
      {loadingCourseHistory ? (
        <LoadingContainer data-cy="loading-course-restore">
          <Loading />
        </LoadingContainer>
      ) : (
        <HistoryVersionItemsContainer>
          {courseHistory?.data && courseHistory?.data.length > 0 ? (
            courseHistory?.data.map((courseVersion: ICourseVersion) => (
              <HistoryVersionItem
                key={courseVersion.id}
                onClick={() => {
                  setSelectedHistoryVersion(courseVersion);
                }}
              >
                <HistoryIconContainer>
                  <HistoryIcon />
                </HistoryIconContainer>
                <HistoryVersionDateContainer>
                  <HistoryVersionDate>
                    Version from{' '}
                    {new Date(courseVersion.createdAt).toLocaleString()}
                  </HistoryVersionDate>
                </HistoryVersionDateContainer>
                <CheckIconContainer>
                  {selectedHistoryVersion?.id === courseVersion.id ? (
                    <CheckIcon />
                  ) : null}
                </CheckIconContainer>
              </HistoryVersionItem>
            ))
          ) : (
            <NoHistoryText>No course history available.</NoHistoryText>
          )}
        </HistoryVersionItemsContainer>
      )}
    </DialogModal>
  );
};

import styled from 'styled-components';

import { colors, radii } from '@gbs-monorepo-packages/styles';

export const Label = styled.legend`
  font-size: 0.77rem;
  margin-left: 0.5rem;
  padding: 1px 6px;
`;

interface IFieldsetProps {
  readonly hasLabel: boolean;
}

export const Fieldset = styled.fieldset<IFieldsetProps>`
  all: unset;
  align-items: center;
  background-color: ${colors.white300};
  border-radius: ${radii.px};
  display: flex;
  flex-direction: row;
  height: ${({ hasLabel }) => (hasLabel ? '3.78rem' : '3.25rem')};
  justify-content: space-between;
  position: relative;
`;

export const Input = styled.input`
  color: ${colors.text};
  line-height: 1.5;
  font-family: inherit;
  outline: none;
  padding: 0 1rem;
  z-index: 0;

  &::file-selector-button {
    background-color: ${colors.button};
    height: 40px;
    border-radius: ${radii.md};
    color: ${colors.white300};
    font-weight: 700;
    font-size: 0.875rem;
    padding: 0.5rem 1rem;
    transition: all 0.25s;
    border: none;
    border-radius: ${radii.md};
  }

  &::file-selector-button:hover {
    filter: brightness(0.9);
  }
`;

import { type CheckboxProps } from '@radix-ui/react-checkbox';

import { CheckIcon, Checkbox, Indicator } from './styles';

export interface IBaseCheckboxProps extends CheckboxProps {
  id?: string;
  dataCy?: string;
}

export const BaseCheckbox = ({
  dataCy = 'checkbox-field',
  ...props
}: IBaseCheckboxProps): JSX.Element => {
  return (
    <Checkbox {...props} data-cy={dataCy}>
      <Indicator>
        <CheckIcon />
      </Indicator>
    </Checkbox>
  );
};

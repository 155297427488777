import { format } from 'date-fns';
import Highcharts, { type Options, type SeriesBarOptions } from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { produce } from 'immer';
import { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useParams } from 'react-router-dom';

import { CenteredText } from '../../components/CenteredText';
import { ContentPagination } from '../../components/ContentPagination';
import { LegendColorsArray } from '../../constants/Analytics';
import {
  type IAnalyticsReportData,
  type IPaginationAnalyticsByClientDTO,
  getAnalyticsByClient,
} from '../../services/analytics';
import {
  type IApiThrowsError,
  type IPaginationMetaProps,
} from '../../services/api';
import { type ICourseWithAnalyticsDTO } from '../../services/courses';
import Logger from '../../utils/logger';
import {
  AnalyticsBodyContainer,
  AnalyticsCardCustom,
  ChartContainer,
  Header,
  ListContainer,
  Loading,
  LoadingContainer,
  MainContainer,
  MainContent,
  TitlePage,
  TitleSectionContainer,
} from './styles';

export const Analytics = (): JSX.Element => {
  const [loadingAnalytics, setLoadingAnalytics] = useState(false);
  const [courses, setCourses] = useState<ICourseWithAnalyticsDTO[]>([]);
  const { companyId = '' } = useParams();

  const [paginationMeta, setPaginationMeta] =
    useState<IPaginationMetaProps | null>(null);

  const currentDate = new Date();
  const chartMonthsMapTemp = new Map<string, number>();
  const chartMonths = [];

  for (let i = 0; i <= 11; i++) {
    const tFormattedDate = format(
      new Date(currentDate.getFullYear(), currentDate.getMonth() - i, 1),
      'MMM yyyy'
    );
    chartMonths.push(tFormattedDate);
    chartMonthsMapTemp.set(tFormattedDate, 12 - i);
  }
  const formattedDate = format(
    new Date(currentDate.getFullYear() - 1, currentDate.getMonth(), 1),
    'MMM yyyy'
  );
  chartMonths.push(formattedDate);
  chartMonthsMapTemp.set(formattedDate, 0);

  chartMonths.reverse();

  const [chartMonthsMap] = useState<Map<string, number>>(chartMonthsMapTemp);

  const [chartOptions, setChartOptions] = useState<Options>({
    chart: {
      type: 'column',
    },
    colors: LegendColorsArray,
    title: {
      text: 'Courses Total View Count',
    },
    xAxis: {
      categories: chartMonths,
    },
    yAxis: {
      title: {
        text: 'Views',
      },
    },
    plotOptions: {
      series: {
        events: {
          legendItemClick: () => {
            return false;
          },
        },
      },
    },
    series: [],
    credits: {
      enabled: false,
    },
  });

  useEffect(() => {
    let mount = true;
    const syncAnalytics = async () => {
      try {
        setLoadingAnalytics(true);

        const courseAnalyticsData = await getAnalyticsByClient({
          clientId: companyId,
          page: 1,
          limit: 15,
        });

        if (mount) {
          setCourses(courseAnalyticsData.data);
          setPaginationMeta(courseAnalyticsData.meta);
          transformAnalyticsData(courseAnalyticsData.data, true);
        }
      } catch (err) {
        Logger.debug('err: ', err);
      } finally {
        if (mount) {
          setLoadingAnalytics(false);
        }
      }
    };

    void syncAnalytics();

    return () => {
      mount = false;
    };
  }, [companyId]);

  const loadMoreAnalytics = () => {
    if (!loadingAnalytics) {
      setLoadingAnalytics(true);
      const pageAux = Number(
        (paginationMeta?.page ?? 0) > 0 ? paginationMeta?.page : 0
      );

      getAnalyticsByClient({
        clientId: companyId,
        page: pageAux + 1,
        limit: 15,
      })
        .then((response: IPaginationAnalyticsByClientDTO) => {
          setPaginationMeta(response.meta);

          setCourses(
            produce((draft) => {
              draft.push(...response.data);
            })
          );
          transformAnalyticsData(response.data);
        })
        .catch((error: IApiThrowsError) => {
          Logger.debug('error: ', error);
        })
        .finally(() => {
          setLoadingAnalytics(false);
        });
    }
  };

  const transformAnalyticsData = (
    currentCourses: ICourseWithAnalyticsDTO[],
    changingCompany = false
  ) => {
    const currentChartSeries = structuredClone(
      (chartOptions.series ?? []) as SeriesBarOptions[]
    );
    if (changingCompany) {
      hideAllCharts(currentChartSeries);
    }

    currentCourses.forEach((course) => {
      if (!course.analytics || course.analytics?.length === 0) {
        const cData = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

        const chartSeriesNotCreated =
          currentChartSeries.find(
            (chartSeriesOption: SeriesBarOptions) =>
              chartSeriesOption.id === String(course.id)
          ) === undefined;

        if (chartSeriesNotCreated) {
          currentChartSeries.push({
            id: String(course.id),
            name: `${course.title} (${
              course.viewURL ? `/${course.viewURL}` : 'unpublished'
            })`,
            data: cData,
            visible: false,
            showInLegend: false,
          } as SeriesBarOptions);
        }
      }

      course.analytics?.forEach((analytic: IAnalyticsReportData) => {
        const dateMapKey = format(new Date(analytic.date), 'MMM yyyy');
        let foundChartSeriesOption = false;
        const chartMonthsMapIndex = chartMonthsMap.get(dateMapKey) ?? null;
        currentChartSeries.forEach((chartSeriesOption: SeriesBarOptions) => {
          if (
            chartSeriesOption.id === String(course.id) &&
            chartMonthsMapIndex !== null
          ) {
            foundChartSeriesOption = true;
            // @ts-expect-error: Operator '+' cannot be applied to types 'string | number' and 'number'.
            // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
            chartSeriesOption.data[chartMonthsMapIndex] += Number(
              analytic.screenPageViews
            );
          }
        });

        if (!foundChartSeriesOption) {
          const cData = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

          const arrayIndex = chartMonthsMapIndex;
          if (arrayIndex !== undefined && arrayIndex !== null) {
            cData[arrayIndex] = Number(analytic.screenPageViews);
          }

          currentChartSeries.push({
            id: String(course.id),
            name: `${course.title} (${analytic.pagePath})`,
            data: cData,
            visible: false,
            showInLegend: false,
          } as SeriesBarOptions);
        }
      });
    });

    setChartOptions({
      ...chartOptions,
      series: currentChartSeries,
    });
  };

  const toggleCardChart = (course: ICourseWithAnalyticsDTO) => {
    const chartSeriesAux: SeriesBarOptions[] = structuredClone(
      (chartOptions.series ?? []) as SeriesBarOptions[]
    );

    chartSeriesAux.forEach((chartSeriesOption: SeriesBarOptions) => {
      if (chartSeriesOption.id === String(course.id)) {
        chartSeriesOption.visible = !(chartSeriesOption.visible ?? true);
        chartSeriesOption.showInLegend = !(
          chartSeriesOption.showInLegend ?? true
        );
      }
    });

    setChartOptions({
      ...chartOptions,
      series: chartSeriesAux,
    });
  };

  const hideAllCharts = (series: SeriesBarOptions[]) => {
    series.forEach((chartSeriesOption: SeriesBarOptions) => {
      chartSeriesOption.visible = false;
      chartSeriesOption.showInLegend = false;
      chartSeriesOption.data = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    });

    setChartOptions({
      ...chartOptions,
      series,
    });
  };

  useEffect(() => {
    setChartOptions({
      ...chartOptions,
      series: [],
    });
  }, [companyId]);

  return (
    <MainContainer data-cy="page-container">
      <Header>
        <TitleSectionContainer>
          <TitlePage data-cy="title-pageName">Analytics</TitlePage>
        </TitleSectionContainer>
      </Header>

      <MainContent data-cy="courses-card-list">
        <ContentPagination dataCy="content-analytics">
          {loadingAnalytics && chartOptions.series?.length === 0 ? (
            <LoadingContainer data-cy="loading-analytics-container">
              <Loading dataCy="loading-documents" />
            </LoadingContainer>
          ) : (
            <AnalyticsBodyContainer>
              <ChartContainer>
                <HighchartsReact
                  highcharts={Highcharts}
                  options={
                    chartOptions.series?.length === 0
                      ? {
                          ...chartOptions,
                          series: [
                            {
                              id: 'no-courses-found',
                              name: 'No courses found',
                              data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                              visible: true,
                              showInLegend: false,
                            },
                          ],
                        }
                      : chartOptions
                  }
                  containerProps={{ style: { height: '100%' } }}
                />
              </ChartContainer>
              <InfiniteScroll
                height={200}
                style={{
                  overflow: 'auto',
                  maxHeight: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  flexGrow: 1,
                }}
                dataLength={chartOptions.series?.length ?? 0} // This is important field to render the next data
                next={loadMoreAnalytics}
                hasMore={
                  (chartOptions.series?.length ?? 0) <
                  (paginationMeta?.total ?? 0)
                }
                loader={
                  <LoadingContainer data-cy="loading-analytics-container">
                    <Loading />
                  </LoadingContainer>
                }
                endMessage={
                  <CenteredText
                    message={
                      chartOptions.series?.length === 0
                        ? 'No courses found'
                        : 'You have seen it all'
                    }
                    dataCy="pagination-endLimit"
                  />
                }
              >
                <ListContainer
                  type="multiple"
                  data-cy="select-analytics-entries-container"
                >
                  {courses.map((course: ICourseWithAnalyticsDTO) => {
                    const uniqueId = `${course.id}-${Math.random()}`;
                    return (
                      <AnalyticsCardCustom
                        data-cy={course}
                        key={uniqueId}
                        data={course}
                        disabled={loadingAnalytics}
                        onClick={() => {
                          toggleCardChart(course);
                        }}
                      ></AnalyticsCardCustom>
                    );
                  })}
                </ListContainer>
              </InfiniteScroll>
            </AnalyticsBodyContainer>
          )}
        </ContentPagination>
      </MainContent>
    </MainContainer>
  );
};

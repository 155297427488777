import React, { forwardRef } from 'react';

import LoadingSpinnerAnimated from '../../assets/spinner.svg';
import { Loading } from './styles';

export interface IBaseLoadingProps
  extends React.ImgHTMLAttributes<HTMLImageElement> {
  dataCy?: string;
  notLoading?: boolean;
}

export const BaseLoading = forwardRef<HTMLImageElement, IBaseLoadingProps>(
  function BaseLoading(
    { dataCy = 'button', notLoading = false, ...props },
    ref
  ): JSX.Element {
    return (
      <>
        {notLoading || (
          <Loading
            {...props}
            data-cy={dataCy}
            ref={ref}
            src={LoadingSpinnerAnimated}
          />
        )}
      </>
    );
  }
);

import { MdOutlineVisibility, MdOutlineVisibilityOff } from 'react-icons/md';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { colors, radii } from '@gbs-monorepo-packages/styles';
import { Root as LabelPrimitiveRoot } from '@radix-ui/react-label';

export const TextContent = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  font-size: 0.875rem;
  padding: 0 0 1.5rem;
`;

export const Title = styled.h1`
  font-size: 1.125rem;
  margin-bottom: 0.875rem;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
`;

export const Form = styled.form`
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background-color: ${colors.white300};
`;

export const ButtonsContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-end;
  margin-top: 2rem;

  & button + button {
    margin-left: 1rem;
  }
`;

export const Description = styled.p`
  font-size: 0.875rem;
  text-align: center;
`;

export const Label = styled(LabelPrimitiveRoot)`
  background-color: ${colors.white300};
  color: ${colors.labelText};
  font-size: 0.875rem;
  left: 16px;
  max-width: 90%;
  overflow: hidden;
  padding: 1px 6px;
  position: absolute;
  text-overflow: ellipsis;
  top: 50%;
  transform-origin: 0;
  transform: translateY(-50%);
  transition: transform 0.15s ease-in-out, top 0.15s ease-in-out,
    padding 0.15s ease-in-out;
  user-select: none;
  white-space: nowrap;
  z-index: 1;
`;

interface IFieldsetProps {
  readonly filled: boolean;
  readonly isInvalid?: boolean;
}

export const Fieldset = styled.fieldset<IFieldsetProps>`
  all: unset;
  align-items: center;
  background-color: ${colors.white300};
  border-radius: ${radii.px};
  border: 1px solid ${colors.inputBorder};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 0.75rem;
  position: relative;
  transition: box-shadow 0.2s ease-in-out, border-color 0.2s ease-in-out;

  &:focus-within {
    border: 1px solid ${colors.fieldFocus};

    & ${Label} {
      color: ${colors.fieldFocus};
      top: -2px;
      transform: scale(0.88) translateX(-0.5rem) translateY(-50%);
      white-space: normal;
    }
  }

  ${({ filled = false, isInvalid = false }) =>
    filled &&
    (!isInvalid
      ? css`
          border: 1px solid ${colors.fieldFocus};

          & ${Label} {
            color: ${colors.fieldFocus};
            top: -2px;
            transform: scale(0.88) translateX(-0.5rem) translateY(-50%);
            white-space: normal;
          }
        `
      : css`
          border: 1px solid ${colors.red300};

          & ${Label} {
            color: ${colors.red300};
            top: -2px;
            transform: scale(0.88) translateX(-0.5rem) translateY(-50%);
            white-space: normal;
          }
        `)}
`;

export const Input = styled.input`
  -webkit-appearance: none;
  background-color: none;
  border-radius: ${radii.px};
  border: none;
  color: ${colors.text};
  flex-grow: 1;
  font-size: 1rem;
  height: 3.25rem;
  line-height: 1.1;
  outline: none;
  padding: 0 1rem;
  transition: box-shadow 0.2s ease-in-out, border-color 0.2s ease-in-out;
`;

export const ErrorMessage = styled.p`
  font-size: 0.875rem;
  color: ${colors.red300};
`;

export const LockButton = styled.button`
  all: unset;
  align-items: center;
  border-radius: ${radii.px};
  color: ${colors.labelText};
  display: inline-flex;
  font-size: 1rem;
  height: 3.25rem;
  justify-content: center;
  width: 2.75rem;

  &:hover {
    background-color: ${colors.backgroundHover};
  }
`;

export const LockedIcon = styled(MdOutlineVisibility)``;
export const UnLockedIcon = styled(MdOutlineVisibilityOff)``;

export const ForgotLink = styled(Link)`
  align-items: center;
  border-radius: ${radii.px};
  color: ${colors.fieldFocus};
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 1.5;
  padding: 4px;
  text-decoration: none;
`;

export const SubmitButton = styled.button`
  all: unset;
  align-items: center;
  background-color: ${colors.black300};
  border-radius: ${radii.px};
  color: ${colors.white300};
  display: flex;
  font-size: 1rem;
  height: 3.25rem;
  justify-content: center;
  margin-top: 1.5rem;

  &:hover {
    opacity: 0.9;
  }
`;

import {
  type ChangeEvent,
  type MouseEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { type SubmitHandler, useForm } from 'react-hook-form';

import { type IUserDTO, useAuth } from '@gbs-monorepo-packages/auth';
import {
  Button,
  FormBase,
  type IApiThrowsError,
  type IResponseData,
} from '@gbs-monorepo-packages/common';
import { zodResolver } from '@hookform/resolvers/zod';

import { BaseTooltip } from '../../components/BaseTooltip';
import { LazyImage } from '../../components/LazyImage';
import { SelectData } from '../../components/SelectData';
import { UploadField } from '../../components/UploadField';
import { Industries } from '../../constants/Industries';
import { States } from '../../constants/States';
import { useCompanies } from '../../hooks/useCompanies';
import { useToast } from '../../hooks/useToast';
import Logger from '../../utils/logger';
import { type UpdateCompanySchema, updateCompanySchema } from './companySchema';
import {
  ButtonContainer,
  DeleteButton,
  DeleteIcon,
  Header,
  ImageContainer,
  ImageSection,
  Loading,
  LoadingContainer,
  MainContainer,
  MainContent,
  RollbackIcon,
  TitlePage,
} from './styles';

export const CompanySettings = (): JSX.Element => {
  const { selectedCompany, updateCompany, deleteLogoImage } = useCompanies();
  const { addToast } = useToast();
  const { getManagers } = useAuth();
  const [loading, setLoading] = useState(false);
  const [managers, setManagers] = useState<IUserDTO[]>([]);

  const [changeImage, setChangeImage] = useState<File | null>(null);
  const [imageSrc, setImageSrc] = useState('');
  const fileInputRef = useRef<HTMLInputElement>(null);

  const managersData = useMemo(() => {
    return managers.map((manager) => {
      return {
        key: String(manager.id),
        value: manager.firstName + ' ' + manager.lastName,
      };
    });
  }, [managers]);

  useEffect(() => {
    let mount = true;

    const initialLoadManagers = () => {
      getManagers()
        .then((response: IResponseData<IUserDTO[]>) => {
          if (mount) {
            setManagers(response.data);
          }
        })
        .catch((error: IApiThrowsError) => {
          Logger.debug('error: ', error);
        });
    };

    initialLoadManagers();

    return () => {
      mount = false;
    };
  }, []);

  const selectDataConsultantId = useRef(null);
  const selectDataIndustry = useRef(null);
  const selectDataManagerId = useRef(null);
  const selectDataState = useRef(null);

  const companyHasLogo =
    selectedCompany?.clientFile?.path ?? selectedCompany?.logoImage?.path;

  const createClient = useForm<UpdateCompanySchema>({
    resolver: zodResolver(updateCompanySchema),
  });

  const {
    formState: { errors },
    handleSubmit,
    setValue,
    watch,
  } = createClient;

  const values = watch();
  fileInputRef.current && (fileInputRef.current.value = '');
  useEffect(() => {
    setValue('logoImage', changeImage);
  }, [changeImage, setValue]);

  const handleConsultantIdSelectChange = (value: string) => {
    setValue('consultantId', value);
  };

  const handleIndustrySelectChange = (value: string) => {
    setValue('industry', value);
  };

  const handleManagerIdSelectChange = (value: string) => {
    setValue('managerId', value);
  };

  const handleStateSelectChange = (value: string) => {
    setValue('state', value);
  };

  useEffect(() => {
    const asyncForm = () => {
      if (selectedCompany) {
        let urlDocument = '';
        if (selectedCompany.clientFile?.path) {
          urlDocument = selectedCompany.clientFile.path;
        } else if (selectedCompany.logoImage?.path) {
          urlDocument = selectedCompany.logoImage.path;
        }
        setValue(
          'consultantId',
          selectedCompany.consultantId
            ? selectedCompany.consultantId
            : undefined
        );
        setValue('employeeCount', selectedCompany.employeeCount);
        setValue('id', selectedCompany.id);
        setValue('industry', selectedCompany.industry);
        setValue(
          'managerId',
          selectedCompany.managerId ? selectedCompany.managerId : undefined
        );

        setValue('name', selectedCompany.name);
        setValue('state', selectedCompany.state);
        setValue('updatedAt', selectedCompany.updatedAt);
        setValue('websiteUrl', selectedCompany.websiteUrl);
        setImageSrc(urlDocument);
      }
    };
    asyncForm();
  }, [selectedCompany, managers, setValue]);

  const handleCancelUpload = useCallback(() => {
    setChangeImage(null);
    setImageSrc(selectedCompany?.clientFile?.path ?? '');
    fileInputRef.current && (fileInputRef.current.value = '');
  }, [selectedCompany]);

  const handleChangeImage = useCallback(
    ({ target }: ChangeEvent<HTMLInputElement>) => {
      if (target.files?.length === 0) {
        handleCancelUpload();
      }

      const newFile = target.files?.item(0);
      if (newFile) {
        setChangeImage(newFile);
        setImageSrc(URL.createObjectURL(newFile));
      }
    },
    [handleCancelUpload]
  );

  const handleDeleteImage = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();

      if (selectedCompany) {
        setLoading(true);

        deleteLogoImage(selectedCompany.id)
          .then(() => {
            setImageSrc('');
            setLoading(false);
            addToast({
              title: 'Image deleted',
              description: `Logo image successfully deleted.`,
              styleType: 'success',
            });
          })
          .catch((err) => {
            setLoading(false);
            addToast({
              title: 'Error on delete image',
              description: `An error occurred. Please try again or contact Edge support.`,
              styleType: 'error',
            });
            Logger.debug('err on delete logo image: ', err);
          });
      }
    },
    [deleteLogoImage, selectedCompany, addToast]
  );

  const onSubmit: SubmitHandler<UpdateCompanySchema> = (
    data: UpdateCompanySchema
  ) => {
    if (loading) return;
    setLoading(true);
    try {
      void updateCompany(data);
      setChangeImage(null);
      fileInputRef.current && (fileInputRef.current.value = '');
      setLoading(false);
      addToast({
        title: 'Client updated',
        description: `Client information successfully updated`,
        styleType: 'success',
        dataCy: 'company-update-success-toast',
      });
    } catch (err) {
      setLoading(false);
      addToast({
        title: 'Error on updating client',
        description:
          'An error occurred. Please try again or contact Edge support.',
        styleType: 'error',
        dataCy: 'company-update-error-toast',
      });
      Logger.debug('err updated client: ', err);
    }
  };

  return (
    <MainContainer data-cy="page-container">
      <Header>
        <TitlePage data-cy="title-pageName">Client Settings</TitlePage>
      </Header>
      <MainContent data-cy="company-settings-form-container">
        {selectedCompany === null ? (
          <LoadingContainer data-cy="loading-courses-container">
            <Loading dataCy="loading-courses" />
          </LoadingContainer>
        ) : (
          <FormBase.Provider {...createClient}>
            <FormBase.Root
              data-cy="company-settings-form"
              onSubmit={handleSubmit(onSubmit)}
            >
              <FormBase.Content>
                <FormBase.InputContent
                  dataCy="label-Client Name"
                  errorMessage={errors.name?.message}
                  filled={!!values?.name}
                  inputRef="name"
                  label="Client Name"
                >
                  <FormBase.InputText
                    dataCy="input-name"
                    id="name"
                    name="name"
                    type="text"
                    value={values.name}
                  />
                </FormBase.InputContent>

                <ImageSection data-cy="image-section">
                  {companyHasLogo && !changeImage && (
                    <BaseTooltip message="Delete image">
                      <DeleteButton
                        data-cy="button-deleteImage"
                        disabled={loading}
                        onClick={handleDeleteImage}
                      >
                        <DeleteIcon />
                      </DeleteButton>
                    </BaseTooltip>
                  )}
                  <ImageContainer data-cy="client-imageContainer">
                    {changeImage && (
                      <DeleteButton
                        data-cy="button-removeImage"
                        onClick={handleCancelUpload}
                      >
                        <RollbackIcon />
                      </DeleteButton>
                    )}
                    <LazyImage
                      alt="client-image"
                      dataCy="client-image"
                      src={imageSrc}
                    />
                  </ImageContainer>
                  <UploadField
                    accept="image/*"
                    data-cy="input-logo"
                    label="Logo"
                    onChange={handleChangeImage}
                    ref={fileInputRef}
                  />
                </ImageSection>
                <FormBase.InputContent
                  dataCy="label-# of employees"
                  errorMessage={errors.employeeCount?.message}
                  filled={!!values?.employeeCount}
                  inputRef="employees"
                  label="# of employees"
                >
                  <FormBase.InputText
                    dataCy="input-employees"
                    id="employees"
                    max={999999}
                    min={1}
                    name="employeeCount"
                    type="number"
                    value={values.employeeCount}
                  />
                </FormBase.InputContent>
                <FormBase.InputContent
                  dataCy="label-Website URL"
                  errorMessage={errors.websiteUrl?.message}
                  filled={!!values?.websiteUrl}
                  inputRef="websiteUrl"
                  label="Website URL"
                >
                  <FormBase.InputText
                    dataCy="input-website"
                    id="websiteUrl"
                    name="websiteUrl"
                    type="text"
                    value={values.websiteUrl}
                  />
                </FormBase.InputContent>
                <FormBase.InputContent
                  dataCy="label-State"
                  errorMessage={errors.state?.message}
                  filled={!!values.state}
                  inputRef="select-state"
                  label="State"
                >
                  <SelectData
                    data={States}
                    dataCy="select-state"
                    onValueChange={handleStateSelectChange}
                    ref={selectDataState}
                    value={values.state}
                    zIndex={2}
                  />
                </FormBase.InputContent>
                <FormBase.InputContent
                  dataCy="label-Industry"
                  errorMessage={errors.industry?.message}
                  filled={!!values.industry}
                  inputRef="select-state"
                  label="Industry"
                >
                  <SelectData
                    data={Industries}
                    dataCy="select-industry"
                    onValueChange={handleIndustrySelectChange}
                    ref={selectDataIndustry}
                    value={values.industry}
                    zIndex={2}
                  />
                </FormBase.InputContent>
                <FormBase.InputContent
                  filled={!!values.managerId}
                  inputRef="select-state"
                  label="Manager"
                  errorMessage={errors.managerId?.message}
                  dataCy="label-managerId"
                >
                  <SelectData
                    data={managersData}
                    value={values.managerId}
                    dataCy="select-managerId"
                    onValueChange={handleManagerIdSelectChange}
                    zIndex={2}
                    ref={selectDataManagerId}
                  />
                </FormBase.InputContent>
                <FormBase.InputContent
                  filled={!!values.consultantId}
                  inputRef="select-state"
                  label="Consultant/Broker"
                  errorMessage={errors.consultantId?.message}
                  dataCy="label-consultantId"
                >
                  <SelectData
                    data={managersData}
                    value={values.consultantId}
                    dataCy="select-consultantId"
                    onValueChange={handleConsultantIdSelectChange}
                    zIndex={2}
                    ref={selectDataConsultantId}
                  />
                </FormBase.InputContent>
              </FormBase.Content>
              <FormBase.Content>
                <ButtonContainer>
                  <Button
                    dataCy="button-update"
                    type="submit"
                    loading={loading}
                  >
                    Update Client
                  </Button>
                </ButtonContainer>
              </FormBase.Content>
            </FormBase.Root>
          </FormBase.Provider>
        )}
      </MainContent>
    </MainContainer>
  );
};

import { z } from 'zod';

export const userSchema = z.object({
  id: z.string(),
  email: z
    .string()
    .trim()
    .email({ message: 'Invalid email' })
    .min(1, { message: 'Email is required' })
    .max(40, { message: 'Email must be at most 40 characters' }),
  firstName: z
    .string()
    .trim()
    .min(1, { message: 'First name is required' })
    .min(3, {
      message: 'First name must be at least 3 characters',
    })
    .max(30, { message: 'First name must be at most 30 characters' }),
  lastName: z
    .string()
    .trim()
    .min(1, { message: 'Last name is required' })
    .min(2, {
      message: 'Last name must be at least 2 characters',
    })
    .max(30, { message: 'Last name must be at most 30 characters' }),
  permissions: z.string(),
  roles: z.string().min(1, { message: 'Roles is required' }),
  status: z.string(),
  job: z.string(),
  clientId: z.number(),
  currentRole: z.string(),
  managerType: z.string().optional(),
  phone: z
    .string()
    .refine((value) => /^\d+$/.test(value), {
      message: 'Only numbers are allowed',
    })
    .optional(),
});

export const userClientCreateSchema = userSchema.omit({
  id: true,
  currentRole: true,
  job: true,
  status: true,
  permissions: true,
});

export const userCreateSchema = userSchema.omit({
  id: true,
  currentRole: true,
  job: true,
  status: true,
  permissions: true,
});

export const userUpdateSchema = userSchema.pick({
  firstName: true,
  lastName: true,
  email: true,
  managerType: true,
  phone: true,
});

export type Users = z.infer<typeof userSchema>;

export type UsersCreateSchema = Omit<
  Users,
  'currentRole' | 'id' | 'job' | 'permissions' | 'status'
>;

export type UsersUpdateSchema = Pick<
  Users,
  'currentRole' | 'email' | 'firstName' | 'lastName' | 'managerType' | 'phone'
>;

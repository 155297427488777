import { type ReactNode, type RefAttributes } from 'react';

import { type AvatarImageProps } from '@radix-ui/react-avatar';

import { Container, Fallback, Image, NoImage } from './styles';

interface ILazyImageProps
  extends AvatarImageProps,
    RefAttributes<HTMLImageElement> {
  alt?: string;
  children?: ReactNode;
  dataCy?: string;
  delayMs?: number;
}

export function LazyImage({
  alt,
  children,
  dataCy,
  delayMs = 500,
  ...props
}: ILazyImageProps): JSX.Element {
  return (
    <Container>
      <Image {...props} alt={alt} data-cy={dataCy} />
      <Fallback data-cy="image-notFound" delayMs={delayMs}>
        {children ?? <NoImage />}
      </Fallback>
    </Container>
  );
}

import { type ReactNode } from 'react';

import * as Tooltip from '@radix-ui/react-tooltip';

import { TooltipArrow, TooltipContent } from './styles';

export interface IBaseTooltipProps {
  children: ReactNode;
  message?: string;
  dataCy?: string;
  disableHoverableContent?: boolean;
}

export const BaseTooltip = ({
  children,
  message,
  dataCy = 'tooltip-field',
  disableHoverableContent = false,
  ...props
}: IBaseTooltipProps): JSX.Element => {
  return (
    <Tooltip.Provider
      disableHoverableContent={disableHoverableContent}
      {...props}
    >
      <Tooltip.Root delayDuration={300}>
        <Tooltip.Trigger asChild>{children}</Tooltip.Trigger>
        <Tooltip.Portal>
          <TooltipContent sideOffset={5} data-cy={dataCy}>
            {message}
            <TooltipArrow />
          </TooltipContent>
        </Tooltip.Portal>
      </Tooltip.Root>
    </Tooltip.Provider>
  );
};

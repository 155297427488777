import { produce } from 'immer';
import { useCallback, useEffect, useRef, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import { CenteredText } from '../../../../components/CenteredText';
import {
  DialogModal,
  type IDialogModalProps,
} from '../../../../components/DialogModal';
import { useToast } from '../../../../hooks/useToast';
import type {
  IApiThrowsError,
  IPaginationMetaProps,
} from '../../../../services/api';
import {
  type ICoursePageDTO,
  type ICoursePageHistory,
  type IHistoryPage,
  getCoursePageHistory,
} from '../../../../services/coursePages';
import Logger from '../../../../utils/logger';
import {
  CheckIcon,
  CheckIconContainer,
  HistoryIcon,
  HistoryIconContainer,
  HistoryVersionDate,
  HistoryVersionDateContainer,
  HistoryVersionItem,
  HistoryVersionItemsContainer,
  Loading,
  LoadingContainer,
  NoHistoryText,
} from './styles';

interface IRestorePageModalProps
  extends Partial<
    Omit<IDialogModalProps, 'children' | 'onAccept' | 'onOpenChange'>
  > {
  onAccept: (pageHistoryVersion: IHistoryPage) => void;
  onDecline: () => void;
  open: boolean;
  coursePage?: ICoursePageDTO;
  waitToOpen?: boolean;
}

export const RestorePageModal = ({
  onAccept,
  onDecline,
  open,
  coursePage,
  waitToOpen = false,
  ...props
}: IRestorePageModalProps): JSX.Element | null => {
  const { addToast } = useToast();
  const [loadingPageHistory, setLoadingPageHistory] = useState(true);
  const loadedHistoryOnce = useRef(false);
  const [selectedHistoryVersion, setSelectedHistoryVersion] =
    useState<IHistoryPage | null>();
  const [paginationMeta, setPaginationMeta] =
    useState<IPaginationMetaProps | null>(null);

  const [pageHistory, setPageHistory] = useState<IHistoryPage[]>([]);

  useEffect(() => {
    loadedHistoryOnce.current = false;
  }, [pageHistory]);

  const handleRestorePage = useCallback(() => {
    if (!selectedHistoryVersion) {
      return;
    }

    onAccept?.(selectedHistoryVersion);
    setSelectedHistoryVersion(null);
  }, [selectedHistoryVersion, onAccept]);

  const handleDeclineRestorePage = useCallback(() => {
    onDecline?.();
    setSelectedHistoryVersion(null);
    loadedHistoryOnce.current = false;
    setPageHistory([]);
  }, [onDecline]);

  useEffect(() => {
    let mount = true;

    const loadPageHistoryOnce = async () => {
      loadedHistoryOnce.current = true;
      setLoadingPageHistory(true);
      try {
        if (!coursePage) {
          addToast({
            title: 'Error loading page history',
            description:
              'An error occurred. Please try again or contact Edge support.',
            styleType: 'error',
          });
          handleDeclineRestorePage();
          return;
        }

        if (coursePage.id > 0) {
          const history: ICoursePageHistory = await getCoursePageHistory({
            coursePageId: coursePage.id,
            page: 1,
            limit: 15,
          });
          if (mount) {
            setPageHistory(history.data);
            setPaginationMeta(history.meta);
          }
        }
      } catch (err) {
        loadedHistoryOnce.current = false;

        addToast({
          title: 'Error loading page history',
          description:
            'An error occurred. Please try again or contact Edge support.',
          styleType: 'error',
        });
      } finally {
        setLoadingPageHistory(false);
      }
    };

    if (open && !loadedHistoryOnce.current) {
      void loadPageHistoryOnce();
    }

    return () => {
      mount = false;
    };
  }, [addToast, coursePage, handleDeclineRestorePage, open]);

  const searchCoursePageHistory = (searchByButton = false) => {
    if (!loadingPageHistory || searchByButton) {
      const pageAux = searchByButton
        ? 0
        : Number((paginationMeta?.page ?? 0) > 0 ? paginationMeta?.page : 0);
      getCoursePageHistory({
        coursePageId: coursePage?.id ?? 0,
        page: pageAux + 1,
        limit: 15,
      })
        .then((response: ICoursePageHistory) => {
          if (response.data !== null) {
            setPageHistory(
              produce((draft) => {
                draft.push(...response.data);
              })
            );
          }
          setPaginationMeta(response.meta);
        })
        .catch((error: IApiThrowsError) => {
          Logger.debug('error: ', error);
        });
    }
  };

  return !open ? null : (
    <DialogModal
      acceptText="Restore"
      dataCy="restore-course-page-dialogModal"
      declineText="Cancel"
      mainText={`${coursePage?.title ?? 'Page'} History`}
      {...props}
      onAccept={handleRestorePage}
      onDecline={handleDeclineRestorePage}
      onOpenChange={handleDeclineRestorePage}
      open={!waitToOpen}
      disabled={!selectedHistoryVersion}
    >
      {loadingPageHistory ? (
        <LoadingContainer data-cy="loading-page-history">
          <Loading />
        </LoadingContainer>
      ) : (
        <HistoryVersionItemsContainer>
          {pageHistory && pageHistory?.length > 0 ? (
            <InfiniteScroll
              height={400}
              style={{
                overflow: 'auto',
                maxHeight: '100%',
                display: 'flex',
                flexDirection: 'column',
                flexGrow: 1,
              }}
              dataLength={pageHistory?.length} // This is important field to render the next data
              next={searchCoursePageHistory}
              hasMore={pageHistory.length < (paginationMeta?.total ?? 0)}
              loader={
                <LoadingContainer data-cy="loading-teammates-container">
                  <Loading />
                </LoadingContainer>
              }
              endMessage={
                <CenteredText
                  message="You have seen it all"
                  dataCy="pagination-endLimit"
                />
              }
            >
              {pageHistory?.map((historyPage: IHistoryPage) => (
                <HistoryVersionItem
                  key={historyPage.version}
                  onClick={() => {
                    setSelectedHistoryVersion(historyPage);
                  }}
                >
                  <HistoryIconContainer>
                    <HistoryIcon />
                  </HistoryIconContainer>
                  <HistoryVersionDateContainer>
                    <HistoryVersionDate>
                      Version from{' '}
                      {new Date(historyPage.loggedAt).toLocaleString()}
                    </HistoryVersionDate>
                  </HistoryVersionDateContainer>
                  <CheckIconContainer>
                    {selectedHistoryVersion?.version === historyPage.version ? (
                      <CheckIcon />
                    ) : null}
                  </CheckIconContainer>
                </HistoryVersionItem>
              ))}
            </InfiniteScroll>
          ) : (
            <NoHistoryText>No page history available.</NoHistoryText>
          )}
        </HistoryVersionItemsContainer>
      )}
    </DialogModal>
  );
};

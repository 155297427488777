import {
  type ButtonHTMLAttributes,
  type ReactElement,
  type ReactNode,
  forwardRef,
} from 'react';

import { BaseTooltip } from '@gbs-monorepo-packages/common';
import * as DropdownMenuPrimitive from '@radix-ui/react-dropdown-menu';

import {
  DropdownMenuContent,
  OptionsButton,
  OptionsIcon,
  StyledArrow,
} from './styles';

export interface IBaseDropdownProps
  extends DropdownMenuPrimitive.DropdownMenuProps {
  children: ReactNode;
  customTrigger?: ReactElement<HTMLButtonElement> | null;
  zIndex?: number;
  dataCy?: string;
}

export interface IBaseTriggerDropdownProps
  extends ButtonHTMLAttributes<HTMLButtonElement> {}

export const BaseTriggerDropdown = forwardRef<
  HTMLButtonElement,
  IBaseTriggerDropdownProps
>(function BaseTriggerDropdown({ ...props }, ref): JSX.Element {
  return (
    <OptionsButton ref={ref} aria-label="More options" {...props}>
      <OptionsIcon />
    </OptionsButton>
  );
});

export const BaseDropdown = ({
  children,
  customTrigger = null,
  dataCy = 'dropdown-menu-button',
  zIndex,
  ...props
}: IBaseDropdownProps): JSX.Element => {
  return (
    <DropdownMenuPrimitive.Root {...props}>
      <BaseTooltip message="Options">
        <DropdownMenuPrimitive.Trigger asChild data-cy={dataCy}>
          {customTrigger ?? <BaseTriggerDropdown />}
        </DropdownMenuPrimitive.Trigger>
      </BaseTooltip>

      <DropdownMenuPrimitive.Portal>
        <DropdownMenuContent
          $zIndex={zIndex}
          data-cy="dropdown-menu-content"
          sideOffset={5}
        >
          {children}
          <StyledArrow />
        </DropdownMenuContent>
      </DropdownMenuPrimitive.Portal>
    </DropdownMenuPrimitive.Root>
  );
};

import { isAxiosError } from 'axios';
import { type ReactNode, createContext, useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { REDIRECT } from '../constants/RoutePaths';
import * as CompaniesService from '../services/companies';
import { getRouteFrom } from '../utils/getRoutes';
import Logger from '../utils/logger';

export interface ICompaniesContextData {
  addCompany: (company: CompaniesService.IAddCompanyDTO) => Promise<void>;
  deleteCompany: (id: number) => Promise<void>;
  deleteLogoImage: (companyId: number) => Promise<void>;
  refreshClients: boolean;
  selectCompany: (company: CompaniesService.ICompanyDTO | null) => void;
  selectCompanyByID: (
    companyId: string
  ) => Promise<CompaniesService.ICompanyDTO | null>;
  selectedCompany: CompaniesService.ICompanyDTO | null;
  selectedListOptionStyle: string | null;
  selectListOptionStyle: (optionStyle: IListStyleOptions) => void;
  setRefreshClients: (refresh: boolean) => void;
  updateCompany: (data: CompaniesService.IUpdateCompanyDTO) => Promise<void>;
}

interface ICompaniesProps {
  children: ReactNode;
}

interface IListStyleOptions {
  optionStyle: string;
}

export const CompaniesContext = createContext<ICompaniesContextData>(
  {} as ICompaniesContextData
);

const RedirectRoute = getRouteFrom(REDIRECT);

export const CompaniesProvider = ({
  children,
}: ICompaniesProps): JSX.Element => {
  const navigate = useNavigate();

  const [selectedCompany, setSelectedCompany] =
    useState<CompaniesService.ICompanyDTO | null>(null);
  const [selectedListOptionStyle, setSelectedListOptionStyle] =
    useState<string>('list');

  const [refreshClients, setRefreshClients] = useState(false);

  const addCompany = useCallback(
    async (data: CompaniesService.IAddCompanyDTO) => {
      try {
        await CompaniesService.addCompany(data);

        setRefreshClients(true);
      } catch (error) {
        Logger.debug('error: ', error);
      }
    },
    []
  );

  const updateSelectedCompany = useCallback(
    async (data: CompaniesService.IUpdateCompanyDTO) => {
      try {
        const result = await CompaniesService.updateCompany(data);

        data.id === selectedCompany?.id && setSelectedCompany(result);
        setRefreshClients(true);
      } catch (error) {
        Logger.debug('error: ', error);
        throw error;
      }
    },
    [selectedCompany?.id]
  );

  const deleteCompany = useCallback(
    async (id: number) => {
      try {
        await CompaniesService.deleteCompany(id);

        if (id === selectedCompany?.id) {
          setSelectedCompany(null);
          navigate(RedirectRoute);
        }

        setRefreshClients(true);
      } catch (error) {
        Logger.debug('error: ', error);
      }
    },
    [navigate, selectedCompany?.id]
  );

  const selectCompany = useCallback(
    (company: CompaniesService.ICompanyDTO | null) => {
      setSelectedCompany(company);
    },
    []
  );

  const selectListOptionStyle = useCallback(
    (optionStyle: IListStyleOptions) => {
      setSelectedListOptionStyle(optionStyle.optionStyle);
    },
    []
  );

  const selectCompanyByID = useCallback(
    async (companyId: string): Promise<CompaniesService.ICompanyDTO | null> => {
      try {
        const companyIdFromPath = Number(companyId);
        const result = await CompaniesService.getClientById({
          id: companyIdFromPath,
        });

        setSelectedCompany(result);
        return result;
      } catch (error) {
        Logger.debug('error: ', error);
        if (!isAxiosError(error) || error.response?.status !== 403) {
          throw error;
        }
        return null;
      }
    },
    []
  );

  const deleteLogoImageFromSelected = useCallback(async (companyId: number) => {
    try {
      const result = await CompaniesService.deleteLogoImage(companyId);

      setSelectedCompany(result);
    } catch (error) {
      Logger.debug('error: ', error);
    }
  }, []);

  return (
    <CompaniesContext.Provider
      value={{
        addCompany,
        deleteCompany,
        deleteLogoImage: deleteLogoImageFromSelected,
        refreshClients,
        selectCompany,
        selectCompanyByID,
        selectedCompany,
        selectedListOptionStyle,
        selectListOptionStyle,
        setRefreshClients,
        updateCompany: updateSelectedCompany,
      }}
    >
      {children}
    </CompaniesContext.Provider>
  );
};

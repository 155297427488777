import { MdClose } from 'react-icons/md';
import styled, { css, keyframes } from 'styled-components';

import { colors, radii, shadows } from '@gbs-monorepo-packages/styles';
import * as PopoverPrimitive from '@radix-ui/react-popover';

const slideUpAndFade = keyframes`
    0% { opacity: 0; transform: 'translateY(2px)' };
    100% { opacity: 1; transform: 'translateY(0)' };
`;

const slideRightAndFade = keyframes`
    0% { opacity: 0; transform: 'translateX(-2px)' };
    100% { opacity: 1; transform: 'translateX(0)' };
`;

const slideDownAndFade = keyframes`
    0% { opacity: 0; transform: 'translateY(-2px)' };
    100% { opacity: 1; transform: 'translateY(0)' };
`;

const slideLeftAndFade = keyframes`
    0% { opacity: 0; transform: 'translateX(2px)' };
    100% { opacity: 1; transform: 'translateX(0)' };
`;

interface IPopoverContentProps {
  $zIndex?: number;
}

// prettier-ignore
export const PopoverContent = styled(PopoverPrimitive.Content)<IPopoverContentProps>`
  animation-duration: 400ms;
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  background-color: ${colors.white300};
  border-radius: ${radii.xs};
  box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px,
    hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
  color: ${colors.black300};
  font-size: 15px;
  line-height: 1;
  padding: 10px 15px;
  user-select: none;
  will-change: transform, opacity;

  ${({ $zIndex }) =>
    $zIndex !== undefined &&
    css`
      z-index: ${$zIndex};
    `}

  @media (prefers-reduced-motion: no-preference) {
    animation-duration: 400ms;
    animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
    will-change: transform, opacity;
    &[data-state='open'] {
      &[data-side='top'] {
        animation-name: ${slideDownAndFade};
      }

      &[data-side='right'] {
        animation-name: ${slideLeftAndFade};
      }

      &[data-side='bottom'] {
        animation-name: ${slideUpAndFade};
      }

      &[data-side='left'] {
        animation-name: ${slideRightAndFade};
      }
    }
  }
`;

export const CloseButton = styled.button`
  all: unset;
  align-items: center;
  border-radius: ${radii.full};
  color: ${colors.black300};
  display: inline-flex;
  font-family: inherit;
  height: 25px;
  justify-content: center;
  position: absolute;
  right: 5px;
  top: 5px;
  width: 25px;

  &:hover {
    background-color: ${colors.backgroundHover};
  }
  &:focus {
    box-shadow: ${shadows.modal};
  }
`;

export const CloseIcon = styled(MdClose)``;

export const PopoverArrow = styled(PopoverPrimitive.Arrow)`
  fill: ${colors.white300};
`;

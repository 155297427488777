import {
  type IResponseAccountWithCodeDTO,
  type IUserDTO,
} from '@gbs-monorepo-packages/auth';

import Logger from '../utils/logger';
import {
  type IPaginationMetaProps,
  type IPaginationResponseData,
  type IResponseDefaultContent,
  type IThrowsError,
  api,
} from './api';
import { type ICompanyDTO } from './companies';

export type IInviteUserDTO = Pick<
  IUserDTO,
  | 'currentRole'
  | 'email'
  | 'firstName'
  | 'lastName'
  | 'managerType'
  | 'phone'
  | 'roles'
> & {
  clientId: number;
};

export interface IUpdateUserDto extends Omit<IUserDTO, 'status'> {
  password: string;
}
export interface IPaginationUserDTO {
  data: IUserDTO[];
  meta: IPaginationMetaProps;
}
export interface IUserMembershipDto extends IUserDTO {
  client?: ICompanyDTO;
}
export interface IPaginationUserMembershipDTO {
  data: IUserMembershipDto[];
  meta: IPaginationMetaProps;
}
export interface IProfileUpdateDTO {
  email: string;
  firstName: string;
  lastName: string;
}
export interface IChangePasswordDTO {
  email: string;
  password: string;
  currentPassword: string;
}

export type IUpdateMemberDto = Pick<
  Partial<IUserDTO>,
  'firstName' | 'lastName' | 'managerType' | 'phone' | 'roles'
> &
  Pick<IUserDTO, 'email'>;

export const update = async ({
  id,
  firstName,
  lastName,
  password,
  roles = [],
}: IUpdateUserDto): Promise<void> => {
  const response = await api
    .patch<IResponseDefaultContent>('change-account', {
      id,
      firstName,
      lastName,
      password,
      roles,
    })
    .then((resp) => resp.data)
    .catch((err: IThrowsError) => {
      throw err;
    });

  Logger.debug('response: ', response);
};

export const updateMember = async ({
  firstName,
  lastName,
  email,
  roles = [],
  managerType,
  phone,
}: IUpdateMemberDto): Promise<void> => {
  const toUpdate: Partial<IUpdateMemberDto> = {};

  roles.length && (toUpdate.roles = roles);
  firstName && (toUpdate.firstName = firstName);
  lastName && (toUpdate.lastName = lastName);
  managerType && (toUpdate.managerType = managerType);
  phone && (toUpdate.phone = phone);

  const response = await api
    .patch<IResponseDefaultContent>(`change-account/${email}`, toUpdate)
    .then((resp) => resp.data)
    .catch((err: IThrowsError) => {
      throw err;
    });

  Logger.debug('response: ', response);
};

export const deleteMember = async (id: number): Promise<void> => {
  const response = await api
    .delete<IResponseDefaultContent>(`delete-account/${id}`)
    .then((resp) => resp.data)
    .catch((err: IThrowsError) => {
      throw err;
    });

  Logger.debug('response: ', response);
};

export const inviteUser = async ({
  email,
  firstName,
  lastName,
  roles,
  clientId,
  managerType,
  phone,
}: IInviteUserDTO): Promise<IResponseAccountWithCodeDTO> => {
  const toCreate: Partial<IInviteUserDTO> = {};

  email && (toCreate.email = email);
  firstName && (toCreate.firstName = firstName);
  lastName && (toCreate.lastName = lastName);
  roles.length && (toCreate.roles = roles);
  clientId && (toCreate.clientId = clientId);
  managerType && (toCreate.managerType = managerType);
  phone && (toCreate.phone = phone);

  const response = await api
    .post<IResponseAccountWithCodeDTO>(`invite-user/`, toCreate)
    .then((resp) => resp.data)
    .catch((err: IThrowsError) => {
      throw err;
    });

  return response;
};

export const getUsersByCompany = async (
  companyId: string,
  page: number,
  limit: number,
  filter: string
): Promise<IPaginationUserDTO> => {
  const result = await api
    .get<IPaginationResponseData<IUserDTO[]>>(
      `api/users/company/${companyId}?page=${page}&limit=${limit}&filter=${encodeURIComponent(
        filter
      )}`
    )
    .then((resp) => resp.data)
    .catch((err) => {
      throw err;
    });

  return result;
};

export const getUsersByMembership = async (
  page: number,
  limit: number,
  filter: string
): Promise<IPaginationUserMembershipDTO> => {
  const result = await api
    .get<IPaginationResponseData<IUserMembershipDto[]>>(
      `api/users/?page=${page}&limit=${limit}&filter=${encodeURIComponent(
        filter
      )}`
    )
    .then((resp) => resp.data)
    .catch((err) => {
      throw err;
    });

  return result;
};

export const profileUpdate = async ({
  email,
  firstName,
  lastName,
}: IProfileUpdateDTO): Promise<void> => {
  const response = await api
    .post<IResponseDefaultContent>(`change-account/${email}`, {
      firstName,
      lastName,
    })
    .then((resp) => resp.data)
    .catch((err: IThrowsError) => {
      throw err;
    });

  Logger.debug('response: ', response);
};

export const changePassword = async ({
  email,
  password,
  currentPassword,
}: IChangePasswordDTO): Promise<void> => {
  const response = await api
    .post<IResponseDefaultContent>(`change-account/${email}`, {
      password,
      currentPassword,
    })
    .then((resp) => resp.data)
    .catch((err: IThrowsError) => {
      throw err;
    });

  Logger.debug('response: ', response);
};

import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

import {
  ForgotPassword,
  type IPrivateRouteProps as IPrivateAuthRouteProps,
  Login,
  PermissionRedirect,
  PrivateRoute as PrivateAuthRoute,
  ResetPassword,
} from '@gbs-monorepo-packages/auth';
import * as Sentry from '@sentry/react';

import { Roles } from './constants/Roles';
import {
  ALL_MEMBERS,
  ANALYTICS,
  COMPANIES,
  COMPANY,
  COURSES,
  COURSES_TEMPLATE,
  COURSE_VIEWER,
  CREATE_TEMPLATE,
  DASHBOARD,
  DOCUMENTS,
  EDIT_COURSE,
  FORGOT_PASSWORD,
  HOME,
  LOGIN,
  MEMBERS,
  NOT_FOUND,
  PERMISSION_REDIRECT,
  PROFILE,
  REDIRECT,
  REDIRECT_LOGIN,
  RESET_PASSWORD,
  SETTINGS,
  TEMPLATE_VIEW,
} from './constants/RoutePaths';
import { CoursesProvider } from './contexts/course';
import { CoursePagesProvider } from './contexts/coursePage';
import { CompanyLayout } from './layouts/CompanyLayout';
import { DefaultLayout } from './layouts/DefaultLayout';
import { Analytics } from './pages/Analytics';
import { Companies } from './pages/Companies';
import { CompanySettings } from './pages/CompanySettings';
import { Courses } from './pages/Courses';
import { CourseViewer } from './pages/CourseViewer';
import { Dashboard } from './pages/Dashboard';
import { Documents } from './pages/Documents';
import { EditCourse } from './pages/EditCourse';
import { Home } from './pages/Home';
import { Members } from './pages/Members';
import { MembersCompany } from './pages/MembersCompany';
import { ProfileSettings } from './pages/ProfileSettings';
import { Redirect } from './pages/Redirect';
import { RedirectLogin } from './pages/RedirectLogin';
import { TemplateCourses } from './pages/TemplateCourses';
import { TemplatePreview } from './pages/TemplatePreview';
import { getRouteFrom } from './utils/getRoutes';

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

const HighUserRoles = [Roles.ADMIN, Roles.MANAGER];
const NotViewerUsers = [Roles.ADMIN, Roles.MANAGER, Roles.STAFF, Roles.CREW];

export const Router = (): JSX.Element => {
  interface ILocationState {
    from: string;
  }

  const location = useLocation();
  const state = location.state as ILocationState | undefined;

  const fromDashboard = state && !state.from.includes('companies');
  return (
    <SentryRoutes>
      <Route path="/" element={<DefaultLayout />}>
        <Route
          path={DASHBOARD.path}
          element={
            <PrivateRoute roles={HighUserRoles}>
              <Dashboard />
            </PrivateRoute>
          }
        />
        <Route
          path={ALL_MEMBERS.path}
          element={
            <PrivateRoute roles={HighUserRoles}>
              <Members />
            </PrivateRoute>
          }
        />

        <Route path={getRouteFrom(REDIRECT)} element={<Redirect />} />
      </Route>

      <Route
        path="/"
        element={fromDashboard === true ? <DefaultLayout /> : <CompanyLayout />}
      >
        <Route
          path={PROFILE.path}
          element={
            <PrivateRoute>
              <ProfileSettings />
            </PrivateRoute>
          }
        />
      </Route>
      <Route path="/" element={<CompanyLayout />}>
        <Route path={COMPANY.path + '/*'}>
          <Route
            path={COURSES.path}
            element={
              <PrivateRoute>
                <Courses />
              </PrivateRoute>
            }
          />
          <Route
            path={ANALYTICS.path}
            element={
              <PrivateRoute>
                <Analytics />
              </PrivateRoute>
            }
          />
          <Route element={<PrivateRoute roles={NotViewerUsers} />}>
            <Route path={DOCUMENTS.path} element={<Documents />} />
            <Route path={MEMBERS.path} element={<MembersCompany />} />
            <Route path={SETTINGS.path} element={<CompanySettings />} />
            <Route path={COURSES_TEMPLATE.path} element={<TemplateCourses />} />
          </Route>
          <Route path="*" element={<Navigate to={COURSES.path} replace />} />
        </Route>
        <Route
          path={COMPANIES.path}
          element={
            <PrivateRoute roles={HighUserRoles}>
              <Companies />
            </PrivateRoute>
          }
        />
      </Route>

      <Route element={<PrivateRoute roles={NotViewerUsers} />}>
        <Route
          path={getRouteFrom(EDIT_COURSE)}
          element={
            <CoursesProvider>
              <CoursePagesProvider>
                <EditCourse />
              </CoursePagesProvider>
            </CoursesProvider>
          }
        />
        <Route
          path={getRouteFrom(TEMPLATE_VIEW)}
          element={<TemplatePreview />}
        />
      </Route>

      <Route element={<PrivateRoute roles={NotViewerUsers} />}>
        <Route
          path={getRouteFrom(CREATE_TEMPLATE)}
          element={
            <CoursesProvider>
              <CoursePagesProvider>
                <EditCourse />
              </CoursePagesProvider>
            </CoursesProvider>
          }
        />
      </Route>
      <Route
        path={getRouteFrom(FORGOT_PASSWORD)}
        element={<ForgotPassword authorizationRoute={LOGIN} />}
      />
      <Route path={getRouteFrom(COURSE_VIEWER)} element={<CourseViewer />} />
      <Route path={getRouteFrom(LOGIN)} element={<Login />} />
      <Route path={getRouteFrom(REDIRECT_LOGIN)} element={<RedirectLogin />} />
      <Route path={getRouteFrom(RESET_PASSWORD)} element={<ResetPassword />} />
      <Route
        path={getRouteFrom(PERMISSION_REDIRECT)}
        element={<PermissionRedirect />}
      />

      <Route path="/">
        <Route path={HOME.path} element={<Home />} />
      </Route>

      <Route path="*" element={<h1>Page Not Found: 404</h1>} />
    </SentryRoutes>
  );
};

type IPrivateRouteProps = Partial<IPrivateAuthRouteProps>;

const PrivateRoute = ({
  children,
  loginPath = LOGIN,
  permissions,
  redirectPath = NOT_FOUND,
  roles,
}: IPrivateRouteProps): JSX.Element => {
  return (
    <PrivateAuthRoute
      loginPath={loginPath}
      permissions={permissions}
      redirectPath={redirectPath}
      roles={roles}
    >
      {children}
    </PrivateAuthRoute>
  );
};

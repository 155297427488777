import { MdOutlineImageNotSupported } from 'react-icons/md';
import styled from 'styled-components';

import * as AvatarPrimitive from '@radix-ui/react-avatar';

export const Container = styled(AvatarPrimitive.Root)`
  align-items: center;
  border-radius: inherit;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
`;

export const Image = styled(AvatarPrimitive.Image)`
  border-radius: inherit;
  height: 100%;
  object-fit: scale-down;
  width: 100%;
`;

export const Fallback = styled(AvatarPrimitive.Fallback)`
  align-items: center;
  border-radius: inherit;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
`;

export const NoImage = styled(MdOutlineImageNotSupported)`
  height: 3.5rem;
  width: 3.5rem;
`;

import { MdCheckCircle, MdHistory } from 'react-icons/md';
import styled from 'styled-components';

import { colors, radii } from '@gbs-monorepo-packages/styles';

import { BaseLoading } from '../../../../components/BaseLoading';

export const Loading = styled(BaseLoading)`
  height: 3.25rem;
  width: 3.25rem;
  margin-top: 1.5rem;
`;

export const LoadingContainer = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: center;
  margin-bottom: 2rem;
`;

export const HistoryVersionItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 2rem;
  max-height: 75vh;
  overflow: auto;
  margin-bottom: 1.5rem;
  padding-right: 0.25rem;

  &::-webkit-scrollbar {
    height: 12px;
    width: 12px;
  }
  &::-webkit-scrollbar-corner {
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background-clip: padding-box;
    background-color: ${colors.scrollbar};
    border: 2px solid transparent;
    // FIXME: replace radius by constants

    border-radius: 10px;
    &:hover {
      background-color: ${colors.scrollbarHover};
    }
  }
`;

export const HistoryVersionItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid ${colors.gray300};
  border-radius: ${radii.sm};
  cursor: pointer;
  margin-bottom: 0.35rem;
`;

export const HistoryIconContainer = styled.div`
  display: flex;
  padding: 0.5rem;
`;

export const HistoryIcon = styled(MdHistory)`
  height: 1.5rem;
  width: 1.5rem;
`;

export const CheckIconContainer = styled.div`
  display: flex;
  padding: 0.5rem;
`;

export const CheckIcon = styled(MdCheckCircle)`
  height: 1.5rem;
  width: 1.5rem;
`;

export const HistoryVersionDateContainer = styled.div`
  padding: 0.5rem;
  width: 100%;
`;

export const HistoryVersionDate = styled.p``;

export const NoHistoryText = styled.p`
  text-align: center;
`;

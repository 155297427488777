export const Industries = [
  { key: 'Airlines/Aviation', value: 'Airlines/Aviation' },
  {
    key: 'Alternative Dispute Resolution',
    value: 'Alternative Dispute Resolution',
  },
  { key: 'Alternative Medicine', value: 'Alternative Medicine' },
  { key: 'Animation', value: 'Animation' },
  { key: 'Apparel/Fashion', value: 'Apparel/Fashion' },
  { key: 'Architecture/Planning', value: 'Architecture/Planning' },
  { key: 'Arts/Crafts', value: 'Arts/Crafts' },
  { key: 'Automotive', value: 'Automotive' },
  { key: 'Aviation/Aerospace', value: 'Aviation/Aerospace' },
  { key: 'Banking/Mortgage', value: 'Banking/Mortgage' },
  { key: 'Biotechnology/Greentech', value: 'Biotechnology/Greentech' },
  { key: 'Broadcast Media', value: 'Broadcast Media' },
  { key: 'Building Materials', value: 'Building Materials' },
  { key: 'Business Supplies/Equipment', value: 'Business Supplies/Equipment' },
  {
    key: 'Capital Markets/Hedge Fund/Private Equity',
    value: 'Capital Markets/Hedge Fund/Private Equity',
  },
  { key: 'Chemicals', value: 'Chemicals' },
  { key: 'Civic/Social Organization', value: 'Civic/Social Organization' },
  { key: 'Civil Engineering', value: 'Civil Engineering' },
  { key: 'Commercial Real Estate', value: 'Commercial Real Estate' },
  { key: 'Computer Games', value: 'Computer Games' },
  { key: 'Computer Hardware', value: 'Computer Hardware' },
  { key: 'Computer Networking', value: 'Computer Networking' },
  {
    key: 'Computer Software/Engineering',
    value: 'Computer Software/Engineering',
  },
  { key: 'Computer/Network Security', value: 'Computer/Network Security' },
  { key: 'Construction', value: 'Construction' },
  { key: 'Consumer Electronics', value: 'Consumer Electronics' },
  { key: 'Consumer Goods', value: 'Consumer Goods' },
  { key: 'Consumer Services', value: 'Consumer Services' },
  { key: 'Cosmetics', value: 'Cosmetics' },
  { key: 'Dairy', value: 'Dairy' },
  { key: 'Defense/Space', value: 'Defense/Space' },
  { key: 'Design', value: 'Design' },
  { key: 'E-Learning', value: 'E-Learning' },
  { key: 'Education Management', value: 'Education Management' },
  {
    key: 'Electrical/Electronic Manufacturing',
    value: 'Electrical/Electronic Manufacturing',
  },
  {
    key: 'Entertainment/Movie Production',
    value: 'Entertainment/Movie Production',
  },
  { key: 'Environmental Services', value: 'Environmental Services' },
  { key: 'Events Services', value: 'Events Services' },
  { key: 'Executive Office', value: 'Executive Office' },
  { key: 'Facilities Services', value: 'Facilities Services' },
  { key: 'Farming', value: 'Farming' },
  { key: 'Financial Services', value: 'Financial Services' },
  { key: 'Fine Art', value: 'Fine Art' },
  { key: 'Fishery', value: 'Fishery' },
  { key: 'Food Production', value: 'Food Production' },
  { key: 'Food/Beverages', value: 'Food/Beverages' },
  { key: 'Fundraising', value: 'Fundraising' },
  { key: 'Furniture', value: 'Furniture' },
  { key: 'Gambling/Casinos', value: 'Gambling/Casinos' },
  { key: 'Glass/Ceramics/Concrete', value: 'Glass/Ceramics/Concrete' },
  { key: 'Government Administration', value: 'Government Administration' },
  { key: 'Government Relations', value: 'Government Relations' },
  { key: 'Graphic Design/Web Design', value: 'Graphic Design/Web Design' },
  { key: 'Health/Fitness', value: 'Health/Fitness' },
  { key: 'Higher Education/Acadamia', value: 'Higher Education/Acadamia' },
  { key: 'Hospital/Health Care', value: 'Hospital/Health Care' },
  { key: 'Hospitality', value: 'Hospitality' },
  { key: 'Human Resources/HR', value: 'Human Resources/HR' },
  { key: 'Import/Export', value: 'Import/Export' },
  { key: 'Individual/Family Services', value: 'Individual/Family Services' },
  { key: 'Industrial Automation', value: 'Industrial Automation' },
  { key: 'Information Services', value: 'Information Services' },
  { key: 'Information Technology/IT', value: 'Information Technology/IT' },
  { key: 'Insurance', value: 'Insurance' },
  { key: 'International Affairs', value: 'International Affairs' },
  {
    key: 'International Trade/Development',
    value: 'International Trade/Development',
  },
  { key: 'Internet', value: 'Internet' },
  { key: 'Investment Banking/Venture', value: 'Investment Banking/Venture' },
  {
    key: 'Investment Management/Hedge Fund/Private Equity',
    value: 'Investment Management/Hedge Fund/Private Equity',
  },
  { key: 'Judiciary', value: 'Judiciary' },
  { key: 'Law Enforcement', value: 'Law Enforcement' },
  { key: 'Law Practice/Law Firms', value: 'Law Practice/Law Firms' },
  { key: 'Legal Services', value: 'Legal Services' },
  { key: 'Legislative Office', value: 'Legislative Office' },
  { key: 'Leisure/Travel', value: 'Leisure/Travel' },
  { key: 'Library', value: 'Library' },
  { key: 'Logistics/Procurement', value: 'Logistics/Procurement' },
  { key: 'Luxury Goods/Jewelry', value: 'Luxury Goods/Jewelry' },
  { key: 'Machinery', value: 'Machinery' },
  { key: 'Management Consulting', value: 'Management Consulting' },
  { key: 'Maritime', value: 'Maritime' },
  { key: 'Market Research', value: 'Market Research' },
  { key: 'Marketing/Advertising/Sales', value: 'Marketing/Advertising/Sales' },
  {
    key: 'Mechanical or Industrial Engineering',
    value: 'Mechanical or Industrial Engineering',
  },
  { key: 'Media Production', value: 'Media Production' },
  { key: 'Medical Equipment', value: 'Medical Equipment' },
  { key: 'Medical Practice', value: 'Medical Practice' },
  { key: 'Mental Health Care', value: 'Mental Health Care' },
  { key: 'Military Industry', value: 'Military Industry' },
  { key: 'Mining/Metals', value: 'Mining/Metals' },
  { key: 'Motion Pictures/Film', value: 'Motion Pictures/Film' },
  { key: 'Museums/Institutions', value: 'Museums/Institutions' },
  { key: 'Music', value: 'Music' },
  { key: 'Nanotechnology', value: 'Nanotechnology' },
  { key: 'Newspapers/Journalism', value: 'Newspapers/Journalism' },
  { key: 'Non-Profit/Volunteering', value: 'Non-Profit/Volunteering' },
  { key: 'Oil/Energy/Solar/Greentech', value: 'Oil/Energy/Solar/Greentech' },
  { key: 'Online Publishing', value: 'Online Publishing' },
  { key: 'Other Industry', value: 'Other Industry' },
  { key: 'Outsourcing/Offshoring', value: 'Outsourcing/Offshoring' },
  { key: 'Package/Freight Delivery', value: 'Package/Freight Delivery' },
  { key: 'Packaging/Containers', value: 'Packaging/Containers' },
  { key: 'Paper/Forest Products', value: 'Paper/Forest Products' },
  { key: 'Performing Arts', value: 'Performing Arts' },
  { key: 'Pharmaceuticals', value: 'Pharmaceuticals' },
  { key: 'Philanthropy', value: 'Philanthropy' },
  { key: 'Photography', value: 'Photography' },
  { key: 'Plastics', value: 'Plastics' },
  { key: 'Political Organization', value: 'Political Organization' },
  { key: 'Primary/Secondary Education', value: 'Primary/Secondary Education' },
  { key: 'Printing', value: 'Printing' },
  { key: 'Professional Training', value: 'Professional Training' },
  { key: 'Program Development', value: 'Program Development' },
  { key: 'Public Relations/PR', value: 'Public Relations/PR' },
  { key: 'Public Safety', value: 'Public Safety' },
  { key: 'Publishing Industry', value: 'Publishing Industry' },
  { key: 'Railroad Manufacture', value: 'Railroad Manufacture' },
  { key: 'Ranching', value: 'Ranching' },
  { key: 'Real Estate/Mortgage', value: 'Real Estate/Mortgage' },
  {
    key: 'Recreational Facilities/Services',
    value: 'Recreational Facilities/Services',
  },
  { key: 'Religious Institutions', value: 'Religious Institutions' },
  { key: 'Renewables/Environment', value: 'Renewables/Environment' },
  { key: 'Research Industry', value: 'Research Industry' },
  { key: 'Restaurants', value: 'Restaurants' },
  { key: 'Retail Industry', value: 'Retail Industry' },
  { key: 'Security/Investigations', value: 'Security/Investigations' },
  { key: 'Semiconductors', value: 'Semiconductors' },
  { key: 'Shipbuilding', value: 'Shipbuilding' },
  { key: 'Sporting Goods', value: 'Sporting Goods' },
  { key: 'Sports', value: 'Sports' },
  { key: 'Staffing/Recruiting', value: 'Staffing/Recruiting' },
  { key: 'Supermarkets', value: 'Supermarkets' },
  { key: 'Telecommunications', value: 'Telecommunications' },
  { key: 'Textiles', value: 'Textiles' },
  { key: 'Think Tanks', value: 'Think Tanks' },
  { key: 'Tobacco', value: 'Tobacco' },
  { key: 'Translation/Localization', value: 'Translation/Localization' },
  { key: 'Transportation', value: 'Transportation' },
  { key: 'Utilities', value: 'Utilities' },
  { key: 'Venture Capital/VC', value: 'Venture Capital/VC' },
  { key: 'Veterinary', value: 'Veterinary' },
  { key: 'Warehousing', value: 'Warehousing' },
  { key: 'Wholesale', value: 'Wholesale' },
  { key: 'Wine/Spirits', value: 'Wine/Spirits' },
  { key: 'Wireless', value: 'Wireless' },
  { key: 'Writing/Editing', value: 'Writing/Editing' },
];

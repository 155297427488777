import {
  cmdAddNewBulletItem,
  cmdBreakWithNewLine,
  cmdListGoToLeft,
  cmdListGoToRight,
} from '../components/GrapesJS/Blocks/BulletPoint';
import { cmdDeleteOnlyComponent } from '../components/GrapesJS/Customs/DeleteOnlyComponent';
import { cmdOpenSearchComponents } from '../components/GrapesJS/Customs/SearchComponents';
import { cmdSelectChildren } from '../components/GrapesJS/Customs/SelectChildren';

export const translateCommandLabel = new Map([
  [cmdSelectChildren, 'Select children'],
  [cmdDeleteOnlyComponent, 'Delete only component'],
  [cmdOpenSearchComponents, 'Search components'],
  ['core:undo', 'Undo'],
  ['core:redo', 'Redo'],
  ['core:copy', 'Copy'],
  ['core:paste', 'Paste'],
  ['core:component-next', 'Component next'],
  ['core:component-prev', 'Component prev'],
  ['core:component-enter', 'Component enter'],
  ['core:component-exit', 'Component exit'],
  ['core:component-delete', 'Component delete'],
  [cmdAddNewBulletItem, 'Bullet: Create a new list item'],
  [cmdListGoToRight, ' Bullet: Indent to right'],
  [cmdListGoToLeft, 'Bullet: Outdent to left'],
  [cmdBreakWithNewLine, 'Bullet: Break with new line'],
]);

export const smScreens = {
  height: '568px',
  mediaHeight: '768px',
  mediaWidth: '768px', // last-value: 575px
  width: '320px',
} as const;

export const mdScreens = {
  mediaWidth: '768px', // last-value: 768px
  width: '768px',
} as const;

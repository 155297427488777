import { FormBase } from '@gbs-monorepo-packages/common';

import { SelectData } from '../../../../components/SelectData';
import { Input } from './styles';

interface IModalInfoGroupTemplate {
  templateTitle: string;
  setTemplateTitle: (value: string) => void;
  templateOptions: number;
  setTemplateOptions: (value: number) => void;
  isUserAdmin: boolean;
  isUserManager: boolean;
  templatesOptionsFiltered: Array<{
    key: number;
    value: string;
  }>;
  templateOptionsStaff: Array<{
    key: number;
    value: string;
  }>;
  templateOptionsManager: Array<{
    key: number;
    value: string;
  }>;
  isModalUpdate: boolean;
  onOpenChange: (value: boolean) => void;
}

export const ModalInfoGroupTemplate = ({
  templateTitle,
  setTemplateTitle,
  templateOptions,
  setTemplateOptions,
  isUserAdmin,
  isUserManager,
  templatesOptionsFiltered,
  templateOptionsStaff,
  templateOptionsManager,
  onOpenChange,
}: IModalInfoGroupTemplate): JSX.Element => {
  return (
    <FormBase.Content data-cy="formBase-templateName">
      <FormBase.InputContent
        dataCy="label-Course Template Name"
        filled={!!templateTitle}
        label="Course Template Name"
        inputRef="group-name"
      >
        <Input
          data-cy="group-name-input"
          value={templateTitle}
          id="group-name"
          onChange={(e) => {
            if (e.target.value.length <= 50) {
              const value = e.target.value.replace(/\s\s+/g, ' ');
              setTemplateTitle(value);
            }
          }}
          required
          type="text"
        />
      </FormBase.InputContent>
      <FormBase.InputContent
        dataCy="label-Course Template Options"
        filled
        label="Course Template Options"
        inputRef="group-type"
      >
        <SelectData
          data={
            isUserAdmin
              ? templatesOptionsFiltered
              : isUserManager
              ? templateOptionsManager
              : templateOptionsStaff
          }
          onValueChange={(id) => {
            setTemplateOptions(+id);
          }}
          onOpenChange={(isOpen) => {
            onOpenChange(isOpen);
          }}
          zIndex={10}
          value={String(templateOptions)}
        />
      </FormBase.InputContent>
    </FormBase.Content>
  );
};

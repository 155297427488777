import { MdDeleteOutline } from 'react-icons/md';
import styled, { css } from 'styled-components';

import { colors } from '@gbs-monorepo-packages/styles';

import { UploadField } from '../UploadField';

export const Description = styled.p`
  font-size: 0.875rem;
  text-align: left;
  margin-bottom: 15px;
  color: ${colors.gray300};
`;

export const ImageSection = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 0 15px 10px 15px;
`;

interface IImageContainerProps {
  hasImage: boolean;
}

export const ImageContainer = styled.div<IImageContainerProps>`
  align-items: center;
  color: ${colors.textLight};
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 0.5rem;

  ${({ hasImage }) =>
    hasImage &&
    css`
      height: 8rem;
    `}
`;

export const ImageErrorMessage = styled.p`
  font-size: 0.875rem;
  color: ${colors.red300};
`;

export const UploadFieldCustom = styled(UploadField)`
  width: 100%;
`;

export const ImageRemoveLink = styled.a`
  font-size: 0.813rem;
  white-space: nowrap;
  margin: 5px 0;
  color: ${colors.blue400};
  cursor: pointer;
  text-decoration: underline;

  &:hover {
    color: ${colors.blue300};
  }
`;

export const DeleteButton = styled.button`
  background-color: transparent;
  border: none;
`;

export const DeleteIcon = styled(MdDeleteOutline)`
  height: 1.55rem;
  width: 1.55rem;
  margin-right: 5px;
  &:hover {
    color: ${colors.blue300};
  }
`;

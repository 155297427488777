import {
  MdDownload,
  MdInsertDriveFile,
  MdKeyboardArrowDown,
  MdModeEdit,
  MdOutlineDeleteOutline,
  MdOutlineMoreHoriz,
  MdSearch,
} from 'react-icons/md';
import styled from 'styled-components';

import { colors, radii } from '@gbs-monorepo-packages/styles';
import { Item } from '@radix-ui/react-dropdown-menu';

import { BaseLoading } from '../../components/BaseLoading';

export const MainContainer = styled.main`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background-color: ${colors.lightGray50};
`;

export const ArrowDown = styled(MdKeyboardArrowDown)`
  height: 1rem;
  width: 1rem;
`;

export const ButtonContent = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
`;

export const UploadButtonContainer = styled.div`
  align-items: center;
  display: inline-flex;
  height: 100%;
  justify-content: flex-end;
`;

export const Header = styled.header`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const IconContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin-left: 0.25rem;
`;

export const MainContent = styled.div`
  background-color: ${colors.white300};
  border-radius: 1.5rem;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 1rem;
`;

export const TitlePage = styled.h1`
  font-size: 2rem;
  font-weight: 700;
  @media (max-width: 390px) {
    font-size: 1.8rem;
  }
  @media (max-width: 337px) {
    font-size: 1.5rem;
  }
  @media (max-width: 279px) {
    font-size: 1.2rem;
  }
`;

export const SearchInputContainer = styled.div`
  width: 100%;
  display: flex;
  background-color: ${colors.white300};
  height: 2.5rem;
  border: 1px ${colors.lightGray200} solid;

  &:hover {
    border: 1px ${colors.gray300} solid;
  }
`;

export const SearchInput = styled.input`
  width: 100%;
  height: 2.2rem;
  background-color: inherit;
  border: 0;
  border-radius: 0 6px 6px 0;
  padding-left: 0.5rem;

  &:focus {
    outline: none;
  }
`;

export const SearchIconContainer = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: inherit;
  border-radius: ${radii.sm} 0 0 6px;
  padding: 0 0.5rem 0 1rem;
  cursor: pointer;
`;

export const SearchIcon = styled(MdSearch)`
  height: 1.5rem;
  width: 1.5rem;
`;

export const DocumentsList = styled.ul`
  width: 100%;
`;

export const DocumentTypeIconContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const DocumentTypeIcon = styled(MdInsertDriveFile)`
  height: 2rem;
  width: 2rem;
`;

export const DocumentDeleteIconContainer = styled.button`
  all: unset;
  padding: 0.75rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: ${radii.full};

  &:hover {
    background-color: ${colors.white300};
  }
`;

export const DocumentDeleteIcon = styled(MdOutlineDeleteOutline)`
  height: 1.5rem;
  width: 1.5rem;
  color: ${colors.gray300};
`;

export const DocumentDownloadIconContainer = styled.button`
  all: unset;
  padding: 0.75rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: ${radii.full};

  &:hover {
    background-color: ${colors.white300};
  }
`;

export const DocumentDownloadIcon = styled(MdDownload)`
  height: 1.5rem;
  width: 1.5rem;
  color: ${colors.gray300};
`;

export const DocumentRenameIconContainer = styled.button`
  all: unset;
  padding: 0.75rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: ${radii.full};

  &:hover {
    background-color: ${colors.white300};
  }
`;

export const DocumentRenameIcon = styled(MdModeEdit)`
  height: 1.5rem;
  width: 1.5rem;
  color: ${colors.gray300};
`;

export const DocumentNameContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 0 0.3rem 0 0.3rem;
`;

export const DocumentName = styled.p``;

export const RenameInputContainer = styled.div`
  display: flex;
  padding: 0 1rem 2rem;
`;

export const RenameInput = styled.input`
  color: ${colors.text};
  flex-grow: 1;
  font-size: 1rem;
  height: 3.25rem;
  padding: 0 1rem;
  border-radius: ${radii.sm};
  border: 1px ${colors.gray400} solid;

  &:focus {
    outline: none;
  }
`;

export const DocumentMoreIconContainer = styled.div`
  width: 10%;
  padding: 0.75rem;
  display: flex;
  justify-content: center;
  align-items: center;
  // FIXME: replace radius by constants

  border-radius: 30px;

  &:hover {
    background-color: ${colors.white300};
  }
`;

export const DocumentMoreIcon = styled(MdOutlineMoreHoriz)`
  height: 1.5rem;
  width: 1.5rem;
  color: ${colors.gray300};
`;

export const TitleSectionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 1rem;
`;

export const LoadingContainer = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: center;
`;

export const Loading = styled(BaseLoading)`
  height: 3.25rem;
  width: 3.25rem;
`;

export const DropdownButtonContainer = styled.div`
  align-items: center;
  display: inline-flex;
  height: 100%;
  justify-content: flex-end;
  width: 2.75rem;
`;

export const DropdownItem = styled(Item)`
  font-size: 1rem;
  font-weight: 400;

  border-radius: ${radii.sm};
  color: ${colors.labelText};
  cursor: pointer;
  height: 1.5rem;
  line-height: 1.125rem;
  padding-left: 25;
  padding: 5px;
  position: relative;
  user-select: none;

  &[data-highlighted] {
    background-color: ${colors.backgroundHover};
    outline: none;
  }
`;

export const DocumentCard = styled.div`
  align-items: center;
  scroll-snap-align: start;
  border-bottom: 1px solid ${colors.gray100};
  border-radius: ${radii.xs};
  color: ${colors.textLight};
  display: flex;
  justify-content: space-between;
  min-height: 4rem;
  padding: 0.5rem 1rem;
  scroll-snap-align: start;
  text-decoration: none;

  &:hover {
    background-color: ${colors.backgroundHover};
  }
`;

export const SearchContainer = styled.div`
  padding-bottom: 1rem;
`;

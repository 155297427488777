import type { CommandOptions, Component, Editor } from 'grapesjs';

import { type ICommandInfo } from '../components/GrapesJS/interfaces';
import { translateCommandLabel } from '../constants/grapes';
import Logger from './logger';

export const getCanvasContent = (
  { Canvas }: Editor,
  options: { forceFocus: boolean }
): Document | null => {
  let iframeContent = Canvas.getDocument();

  if (
    options.forceFocus &&
    (iframeContent === null || !iframeContent.hasFocus())
  ) {
    const currentDocument = document.getElementsByClassName(
      'gjs-frame'
    ) as HTMLCollectionOf<HTMLElement>;

    currentDocument.item(0)?.focus();

    iframeContent = Canvas.getDocument();
  }

  return iframeContent;
};

export const canvasIsFocussed = (editor: Editor): boolean => {
  const iframeContent = getCanvasContent(editor, { forceFocus: true });

  try {
    if (iframeContent?.hasFocus() ?? false) return true;
  } catch (error) {
    Logger.debug('error: ', ['canvas is not focussed', iframeContent]);
  }
  return false;
};

export const runCommandIfCanvasFocussed = (
  command: string,
  editor: Editor,
  options?: CommandOptions
): void => {
  const { Commands } = editor;
  const isNotRunning = !Commands.isActive(command);

  if (isNotRunning) {
    if (!canvasIsFocussed(editor)) return;
    Commands.run(command, options);
  }
};

export const stopPropagationFirstEvent = (args: any[]): void => {
  args.some((arg) => {
    if (arg instanceof Event) {
      arg.stopPropagation();
      return true;
    }
    return false;
  });
};

const regexToSplitCommands = /\s?,\s?/;

export const getCommandsInfo = (editor: Editor): ICommandInfo[] => {
  const keymaps = editor.Keymaps.getAll();
  const commandsInfo: ICommandInfo[] = [];
  for (const id in keymaps) {
    const keymap = keymaps[id];
    const shortcuts = keymap.keys.split(regexToSplitCommands);
    const label = translateCommandLabel.get(id) ?? keymap.id;
    commandsInfo.push({ ...keymap, shortcuts, label });
  }
  return commandsInfo;
};

export const findInsideComponents = (
  query: string,
  fromComponents: Array<Component | undefined>
): Component[] => {
  const foundComponents: Component[] = [];
  fromComponents.forEach((component) => {
    if (component !== undefined) {
      foundComponents.push(...component.find(query));
    }
  });
  return foundComponents;
};

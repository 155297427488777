import { type KeyboardEventHandler, type ReactNode } from 'react';

import * as Select from '@radix-ui/react-select';

import {
  ArrowDown,
  IconContainer,
  SelectContent,
  SelectTrigger,
} from './styles';

export interface IBaseSelectProps extends Select.SelectProps {
  children: ReactNode;
  className?: string;
  customContentStyle?: React.CSSProperties;
  dataCy?: string;
  defaultValue?: string;
  onKeyPress?: KeyboardEventHandler<HTMLDivElement>;
  placeholder?: string;
  style?: React.CSSProperties;
  zIndex?: number;
}

export const BaseSelect = ({
  children,
  className,
  customContentStyle,
  dataCy = 'select-field',
  onKeyPress,
  placeholder = 'Select…',
  style,
  zIndex,
  ...props
}: IBaseSelectProps): JSX.Element => {
  return (
    <Select.Root {...props}>
      <SelectTrigger
        data-cy={`button-${dataCy}`}
        style={style}
        className={className}
      >
        <Select.Value data-cy={dataCy} placeholder={placeholder} />

        <IconContainer data-cy="icon-container">
          <ArrowDown data-cy="arrow-down" />
        </IconContainer>
      </SelectTrigger>
      <Select.Portal data-cy="select-portal">
        <SelectContent
          style={customContentStyle}
          $zIndex={zIndex}
          data-cy="select-content-container"
          onKeyPress={onKeyPress}
        >
          {children}
        </SelectContent>
      </Select.Portal>
    </Select.Root>
  );
};

import { z } from 'zod';

export const templateSchema = z.object({
  id: z.number(),
  cssContent: z.null(),
  htmlContent: z.string(),
  hasWrapper: z.boolean(),
  title: z
    .string()
    .trim()
    .nonempty({ message: 'Description is required' })
    .min(3, { message: 'Description must be at least 3 characters' })
    .max(50, { message: 'Description must be less than 50 characters long' }),
  createdAt: z.string(),
  updatedAt: z.string(),
  templateOptions: z.string({ required_error: 'Template Option is required' }),
  idExternal: z.null(),
  components: z.null(),
});

export const templateCreateSchema = templateSchema.pick({
  title: true,
  templateOptions: true,
});

export type TemplateSchema = z.infer<typeof templateSchema>;

export type TemplateCreateSchema = z.infer<typeof templateCreateSchema>;

import { useCallback, useEffect, useRef } from 'react';
import { type SubmitHandler, useForm } from 'react-hook-form';

import { FormBase } from '@gbs-monorepo-packages/common';
import { zodResolver } from '@hookform/resolvers/zod';

import {
  FormModal,
  type IFormModalProps,
} from '../../../../components/FormModal';
import { SelectData } from '../../../../components/SelectData';
import {
  TemplatesType,
  templatesTypes,
} from '../../../../constants/TemplatesTypes';
import {
  type TemplateCreateSchema,
  templateCreateSchema,
} from '../../../TemplateCourses/templateSchema';

interface ISavePageAsTemplateModalProps
  extends Partial<
    Omit<IFormModalProps, 'children' | 'onAccept' | 'onOpenChange'>
  > {
  onAccept: (title: string, templateOptions: number) => Promise<void>;
  onDecline: () => void;
  open: boolean;
  waitToOpen?: boolean;
  isUserAdmin?: boolean;
  isUserManager?: boolean;
}

const templatesOptionsFiltered = templatesTypes.filter(
  (i) => i.key !== TemplatesType.BUILD_SCRATCH
);

const templateOptionsStaff = templatesTypes.filter(
  (i) => i.key === TemplatesType.MY_TEMPLATES
);

const templateOptionsManager = templatesTypes.filter(
  (i) =>
    i.key === TemplatesType.MY_TEMPLATES ||
    i.key === TemplatesType.SHARE_TEMPLATES
);

export const SavePageAsTemplateModal = ({
  onAccept,
  onDecline,
  open,
  waitToOpen = false,
  isUserAdmin = true,
  isUserManager = true,
  ...props
}: ISavePageAsTemplateModalProps): JSX.Element | null => {
  const isSelectOpen = useRef(new Set());

  const templateSchema = useForm<TemplateCreateSchema>({
    resolver: zodResolver(templateCreateSchema),
  });

  const {
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    reset,
  } = templateSchema;

  const values = watch();

  useEffect(() => {
    reset();
    if (isUserAdmin) {
      setValue('templateOptions', String(TemplatesType.GLOBAL_TEMPLATES));
    } else {
      setValue('templateOptions', String(TemplatesType.MY_TEMPLATES));
    }
  }, []);

  const handleDeclineSavePageTemplate = useCallback(() => {
    if (!isSelectOpen.current.size) {
      reset();
      onDecline?.();
    }
  }, [onDecline, reset]);

  const handleOpenChange = useCallback((isOpen: boolean, key: string) => {
    if (isOpen) {
      isSelectOpen.current.add(key);
    } else {
      isSelectOpen.current.delete(key);
    }
  }, []);

  const onSubmit: SubmitHandler<TemplateCreateSchema> = async (
    data: TemplateCreateSchema
  ) => {
    await onAccept?.(data.title, Number(data.templateOptions));
  };

  return !open ? null : (
    <FormBase.Provider {...templateSchema}>
      <FormModal
        acceptText="Create Template"
        dataCy="save-course-page-template-dialog-modal"
        declineText="Cancel"
        mainText="New Page Template"
        {...props}
        onAccept={handleSubmit(onSubmit)}
        onDecline={handleDeclineSavePageTemplate}
        onOpenChange={handleDeclineSavePageTemplate}
        open={!waitToOpen}
      >
        <FormBase.Content data-cy="formBase-templateName">
          <FormBase.InputContent
            filled={!!values?.title}
            inputRef="template-name"
            label="Template Name"
            errorMessage={errors.title?.message}
            dataCy="label-Template Name"
          >
            <FormBase.InputText
              dataCy="input-name"
              id="template-name"
              name="title"
              type="text"
              maxLength={50}
              autoComplete="off"
            />
          </FormBase.InputContent>

          <FormBase.InputContent
            filled={!!values.templateOptions}
            inputRef="select-template-options"
            label="Template Options"
            errorMessage={errors.templateOptions?.message}
            dataCy="templateOptions"
          >
            <SelectData
              data={
                isUserAdmin
                  ? templatesOptionsFiltered
                  : isUserManager
                  ? templateOptionsManager
                  : templateOptionsStaff
              }
              zIndex={10}
              value={values.templateOptions}
              dataCy="select-template-options"
              onValueChange={(id) => {
                setValue('templateOptions', id);
              }}
              onOpenChange={(isOpen) => {
                handleOpenChange(isOpen, 'options');
              }}
            />
          </FormBase.InputContent>
        </FormBase.Content>
      </FormModal>
    </FormBase.Provider>
  );
};

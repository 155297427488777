import { MdCheck } from 'react-icons/md';
import styled from 'styled-components';

import { colors, radii } from '@gbs-monorepo-packages/styles';
import * as ToggleGroup from '@radix-ui/react-toggle-group';

export const CheckContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  width: 25px;
  margin-right: 0.375rem;
`;

export const CheckboxFake = styled.div`
  align-items: center;
  background-color: ${colors.white300};
  border-radius: ${radii.px};
  border: 1px solid currentColor;
  cursor: pointer;
  display: flex;
  font-size: 1rem;
  height: 1.25rem;
  justify-content: center;
  width: 1.25rem;

  &:hover {
    filter: brightness(0.9);
  }
`;

export const Indicator = styled.div`
  align-items: center;
  color: ${colors.white300};
  display: flex;
  justify-content: center;
`;

export const CheckIcon = styled(MdCheck)`
  height: 100%;
  width: 100%;
`;

export const Item = styled(ToggleGroup.Item)`
  all: unset;
  align-items: center;
  background-color: ${colors.white300};
  border-radius: ${radii.sm};
  cursor: pointer;
  display: flex;
  padding: 0.5rem 0.375rem;

  &[data-disabled] {
    color: ${colors.gray200};
    pointer-events: none;
    & ${CheckboxFake} {
      background-color: ${colors.lightGray100};
    }
  }

  &[data-state='on'] {
    background-color: ${colors.lightGray100};
    & ${CheckboxFake} {
      background-color: currentColor;
    }
  }
  &[data-state='off'] {
    & ${Indicator} {
      display: none;
    }
  }

  &:hover {
    filter: brightness(0.9);
  }

  &:focus {
    border: 1px solid currentColor;
  }
`;

import { MdDelete, MdDeleteOutline } from 'react-icons/md';
import styled, { css } from 'styled-components';

import { colors, radii, scrollbar } from '@gbs-monorepo-packages/styles';
import * as TabsPrimitive from '@radix-ui/react-tabs';

import { BaseLoading } from '../../../../../components/BaseLoading';
interface IInputProps {
  hidden?: boolean;
  isError?: boolean;
}

interface IFooterContentProps {
  existPreview?: boolean;
}

interface IImageProps {
  isSelected?: boolean;
  isActionRunning?: boolean;
  isGlobal?: boolean;
  isSvg?: boolean;
}

interface IButtonDelete {
  isActionRunning?: boolean;
}

export const MainContainer = styled.main`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

export const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 0.65rem 0.5rem;
  background-color: ${colors.lightGray200};
  border-top-right-radius: ${radii.sm};
  gap: 1rem;

  ${scrollbar};
`;

export const MainContentGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 180px);
  justify-items: center;
  align-items: center;
  gap: 10px;
  border: 1px solid ${colors.lightGray100};
  border-radius: ${radii.sm};
  max-height: 400px;
  overflow: auto;
  background-color: ${colors.background};
  padding: 10px;

  ${scrollbar};
`;

export const ImageContainer = styled.div<IImageProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 170px;
  height: 120px;
  border: 1px solid ${colors.gray100};
  border-radius: ${radii.px};
  padding: 5px;
  overflow: hidden;
  transition: transform 0.3s ease;
  background-color: ${colors.white300};
  transition: all 0.3s ease;

  ${(props) =>
    (props.isSvg ?? false) &&
    css`
      background: ${colors.blue100};
      background: -moz-linear-gradient(
        90deg,
        rgba(255, 255, 255, 1) 0%,
        rgba(109, 197, 234, 0.33049716468618695) 100%
      );
      background: -webkit-linear-gradient(
        90deg,
        rgba(255, 255, 255, 1) 0%,
        rgba(109, 197, 234, 0.33049716468618695) 100%
      );
      background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 1) 0%,
        rgba(109, 197, 234, 0.33049716468618695) 100%
      );
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=${colors.blue100},endColorstr==${colors.white300},GradientType=1);
    `}

  ${(props) =>
    (props.isSelected ?? false) &&
    css`
      border: 1px solid ${colors.blue300};
      transform: scale(1.1);
    `}

  ${(props) =>
    (props.isActionRunning ?? false) &&
    css`
      pointer-events: none;
      opacity: 0.5;
      // blur effect
      filter: blur(2px);
      -webkit-filter: blur(2px);
    `}

  ${(props) =>
    (props.isGlobal ?? false) &&
    css`
      border: 1px solid ${colors.gray300};
    `}

  &:hover {
    cursor: pointer;
  }
`;

export const ImageGrid = styled.img`
  max-width: 100%;
  height: 80px;
  object-fit: contain;
`;

export const ImageName = styled.p`
  margin-top: 5px;
  font-size: 12px;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 80%;
  color: ${colors.blue250};
`;

export const ImageDeleteButton = styled.button<IButtonDelete>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border: none;
  position: absolute;
  background-color: transparent;
  top: 0px;
  right: 0px;
  background-color: transparent;

  &:hover {
    cursor: pointer;
    color: ${colors.blue250};
  }

  ${(props) =>
    (props.isActionRunning ?? false) &&
    css`
      pointer-events: none;
      opacity: 0.5;
      // blur effect
      filter: blur(2px);
      -webkit-filter: blur(2px);
    `}
`;

export const FooterContent = styled.div<IFooterContentProps>`
  display: flex;
  justify-content: end;
  align-items: flex-end;
  margin-top: 0.5rem;
  gap: 0.5rem;
  ${(props) =>
    (props.existPreview ?? false) &&
    css`
      justify-content: space-between;
    `}
`;

export const TabsRoot = styled(TabsPrimitive.Root)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const TabsList = styled(TabsPrimitive.List)`
  flex-shrink: 0;
  display: flex;
  width: fit-content;
`;

export const TabsTrigger = styled(TabsPrimitive.Trigger)`
  font-family: inherit;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: start;
  font-size: 15px;
  line-height: 1;

  user-select: none;
  border: 1px solid ${colors.lightGray100};
  border-bottom: none;
  border-top-left-radius: ${radii.sm};
  border-top-right-radius: ${radii.sm};
  padding: 0.75rem;
  width: 10rem;
  background-color: transparent;
  color: ${colors.blue250};
  font-weight: 600;
  &[data-state='active'] {
    border: 1px solid ${colors.lightGray200};
    background-color: ${colors.lightGray200};
  }
`;

export const TabsContent = styled(TabsPrimitive.Content)`
  flex-grow: 1;
  border-bottom-left-radius: ${radii.sm};
  border-bottom-right-radius: ${radii.sm};
  outline: none;
  margin-bottom: 5px;
`;

export const Input = styled.input<IInputProps>`
  -webkit-appearance: none;
  width: 50%;
  height: 2.35rem;
  border: 1px solid ${colors.gray100};
  border-radius: ${radii.px};
  color: ${colors.text};
  font-size: 1rem;
  line-height: 1.1;
  outline: none;
  padding: 0 1rem;
  transition: box-shadow 0.2s ease-in-out, border-color 0.2s ease-in-out;
  background-color: ${colors.white300};

  ${(props) =>
    (props.hidden ?? false) &&
    css`
      display: none;
    `}
  &::placeholder {
    font: bold;
    opacity: 0.8;
  }

  ${(props) =>
    (props.isError ?? false) &&
    css`
      border: 1px solid ${colors.red300};
    `}
  &::placeholder {
    font: bold;
    opacity: 0.8;
  }
`;

export const ErrorMessage = styled.p`
  font-size: 0.875rem;
  color: ${colors.red300};
`;

export const Form = styled.form`
  width: 100%;
  max-width: 100%;
  text-align: center;
  position: relative;

  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  background-color: ${colors.lightGray200};
  padding: 0.5rem;
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  gap: 0.75rem;
`;

export const ListImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-between;
  overflow-y: auto;
  max-height: 25rem;
  margin-top: 1rem;
`;

interface IPropsContainerListItems {
  isGlobal: boolean;
}

export const ContainerListItems = styled.div<IPropsContainerListItems>`
  all: unset;
  background-color: ${colors.white300};
  border-radius: ${radii.xs};
  color: ${colors.textLight};
  cursor: 'default';
  display: flex;
  min-height: 4.625rem;
  padding: 0.5rem 1rem;
  scroll-snap-align: start;
  text-decoration: none;
  align-items: center;

  &:hover {
    border: 1px solid ${colors.blue250};
  }

  & > div + div {
    margin-left: 1rem;
  }

  ${(props) =>
    (props.isGlobal ?? false) &&
    css`
      background-color: ${colors.lightGray50};
    `}
`;

export const ImageContainerList = styled.div`
  align-items: center;
  color: ${colors.textLight};
  display: flex;
  justify-content: center;
  width: 6rem;
`;

export const PreviewImage = styled.img`
  min-width: 50px;
  height: 50px;
  background: ${colors.blue100};
  background: -moz-linear-gradient(
    90deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(109, 197, 234, 0.33049716468618695) 100%
  );
  background: -webkit-linear-gradient(
    90deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(109, 197, 234, 0.33049716468618695) 100%
  );
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(109, 197, 234, 0.33049716468618695) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=${colors.blue100},endColorstr==${colors.white300},GradientType=1);
`;

export const DescriptionImage = styled.h3`
  color: ${colors.textLight};
  font-size: 1.125rem;
  font-weight: 400;
  width: 80%;
  max-width: 80%;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  display: -webkit-box;
`;

export const TextContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  width: 100%;
`;

export const ActionButton = styled.button`
  all: unset;
  align-items: center;
  border-radius: ${radii.full};
  color: ${colors.blue400};
  cursor: pointer;
  display: inline-flex;
  font-family: inherit;
  height: 2.5rem;
  justify-content: center;
  margin: 0.25rem;
  width: 2.5rem;

  &:hover {
    background-color: ${colors.white300};
    filter: brightness(0.9);
  }
`;

export const DeleteIconLibrary = styled(MdDelete)`
  width: 1rem;
  height: 1rem;
`;

export const DeleteIcon = styled(MdDeleteOutline)`
  height: 1.75rem;
  width: 1.75rem;
`;

export const Label = styled.label`
  height: 2.25rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: 2px dashed ${colors.blue250};
  color: ${colors.blue250};
  font-weight: bold;
  flex: 1;
`;

export const ButtonDrop = styled.button`
  cursor: pointer;
  padding: 0.25rem;
  font-size: 1rem;
  border: none;
  font-family: 'Oswald', sans-serif;
  background-color: transparent;
  color: ${colors.blue250};
  font-weight: bold;
  &:hover {
    text-decoration-line: underline;
  }
`;

export const Separator = styled.span`
  width: 2px;
  height: 3rem;
  background-color: ${colors.blue250};
`;

export const DraggableSpace = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: ${radii.lg};
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  background-color: ${colors.blue250};
  opacity: 0.1;
  align-items: center;
`;

export const LoadingContainer = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: center;
`;

export const Loading = styled(BaseLoading)`
  height: 3.25rem;
  width: 3.25rem;
`;

export const SectionPreviewImage = styled.section`
  display: flex;
  max-width: 400px;
  height: 70px;
  gap: 1rem;
  flex-wrap: nowrap;
  overflow-x: auto;
`;

export const SimpleContainer = styled.div`
  width: 55%;
  align-items: center;
  display: flex;
  justify-content: end;
  gap: 0.5rem;
`;

export const CheckboxContainer = styled.div`
  align-items: center;
  display: flex;
`;

export const CheckboxLabel = styled.label`
  margin: 0 0.5rem;
  color: ${colors.blue400};
`;

export const SectionLabel = styled.h1`
  margin: 0 0.5rem;
  color: var(--gbs-color-blue-400);
  cursor: pointer;
`;

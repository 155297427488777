import React, { type ReactNode, memo } from 'react';
import { Draggable } from 'react-beautiful-dnd';

import { Slot } from '@radix-ui/react-slot';

export interface ICardProps extends React.HTMLAttributes<HTMLElement> {
  children: ReactNode;
  draggableId: number;
  index: number;
  refViewPort?: React.RefObject<HTMLDivElement>;
  dataCy?: string;
}

export const Card = memo(function Card({
  draggableId,
  index,
  refViewPort,
  dataCy = 'draggable-card',
  ...props
}: ICardProps) {
  return (
    <Draggable draggableId={`${draggableId}`} index={index}>
      {(provided, snapshot) => {
        if (snapshot.isDragging) {
          const draggableStyle = provided.draggableProps.style;

          if (
            draggableStyle &&
            refViewPort?.current &&
            'left' in draggableStyle && // only to fix type
            'top' in draggableStyle // only to fix type
          ) {
            const { x, y } = refViewPort.current.getBoundingClientRect();
            draggableStyle.left -= x; // This will fix offset
            draggableStyle.top -= y; // This will fix offset
          }
        }
        return (
          <Slot
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            {...props}
            data-cy={dataCy}
          />
        );
      }}
    </Draggable>
  );
});

import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import {
  type IResponseAccountWithCodeDTO,
  useAuth,
} from '@gbs-monorepo-packages/auth';
import { Logger, getRouteFrom } from '@gbs-monorepo-packages/common';

import { EDIT_COURSE } from '../../constants/RoutePaths';
import { redirectDashboard } from '../../services/dashboard';
import { Container, Loading } from './styles';

export const RedirectLogin = (): JSX.Element => {
  const { saveUser } = useAuth();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    const redirPage = searchParams.get('redirTo');
    const companyId = searchParams.get('company');
    const courseId = searchParams.get('course');

    redirectDashboard()
      .then((response: IResponseAccountWithCodeDTO) => {
        switch (redirPage) {
          case 'course':
            if (companyId === null || courseId === null) {
              saveUser(response.data);
            } else {
              saveUser(response.data, false);

              navigate(
                getRouteFrom(EDIT_COURSE)
                  .replace(':companyId', companyId)
                  .replace(':courseId', courseId)
              );
            }
            break;

          default:
            saveUser(response.data);
            break;
        }
      })
      .catch((error) => {
        Logger.debug('error: ', error);
      });
  }, []);

  return (
    <Container data-cy="loading-container">
      <Loading />
    </Container>
  );
};

var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};

// src/index.ts
import { createGlobalStyle } from "styled-components";

// src/constants/colors.ts
var Colors = {
  black300: "#111213",
  blackTranslucent250: "rgba(0, 0, 0, 0.250)",
  blackTranslucent400: "rgba(0, 0, 0, 0.439)",
  blue50: "#b9d8ea",
  blue100: "#6dc5ea",
  blue200: "#3eb4e4",
  blue250: "#007bad",
  blue275: "#2F80ED",
  blue300: "#145091",
  blue400: "#00426b",
  blue500: "#2f80ed",
  blue600: "#436D9B",
  blueTranslucent200: "rgba(62, 180, 228, 0.15)",
  gray100: "#d2dce7",
  gray200: "#c4c4c4",
  gray250: "#8a9099",
  gray300: "#6f7780",
  gray350: "#3f434a",
  gray400: "#414042",
  gray500: "#25282d",
  green150: "#8ec64d",
  green200: "#49c96d",
  green300: "#20a144",
  green400: "#0a8852",
  greenTranslucent200: "rgba(73, 201, 109, 0.15)",
  lightGray50: "#f8f8f8",
  lightGray100: "#ededed",
  lightGray200: "#d9d9d9",
  lightGray300: "#c6c9cc",
  orange400: "#df5a29",
  orange500: "#c44829",
  orange600: "#833421",
  orangeTranslucent400: "rgba(223, 90, 41, 0.15)",
  red200: "#e14d4e",
  red300: "#f00",
  white300: "#fff",
  whiteTranslucent100: "rgba(255, 255, 255, 0.100)",
  yellow200: "#ffdb56"
};
var BackgroundColor = {
  backgroundHoverLight: Colors.whiteTranslucent100,
  backgroundHover: "#25282d0a",
  background: "#f5f7fa",
  backgroundDarker: Colors.lightGray50,
  messageBackgroundMe: "#e8ebfa",
  messageBackgroundClient: "#f5f5f5",
  messageBackgroundAdmin: "#d8d8f0"
};
var ExtraColors = {
  authPagesBackground: BackgroundColor.background,
  authPagesFieldBorder: "#4aaff9",
  authPagesLink: "#304ffd",
  button: Colors.blue400,
  buttonShadow: Colors.blackTranslucent400,
  divider: "rgba(0, 0, 0, 0.1)",
  dropzoneBackground: Colors.lightGray50,
  dropzoneBorder: Colors.lightGray300,
  dropzoneBorderError: Colors.red300,
  dropzoneBorderFocus: Colors.blue250,
  dropzoneBorderSuccess: Colors.green200,
  dropzoneText: Colors.gray350,
  headerText: Colors.gray350,
  fieldFocus: Colors.blue250,
  inputBorder: Colors.gray400,
  inputBorderLight: Colors.gray200,
  inputPlaceholder: Colors.gray200,
  labelText: Colors.gray300,
  sidebarBackground: Colors.white300,
  sidebarBackgroundActive: Colors.lightGray50,
  sidebarText: Colors.gray350,
  sidebarTextLight: Colors.gray250,
  statusError: Colors.orangeTranslucent400,
  statusTextError: Colors.orange500,
  statusInfo: Colors.blueTranslucent200,
  statusTextInfo: Colors.blue300,
  statusSuccess: Colors.greenTranslucent200,
  statusTextSuccess: Colors.green300,
  statusWarning: Colors.blueTranslucent200,
  statusTextWarning: Colors.orange500,
  text: Colors.gray350,
  textLight: Colors.gray250,
  toastError: Colors.orange500,
  toastTextError: Colors.white300,
  toastInfo: Colors.blue400,
  toastTextInfo: Colors.blue400,
  toastSuccess: Colors.green400,
  toastTextSuccess: Colors.white300,
  toastWarning: Colors.orange500,
  toastTextWarning: Colors.orange500,
  scrollbar: "#6665",
  scrollbarHover: "#9998",
  statusTextComplete: "#13dc28",
  statusTextInProgress: "#DC7413",
  statusTextLow: "#13dc28",
  statusTextMedium: "#F9C400",
  statusTextHigh: "#DC1313"
};
var colors = __spreadValues(__spreadValues(__spreadValues({}, Colors), BackgroundColor), ExtraColors);

// src/constants/animations.ts
import { css } from "styled-components";
var underlineAnimation = css`
  position: relative;

  &::after {
    content: '';
    background-color: currentColor;
    bottom: 0;
    height: 1px;
    left: 0;
    position: absolute;
    transform-origin: bottom right;
    transform: scaleX(0);
    transition: transform 0.25s ease-out;
    width: 100%;
  }

  &:hover::after {
    transform-origin: bottom left;
    transform: scaleX(1);
  }
`;

// src/constants/fonts.ts
var fonts = {
  default: "Montserrat, Roboto, sans-serif",
  admin: "Poppins, Montserrat, sans-serif"
};

// src/constants/radii.ts
var radii = {
  px: "3px",
  xs: "4px",
  sm: "6px",
  md: "8px",
  lg: "14px",
  xl: "20px",
  full: "99999px"
};

// src/constants/scrollbar.ts
import { css as css2 } from "styled-components";
var scrollbar = css2`
  &::-webkit-scrollbar {
    height: 12px;
    width: 12px;
  }
  &::-webkit-scrollbar-corner {
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background-clip: padding-box;
    background-color: ${colors.scrollbar};
    border: 2px solid transparent;
    border-radius: ${radii.lg};
    min-height: 45px;
    &:hover {
      background-color: ${colors.scrollbarHover};
    }
  }
  &:not(:hover) {
    &::-webkit-scrollbar {
      visibility: hidden;
    }
  }
`;

// src/constants/shadows.ts
var shadows = {
  modal: "0 0 8px -2px rgba(0, 0, 0, 0.1), 0 6px 20px -3px rgba(0, 0, 0, 0.2)"
};

// src/index.ts
var GlobalStyle = createGlobalStyle`

  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  html {
    @media (max-width: 1080px) {
      font-size: 93.75%;
    }

    @media (max-width: 720px) {
      font-size: 87.5%;
    }
  }

  body {
    -webkit-font-smoothing: antialiased;
    background-color: ${colors.white300};
  }

  body,
  input,
  textarea,
  button {
    color: ${colors.text};
    font-family: 'Montserrat', 'Roboto', sans-serif;
    font-weight: 400;
  }

  button {
    cursor: pointer;
  }

  html, body, #root {
    height: 100%;
  }

  [disabled] {
    cursor: not-allowed;
    opacity: 0.6;
  }
`;
export {
  GlobalStyle,
  colors,
  fonts,
  radii,
  scrollbar,
  shadows,
  underlineAnimation
};

import styled, { css } from 'styled-components';

import { colors, radii } from '@gbs-monorepo-packages/styles';
import { Slot } from '@radix-ui/react-slot';

interface IOptionsButtonProps {
  noPaddingRight: boolean;
  styleType?: 'outline' | 'text';
}

const buttonStyle = css<IOptionsButtonProps>`
  all: unset;
  align-items: center;
  border-radius: ${radii.md};
  cursor: pointer;
  display: inline-flex;
  font-family: inherit;
  font-size: 0.875rem;
  font-weight: 700;
  justify-content: center;
  line-height: 1.5;
  padding: 0.5rem 1rem;
  text-transform: uppercase;

  &:hover {
    filter: brightness(0.9);
  }

  &[disabled] {
    cursor: not-allowed;
    opacity: unset;
    background-color: ${colors.blue50};
  }

  ${(props) =>
    props.noPaddingRight &&
    css`
      padding-right: 0.25rem;
    `}

  ${(props) => {
    switch (props.styleType) {
      case 'outline':
        return css`
          background-color: ${colors.white300};
          border: 0.5px solid ${colors.button};
          color: ${colors.button};
          &[disabled] {
            opacity: 0.2;
          }
        `;
      case 'text':
        return css`
          background-color: ${colors.white300};
          color: ${colors.button};
          &[disabled] {
            opacity: 0.25;
          }
        `;
      default:
        return css`
          background-color: ${colors.button};
          color: ${colors.white300};
        `;
    }
  }}
`;

export const DefaultButton = styled.button<IOptionsButtonProps>`
  ${buttonStyle};
`;

export const SlotButton = styled(Slot)<IOptionsButtonProps>`
  ${buttonStyle};
`;

export const Loading = styled.img`
  height: 1rem;
  width: 1rem;
`;

import {
  MdCheck,
  MdKeyboardArrowDown,
  MdKeyboardArrowUp,
} from 'react-icons/md';
import styled, { css } from 'styled-components';

import { colors, radii, shadows } from '@gbs-monorepo-packages/styles';
import {
  Content,
  Item,
  ScrollDownButton,
  ScrollUpButton,
  SelectIcon,
  SelectItemIndicator,
  Trigger,
  Viewport,
} from '@radix-ui/react-select';

interface IThemeStyle {
  $themeStyle: 'admin' | 'default';
}

const adminStyle = css`
  height: 2.25rem;
  border-radius: ${radii.lg};
  &[data-placeholder] {
    font-style: italic;
  }
`;

export const SelectTrigger = styled(Trigger)`
  width: 100%;
  align-items: center;
  background-color: ${colors.white300};
  border-radius: ${radii.px};
  border: 1px solid ${colors.inputBorderLight};
  color: ${colors.text};
  display: flex;
  font-size: 1rem;
  height: 2rem;
  justify-content: space-between;
  line-height: 1.1;
  outline: none;
  padding-right: 0.2rem;
  padding: 0 0.5rem;
  &[data-placeholder] {
    color: ${colors.inputPlaceholder};
  }

  ${({ $themeStyle }: IThemeStyle) =>
    $themeStyle === 'admin' &&
    css`
      ${adminStyle}
    `}
`;

interface ISelectContentProps {
  $overwriteHeight?: number;
  $zIndex?: number;
}

// prettier-ignore
export const SelectContent = styled(Content)<ISelectContentProps>`
  background-color: ${colors.white300};
  border-radius: ${radii.sm};
  box-shadow: ${shadows.modal};
  min-width: 220;
  overflow: hidden;
  padding: 5px;

  ${({ $overwriteHeight }) =>
    $overwriteHeight !== undefined &&
    css`
      height: ${$overwriteHeight}px !important;
    `}

  ${({ $zIndex }) =>
    $zIndex !== undefined &&
    css`
      z-index: ${$zIndex};
    `}
`;

export const IconContainer = styled(SelectIcon)`
  align-items: center;
  display: flex;
`;

export const ArrowDownIcon = styled(MdKeyboardArrowDown)`
  color: ${colors.text};
  height: 1rem;
  width: 1rem;
`;

export const ArrowUpContainer = styled(ScrollUpButton)`
  align-items: center;
  background-color: ${colors.white300};
  color: ${colors.blue300};
  cursor: default;
  display: flex;
  height: 1.625rem;
  justify-content: center;

  &:hover {
    background-color: ${colors.backgroundHover};
  }
`;

export const ArrowUp = styled(MdKeyboardArrowUp)`
  height: 1rem;
  width: 1rem;
`;

export const CustomViewport = styled(Viewport)`
  padding: 0.315rem;
`;

export const CustomItem = styled(Item)`
  all: unset;
  align-items: center;
  border-radius: ${radii.px};
  color: ${colors.text};
  display: flex;
  font-size: 1rem;
  line-height: 1.1;
  padding: 0.375rem 35px 0.375rem 25px;
  position: relative;
  user-select: none;

  &[data-disabled] {
    color: ${colors.gray200};
    pointer-events: none;
  }

  &[data-highlighted],
  &:hover {
    background-color: rgba(0, 0, 0, 0.08);
  }
`;

export const IndicatorContainer = styled(SelectItemIndicator)`
  align-items: center;
  color: ${colors.blue300};
  display: inline-flex;
  justify-content: center;
  left: 0px;
  position: absolute;
  width: 25px;

  &:active {
    background-color: rgba(0, 0, 0, 0.08);
  }
`;

export const SelectIndicatorIcon = styled(MdCheck)`
  height: 1.125rem;
  width: 1.125rem;
`;

export const ArrowDownContainer = styled(ScrollDownButton)`
  align-items: center;
  background-color: ${colors.white300};
  color: ${colors.blue300};
  cursor: default;
  display: flex;
  height: 25px;
  justify-content: center;

  &:hover {
    background-color: ${colors.backgroundHover};
  }
`;

export const ArrowDown = styled(MdKeyboardArrowDown)`
  height: 1rem;
  width: 1rem;
`;

import { type ReactNode, type Ref } from 'react';

import * as DialogPrimitive from '@radix-ui/react-dialog';

import {
  CloseButton,
  CloseIcon,
  DialogContent,
  DialogContentLogin,
  DialogOverlay,
  DialogOverlayLogin,
} from './styles';

export interface IBaseModalProps extends DialogPrimitive.DialogProps {
  biggerModal?: boolean;
  children: ReactNode;
  dataCy?: string;
  hiddenCloseButton?: boolean;
  trigger?: ReactNode;
  zIndex?: number;
  lightBlueModal?: boolean;
  vhHeight?: string;
  fullWidth?: boolean;
  refContent?: Ref<HTMLDivElement>;
  setMaxWidth?: number;
}

export const BaseModal = ({
  biggerModal = false,
  children,
  dataCy = 'content-modal',
  hiddenCloseButton = false,
  trigger,
  zIndex,
  lightBlueModal = false,
  setMaxWidth,
  refContent,
  vhHeight,
  fullWidth = false,
  ...props
}: IBaseModalProps): JSX.Element => {
  return (
    <DialogPrimitive.Root {...props}>
      <DialogPrimitive.Trigger asChild>{trigger}</DialogPrimitive.Trigger>
      {lightBlueModal ? (
        <DialogPrimitive.Portal>
          <DialogOverlayLogin $zIndex={zIndex} data-cy="overlay-modal" />
          <DialogContentLogin
            data-cy={dataCy}
            $biggerModal={biggerModal}
            $zIndex={zIndex}
            $setManualWidth={setMaxWidth}
          >
            {children}
            <DialogPrimitive.Close asChild>
              {!hiddenCloseButton && (
                <CloseButton aria-label="Close" data-cy="button-closeModal">
                  <CloseIcon />
                </CloseButton>
              )}
            </DialogPrimitive.Close>
          </DialogContentLogin>
        </DialogPrimitive.Portal>
      ) : (
        <DialogPrimitive.Portal>
          <DialogOverlay $zIndex={zIndex} data-cy="overlay-modal" />
          <DialogContent
            data-cy={dataCy}
            $biggerModal={biggerModal}
            $zIndex={zIndex}
            $height={vhHeight}
            $fullWidth={fullWidth}
            ref={refContent}
            $setManualWidth={setMaxWidth}
          >
            {children}
            <DialogPrimitive.Close asChild>
              {!hiddenCloseButton && (
                <CloseButton aria-label="Close" data-cy="button-closeModal">
                  <CloseIcon />
                </CloseButton>
              )}
            </DialogPrimitive.Close>
          </DialogContent>
        </DialogPrimitive.Portal>
      )}
    </DialogPrimitive.Root>
  );
};

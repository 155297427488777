import type * as CSS from 'csstype';
import { type Editor } from 'grapesjs';

import { type IButtonProps } from '../../interfaces';
import WrapperCss from './styles.css?inline';

export const cmdToggleWrapper = 'toggle-wrapper';

export const wrapperClass = 'content-center';
export const wrapperStyle: CSS.StandardPropertiesHyphen = {
  padding: '0 calc((100% - 500px)/2)',
};
export const wrapperCss = WrapperCss;

const iconStyle = 'style="display: block; max-width:22px"';

export const handlerWrapper = (editor: Editor, hasWrapper?: boolean): void => {
  const { Commands } = editor;
  const needToActivate = hasWrapper ?? !Commands.isActive(cmdToggleWrapper);

  if (needToActivate === Commands.isActive(cmdToggleWrapper)) return;

  if (needToActivate) {
    Commands.run(cmdToggleWrapper);
  } else {
    Commands.stop(cmdToggleWrapper);
  }
};

const ToggleWrapperPlugin = (editor: Editor): void => {
  const { Commands, Panels, Css } = editor;

  Css.setRule('.content-center', wrapperStyle);

  const buttonToggleWrapperOptions: IButtonProps = {
    id: cmdToggleWrapper,
    className: '',
    command: cmdToggleWrapper,
    attributes: { title: 'Toggle content centering' },
    active: false,
    tagName: 'span',
    label: `<svg ${iconStyle} stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"><path d="M4.5 9a3.5 3.5 0 1 0 0 7h7a3.5 3.5 0 1 0 0-7h-7zm7 6a2.5 2.5 0 1 1 0-5 2.5 2.5 0 0 1 0 5zm-7-14a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zm2.45 0A3.49 3.49 0 0 1 8 3.5 3.49 3.49 0 0 1 6.95 6h4.55a2.5 2.5 0 0 0 0-5H6.95zM4.5 0h7a3.5 3.5 0 1 1 0 7h-7a3.5 3.5 0 1 1 0-7z"></path></svg>`,
    context: cmdToggleWrapper,
    buttons: [],
    options: {},
    dragDrop: false,
    togglable: true,
    runDefaultCommand: false,
    stopDefaultCommand: false,
    disable: false,
  };

  const buttonToggleWrapper = Panels.addButton(
    'options',
    buttonToggleWrapperOptions
  );

  Commands.add(cmdToggleWrapper, {
    run: (editor) => {
      buttonToggleWrapper?.set('active', true);
      editor.getWrapper()?.addClass(wrapperClass);
    },
    stop: (editor) => {
      buttonToggleWrapper?.set('active', false);
      editor.getWrapper()?.removeClass(wrapperClass);
    },
  });
};

export default ToggleWrapperPlugin;

import { MdKeyboardArrowDown } from 'react-icons/md';
import styled, { css } from 'styled-components';

import { colors, radii, shadows } from '@gbs-monorepo-packages/styles';
import * as SelectPrimitive from '@radix-ui/react-select';

export const SelectTrigger = styled(SelectPrimitive.Trigger)`
  align-items: center;
  background-color: ${colors.white300};
  border: none;
  color: ${colors.text};
  display: flex;
  font-size: 1rem;
  height: 2rem;
  justify-content: space-between;
  line-height: 1.1;
  outline: none;
  padding-right: 0.2rem;
  padding: 0 0.5rem;
  &[data-placeholder] {
    color: ${colors.inputPlaceholder};
  }

  &:nth-child(1) > * {
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;

interface ISelectContentProps {
  $zIndex?: number;
}

// prettier-ignore
export const SelectContent = styled(SelectPrimitive.Content)<ISelectContentProps>`
  background-color: ${colors.white300};
  border-radius: ${radii.sm};
  box-shadow: ${shadows.modal};
  min-width: 220;
  overflow: hidden;
  padding: 5px;

  ${({ $zIndex }) =>
    $zIndex !== undefined &&
    css`
      z-index: ${$zIndex};
    `}
`;

export const IconContainer = styled(SelectPrimitive.SelectIcon)`
  align-items: center;
  display: flex;
`;

export const ArrowDown = styled(MdKeyboardArrowDown)`
  color: ${colors.text};
  height: 1rem;
  width: 1rem;
`;

export const Square = styled.div`
  box-shadow: ${shadows.modal};
  width: 15px;
  height: 15px;
  overflow: hidden;
  z-index: 1;
  margin-left: 5px;
  border: 1px solid ${colors.inputBorderLight};
`;

import React, { type ReactNode, forwardRef } from 'react';

import LoadingSpinnerAnimated from '../../assets/spinner.svg';
import { DefaultButton, Loading, SlotButton } from './styles';

export interface IButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  asChild?: boolean;
  children: ReactNode;
  dataCy?: string;
  loading?: boolean;
  noPaddingRight?: boolean;
  styleType?: 'outline' | 'text';
}

export const Button = forwardRef<HTMLButtonElement, IButtonProps>(
  (
    {
      asChild = false,
      children,
      dataCy = 'button',
      disabled = false,
      loading = false,
      noPaddingRight = false,
      styleType,
      ...props
    },
    ref
  ): JSX.Element => {
    const Comp = asChild ? SlotButton : DefaultButton;
    return (
      <Comp
        type={'button'}
        {...props}
        data-cy={dataCy}
        disabled={loading || disabled}
        noPaddingRight={noPaddingRight}
        ref={ref}
        styleType={styleType}
      >
        {loading ? <Loading src={LoadingSpinnerAnimated} /> : children}
      </Comp>
    );
  }
);

Button.displayName = 'Button';

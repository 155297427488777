import {
  MdChecklist,
  MdChevronLeft,
  MdChevronRight,
  MdFullscreen,
  MdFullscreenExit,
  MdKeyboardDoubleArrowLeft,
  MdKeyboardDoubleArrowRight,
  MdMenu,
  MdOutlineCheckBox,
  MdOutlineDescription,
  MdOutlineVisibility,
  MdOutlineVisibilityOff,
  MdSearch,
} from 'react-icons/md';
import styled, { css } from 'styled-components';

import { colors, radii, scrollbar } from '@gbs-monorepo-packages/styles';
import { Root as LabelPrimitiveRoot } from '@radix-ui/react-label';

import { BaseLoading } from '../../components/BaseLoading';

interface IVisibleProps {
  $visible?: boolean;
}

export const CourseViewerContainer = styled.div`
  display: flex;
  height: 100%;
`;

export const MainContainer = styled.main`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex: 1;
  justify-content: space-between;
`;

export const Header = styled.header<IVisibleProps>`
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0 0.5rem;
  height: 3.25rem;
  z-index: 2;
  display: none;

  ${({ $visible = false }) =>
    $visible &&
    css`
      display: flex;
    `}
`;

export const LoadingPagesContainer = styled.div`
  align-items: center;
  display: flex;
  flex-grow: 1;
  justify-content: center;
`;

export const LoadingPages = styled(BaseLoading)`
  height: 3.25rem;
  width: 3.25rem;
`;

export const NoPagesContainer = styled.div`
  align-items: center;
  display: flex;
  flex-grow: 1;
  justify-content: center;
`;

export const NoPagesText = styled.p`
  font-size: 1rem;
  font-weight: 400;
  color: ${colors.textLight};
`;

export const Footer = styled.footer<IVisibleProps>`
  align-items: center;
  flex-wrap: wrap;
  padding: 0 0.5rem;
  justify-content: center;
  display: none;

  ${({ $visible = false }) =>
    $visible &&
    css`
      display: flex;
    `}
`;

export const TitlePage = styled.h1`
  font-size: 2rem;
  font-weight: 700;
  @media (max-width: 337px) {
    font-size: 1.5rem;
  }
  @media (max-width: 279px) {
    font-size: 1.2rem;
  }
`;

export const MainContent = styled.div`
  align-content: flex-start;
  position: relative;
  align-items: stretch;
  display: flex;
  flex-basis: 0;
  flex-grow: 1;
  flex-wrap: wrap;
  height: 100%;
  overflow: auto;

  ${scrollbar};
`;

export const IframeCustom = styled.iframe`
  border: none;
  height: 100%;
  width: 100%;
`;

export const ActionIconContainer = styled.div`
  display: flex;
  padding: 0.75rem 0.5rem;
  flex-direction: column;
  cursor: pointer;

  &:hover {
    background-color: ${colors.backgroundHover};
  }
`;

export const PagesMenuIconContainer = styled.div`
  display: flex;
  padding: 0.75rem 0.5rem;
  flex-direction: column;
  cursor: pointer;

  &:hover {
    background-color: ${colors.backgroundHover};
  }
`;

export const AsideContainer = styled.aside`
  border-left: 1px solid ${colors.gray100} !important;
  box-shadow: rgb(0 0 0 / 16%) 0px 1px 4px;
  overflow: auto;
  max-height: 100vh;
  display: flex;
  flex-direction: column;
  max-width: 20rem;
  min-width: 16rem;
  overflow-x: hidden;
  z-index: 1;
  background: ${colors.white300};

  ${scrollbar};
`;

export const PageItemsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 3.4rem;
`;

export const PageItem = styled.button`
  all: unset;
  display: flex;
  padding: 0.8rem;
  cursor: pointer;
  align-items: center;
  justify-content: flex-start;

  &:hover {
    background-color: ${colors.backgroundHover};
  }
`;

export const PageTitle = styled.h5`
  all: unset;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  margin-left: 0.8rem;
  margin-right: 0.8rem;
`;

export const PageTitleContainer = styled.div`
  flex: 0.75;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
`;

export const PageIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const LeftArrowIconContainer = styled.button`
  all: unset;
  display: flex;
  padding: 0.75rem 0.5rem;
  flex-direction: column;
  cursor: pointer;

  &:hover {
    background-color: ${colors.backgroundHover};
  }
`;

export const RightArrowIconContainer = styled.button`
  all: unset;
  display: flex;
  padding: 0.75rem 0.5rem;
  flex-direction: column;
  cursor: pointer;

  &:hover {
    background-color: ${colors.backgroundHover};
  }
`;

export const PageMessage = styled.div``;

export const TextContent = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  font-size: 0.875rem;
  padding: 0 0 1.5rem;
`;

export const Title = styled.h1`
  font-size: 1.5rem;
  margin-bottom: 0.875rem;
`;
export const Description = styled.p`
  font-size: 0.875rem;
  text-align: center;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

export const Label = styled(LabelPrimitiveRoot)`
  background-color: ${colors.white300};
  color: ${colors.labelText};
  font-size: 0.875rem;
  left: 16px;
  max-width: 90%;
  overflow: hidden;
  padding: 1px 6px;
  position: absolute;
  text-overflow: ellipsis;
  top: 50%;
  transform-origin: 0;
  transform: translateY(-50%);
  transition: transform 0.15s ease-in-out, top 0.15s ease-in-out,
    padding 0.15s ease-in-out;
  user-select: none;
  white-space: nowrap;
  z-index: 1;
`;

interface IFieldsetProps {
  readonly filled: boolean;
  readonly isInvalid?: boolean;
}

export const Fieldset = styled.fieldset<IFieldsetProps>`
  all: unset;
  align-items: center;
  background-color: ${colors.white300};
  border-radius: ${radii.px};
  border: 1px solid ${colors.inputBorder};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 0.75rem;
  position: relative;
  transition: box-shadow 0.2s ease-in-out, border-color 0.2s ease-in-out;

  &:focus-within {
    border: 1px solid ${colors.fieldFocus};

    & ${Label} {
      color: ${colors.fieldFocus};
      top: -2px;
      transform: scale(0.88) translateX(-0.5rem) translateY(-50%);
      white-space: normal;
    }
  }

  ${({ filled = false, isInvalid = false }) =>
    filled &&
    (!isInvalid
      ? css`
          border: 1px solid ${colors.fieldFocus};

          & ${Label} {
            color: ${colors.fieldFocus};
            top: -2px;
            transform: scale(0.88) translateX(-0.5rem) translateY(-50%);
            white-space: normal;
          }
        `
      : css`
          border: 1px solid ${colors.red300};

          & ${Label} {
            color: ${colors.red300};
            top: -2px;
            transform: scale(0.88) translateX(-0.5rem) translateY(-50%);
            white-space: normal;
          }
        `)}
`;

export const Input = styled.input`
  -webkit-appearance: none;
  background-color: none;
  border-radius: ${radii.px};
  border: none;
  color: ${colors.text};
  flex-grow: 1;
  font-size: 1rem;
  height: 3.25rem;
  line-height: 1.1;
  outline: none;
  padding: 0 1rem;
  transition: box-shadow 0.2s ease-in-out, border-color 0.2s ease-in-out;
`;

export const ErrorMessage = styled.p`
  font-size: 0.875rem;
  color: ${colors.red300};
`;

export const SubmitButton = styled.button`
  all: unset;
  align-items: center;
  background-color: ${colors.black300};
  border-radius: ${radii.px};
  color: ${colors.white300};
  display: flex;
  font-size: 1rem;
  height: 3.25rem;
  justify-content: center;
  margin-top: 1.5rem;

  &:hover {
    opacity: 0.9;
  }
`;

export const PageIcon = styled(MdOutlineDescription)`
  height: 1.5rem;
  width: 1.5rem;
`;

export const DecisionToolIcon = styled(MdChecklist)`
  height: 1.5rem;
  width: 1.5rem;
`;

export const MultipleChoiceIcon = styled(MdOutlineCheckBox)`
  height: 1.5rem;
  width: 1.5rem;
`;

export const PagesMenuIcon = styled(MdMenu)`
  height: 1.5rem;
  width: 1.5rem;
`;

export const SearchIcon = styled(MdSearch)`
  height: 1.5rem;
  width: 1.5rem;
`;

export const FullscreenIcon = styled(MdFullscreen)`
  height: 1.5rem;
  width: 1.5rem;
`;

export const ExitFullscreenIcon = styled(MdFullscreenExit)`
  height: 1.5rem;
  width: 1.5rem;
`;

export const RightArrowIcon = styled(MdChevronRight)`
  height: 2rem;
  width: 2rem;
  color: ${colors.black300};
`;

export const LeftArrowIcon = styled(MdChevronLeft)`
  height: 2rem;
  width: 2rem;
  color: ${colors.black300};
`;

export const RightArrowIconFullScreen = styled(MdKeyboardDoubleArrowRight)`
  height: 2.7rem;
  width: 2.7rem;
`;

export const LeftArrowIconFullScreen = styled(MdKeyboardDoubleArrowLeft)`
  height: 2.7rem;
  width: 2.7rem;
`;

export const HeaderImageContainer = styled.div`
  display: flex;
  width: 10rem;
  height: 100%;
  align-items: center;
`;

export const HeaderImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

export const ActionItemsContainer = styled.div`
  display: flex;
  position: absolute;
  right: 0;
  z-index: 1;
`;

interface IOverlayToggleToMobileProps {
  $isShrunken?: boolean;
}

export const OverlayToggleToMobile = styled.div<IOverlayToggleToMobileProps>`
  background-color: ${colors.blackTranslucent400};
  display: none;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1;

  ${({ $isShrunken = false }) =>
    $isShrunken &&
    css`
      display: block;
    `}
`;

export const ToggleButton = styled.button<IVisibleProps>`
  all: unset;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  padding: 6px 10px;
  cursor: pointer;
  display: none;
  text-align: center;
  background-color: #ccc;
  opacity: 0.7;
  border-radius: 8px;
  box-shadow: var(--gbs-shadow-modal);
  ${({ $visible = false }) =>
    $visible &&
    css`
      pointer-events: auto;
      display: block;
    `}

  &:hover {
    opacity: 1;
  }
`;

export const ToggleLeftButton = styled(ToggleButton)<IVisibleProps>`
  left: 15px;
`;

export const ToggleRightButton = styled(ToggleButton)<IVisibleProps>`
  right: 15px;
`;

export const FullscreenMessageContainer = styled.div<IVisibleProps>`
  position: absolute;
  color: ${colors.white300};
  font-weight: 900;
  top: 10%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 10px 20px;
  background-color: ${colors.blackTranslucent400};
  display: none;
  ${({ $visible = false }) =>
    $visible &&
    css`
      display: block;
      text-align: center;
    `}
`;

export const PageContainer = styled.div<IVisibleProps>`
  position: absolute;
  color: ${colors.text};
  font-weight: 900;
  bottom: 0%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin-bottom: 1rem;
  padding: 10px 20px;
  background-color: #fefefe;
  border-radius: ${radii.md};
  display: none;
  ${({ $visible = false }) =>
    $visible &&
    css`
      display: block;
      text-align: center;
    `}
`;

export const ContainerArrows = styled.div<IVisibleProps>`
  display: none;

  ${({ $visible = false }) =>
    $visible &&
    css`
      display: block;
    `}
`;

export const ExitFullScreenMobile = styled.div<IVisibleProps>`
  position: absolute;
  top: 0;
  right: 15px;
  z-index: 1;
  display: block;
`;

export const ToggleFullscreenButton = styled.button`
  all: unset;
  padding: 10px 0;
  z-index: 1;
  cursor: pointer;
  display: block;
  pointer-events: auto;
  text-align: center;
`;

export const LockButton = styled.button`
  all: unset;
  align-items: center;
  border-radius: ${radii.px};
  color: ${colors.labelText};
  display: inline-flex;
  font-size: 1rem;
  height: 3.25rem;
  justify-content: center;
  width: 2.75rem;

  &:hover {
    background-color: ${colors.backgroundHover};
  }
`;

export const LockedIcon = styled(MdOutlineVisibility)``;
export const UnLockedIcon = styled(MdOutlineVisibilityOff)``;
